import Grid from "@mui/material/Grid";
import {useTheme} from "@mui/material";

type bracketConnectorProps = {
    height: string;
}
export default function BracketConnector({height}: Readonly<bracketConnectorProps>) {
    const theme = useTheme();
    const connectorColor = theme.palette.mode === 'dark' ? '#ffffff' : '#000000';
    return (
        <Grid container sx={{
            display: 'flex',
            flexDirection: "row",
            height: height,
            width: 'fit-content',
            minWidth: "2rem",
            justifyContent: "center",
        }}>
            <Grid item sx={{
                flex: .5,
                borderTop: `2px solid ${connectorColor}`,
                borderBottom: `2px solid ${connectorColor}`,
                borderRight: `2px solid ${connectorColor}`,
            }}>
            </Grid>
            <Grid item sx={{
                flex: .5,
                display: 'flex',
                flexDirection: "column",
                justifyContent: "center",
                position: "relative",
            }}>
                <hr color={connectorColor} style={{width: "100%"}}/>
            </Grid>
        </Grid>
    )
}