import {useEffect, useState} from "react";
import * as React from "react";
import {about_me, change_email} from "../apis/Auth";
import Layout from "../utilities/Layout";
import {Alert, List, ListItem, Snackbar, Theme, useMediaQuery} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import GA4EventSend from "../utilities/GA4EventSend";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import {get_user_mail_settings, manage_subscribe} from "../apis/Mailchimp";
import Checkbox from "@mui/material/Checkbox";
import Loader from "../components/common/Loader";
import {Helmet} from "react-helmet";

export default function ChangeEmail() {
    if (localStorage.getItem("user") === null) {
        let pathname = encodeURIComponent("/change-email");
        window.location.href = "/signin/redirect?path=" + pathname;
    }

    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isClicked, setIsClicked] = useState(false);
    const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"success" | "error" | "info" | "warning" | undefined>("error");
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));
    const [subscribed, setSubscribed] = useState<boolean | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    function validateEmail(email: string): boolean {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    function handleChange(event: React.ChangeEvent<HTMLFormElement>) {
        const data = new FormData(event.currentTarget);
        const email = (data.get('email') as string).toLowerCase() ?? "";
        setButtonEnabled(
            validateEmail(email)
        );
    }

    useEffect(() => {
        if (loading) {
            get_user_mail_settings(localStorage.getItem("access_token")!, localStorage.getItem("refresh_token")!).then((response) => {
                console.log(response);
                if (response.succeeded) {
                    if (response.data === "subscribed") {
                        setSubscribed(true);
                    } else {
                        setSubscribed(false);
                    }
                }
            });
            setLoading(false);
        }
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp, loading]);

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setIsClicked(true);
        const data = new FormData(event.currentTarget);
        const email = (data.get('email') as string).toLowerCase() ?? "";
        if (validateEmail(email)) {
            let response = await change_email(localStorage.getItem("access_token") ?? "", localStorage.getItem("refresh_token") ?? "", email);
            if (response.succeeded) {
                let ab_response = await about_me(localStorage.getItem("refresh_token") ?? "", localStorage.getItem("access_token") ?? "");
                if (ab_response.succeeded) {
                    localStorage.setItem('user', JSON.stringify(ab_response.data));
                } else {
                    console.error(response.message);
                }
                setSeverity("success");
                setMessage(response.message);
                setOpen(true);
            } else {
                setSeverity("error");
                setMessage(response.message);
                setOpen(true);
            }
            GA4EventSend("change_email", "user", "change_email", response.statusCode, false);
        }
        setIsClicked(false);
    }

    async function changeMailSettings() {
        setIsClicked(true);
        let response = await manage_subscribe(JSON.parse(localStorage.getItem("user") as string)!.id, subscribed ?? false, JSON.parse(localStorage.getItem("user") as string)!.phone_number.length > 0 ?? false);
        if (response.succeeded) {
            setSeverity("success");
            setMessage(subscribed ? "Successfully subscribed to mail list" : "Successfully unsubscribed from mail list");
            setOpen(true);
        } else {
            setSeverity("error");
            setMessage(subscribed ? "Failed to subscribe to mail list" : "Failed to unsubscribe from mail list");
            setOpen(true);
        }
        setIsClicked(false);
    }

    return (
        <Layout>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={open} onClose={() => setOpen(false)}
                      autoHideDuration={6000}>
                <Alert elevation={6} severity={severity} sx={{width: '100%'}} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
            <Helmet>
                <title>DRBB | Change Email</title>
            </Helmet>
            <Box sx={{
                height: isMdOrUp ? '65vh' : 'fit-content',
                width: '100%',
                display: 'flex',
                m: 2,
                borderRadius: '10px'
            }}>
                {loading || subscribed === null ? <Loader/> :
                    <Paper elevation={3} sx={{
                        padding: 2,
                        margin: 'auto',
                        width: 'fit-content',
                        height: 'fit-content'
                    }}>
                        <Grid container direction={"column"} component={"form"} onSubmit={handleSubmit}
                              onChange={handleChange} sx={{
                            maxWidth: "300px",
                            margin: "auto",
                            p: 1,
                        }}>
                            <Typography variant={"h4"} fontWeight={"bold"} sx={{textAlign: "center", mb: 2}}>
                                Change Email Address
                            </Typography>
                            <TextField
                                required
                                fullWidth
                                name="email"
                                label="Email"
                                id="email"
                                type={"email"}
                                onChange={() => {
                                }}
                            />
                            <Grid display="flex" flexDirection="row" alignItems="center" marginY={1}>
                                <FormControlLabel
                                    control={<Checkbox name="allowExtraTexts" value="agreed" color="primary"
                                                       defaultChecked={subscribed}
                                                       onChange={(event) => {
                                                           setSubscribed(!subscribed);
                                                       }}
                                    />}
                                    label="I want to receive bracket promotions and updates via email"
                                    labelPlacement="start"
                                    sx={{ml: "8px", mr: "0px"}}
                                />
                            </Grid>
                            <Button variant={"contained"} disabled={isClicked} color={"primary"} sx={{
                                backgroundColor: "#486ce7",
                                color: "#ffffff",
                                ":hover": {
                                    backgroundColor: "#c40000",
                                    color: "#ffffff",
                                }
                            }}
                                    onClick={() => {
                                        changeMailSettings();
                                    }}>Change Mail Settings</Button>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                                disabled={isClicked || !buttonEnabled}
                            >
                                Change Email
                            </Button>
                        </Grid>
                    </Paper>
                }
            </Box>
        </Layout>
    )
}