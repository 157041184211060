export enum RaceClass{
    "TOP_FUEL" = "Top Fuel",
    "FUNNY_CAR" = "Funny Car",
    "PRO_STOCK" = "Pro Stock",
    "PRO_STOCK_MOTORCYCLE" = "Pro Stock Motorcycle",
}

export function raceClassFromInt(raceClass: number): RaceClass {
    switch (raceClass) {
        case 1:
            return RaceClass.TOP_FUEL;
        case 2:
            return RaceClass.FUNNY_CAR;
        case 3:
            return RaceClass.PRO_STOCK;
        case 4:
            return RaceClass.PRO_STOCK_MOTORCYCLE;
        default:
            return RaceClass.TOP_FUEL;
    }
}

export function raceClassToInt(raceClass: RaceClass): number {
    switch (raceClass) {
        case RaceClass.TOP_FUEL:
            return 1;
        case RaceClass.FUNNY_CAR:
            return 2;
        case RaceClass.PRO_STOCK:
            return 3;
        case RaceClass.PRO_STOCK_MOTORCYCLE:
            return 4;
        default:
            return 1;
    }
}

export function raceClassFromString(raceClass: string): RaceClass {
    switch (raceClass) {
        case "Top Fuel":
            return RaceClass.TOP_FUEL;
        case "Funny Car":
            return RaceClass.FUNNY_CAR;
        case "Pro Stock":
            return RaceClass.PRO_STOCK;
        case "Pro Stock Motorcycle":
            return RaceClass.PRO_STOCK_MOTORCYCLE;
        default:
            return RaceClass.TOP_FUEL;
    }
}