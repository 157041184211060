import * as React from 'react';
import {useEffect, useState} from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {Theme} from "@mui/material";
import {Driver} from "../../data/models/bracket_api/Driver";
import {DriverResponse, get_drivers} from "../../apis/Driver";
import RaceClassFromInt from "../../utilities/RaceClassTools";
import {RaceClass} from '../../data/models/bracket_api/RaceClass';
import Link from '@mui/material/Link';

type StatsTableProps = {
    drivers: Driver[]
}

export default function StatsTable(props: Readonly<StatsTableProps>) {
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [value, setValue] = React.useState(0);


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp]);


    function formatDate(input?: string) {
        if (input === undefined || input === null || input === "") {
            return undefined;
        }
        let date = new Date(input);
        return date.getUTCMonth() + 1 + "/" + date.getUTCDate() + "/" + date.getUTCFullYear();
    }

    return (
        <Grid>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                key={'driver'}
                                align={'left'}
                                style={{minWidth: 'fit-content'}}
                            >
                                <Typography variant='h6' fontWeight={'bold'}>
                                    Driver
                                </Typography>
                            </TableCell>
                            <TableCell
                                key={'dob'}
                                align={'center'}
                                style={{minWidth: 'fit-content'}}
                            >
                                <Typography variant='h6' fontWeight={'bold'}>
                                    {isMdOrUp ? "Date of Birth" : "DOB"}
                                </Typography>
                            </TableCell>
                            <TableCell
                                key={'residence'}
                                align={'right'}
                                style={{minWidth: 'fit-content'}}
                            >
                                <Typography variant='h6' fontWeight={'bold'}>
                                    Residence
                                </Typography>
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.drivers
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id} sx={{cursor: 'pointer'}}
                                              onClick={() => {
                                                  window.location.href = 'stats/detail?' + encodeURIComponent(`id=${row.id}`);
                                              }}>
                                        <TableCell
                                            key={'driver' + row.id}
                                            align={'left'}
                                            sx={{minWidth: 'fit-content', padding: 1}}
                                        >
                                            <Typography variant={isMdOrUp ? 'body1' : 'body2'}>
                                                {row.first_name + " " + row.last_name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            key={'dob' + row.id}
                                            align={'center'}
                                            sx={{minWidth: 'fit-content', padding: 1}}
                                        >
                                            <Typography variant={isMdOrUp ? 'body1' : 'body2'}>
                                                {formatDate(row.date_of_birth) || ""}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            key={'residence' + row.id}
                                            align={'right'}
                                            sx={{minWidth: 'fit-content', padding: 1}}
                                        >
                                            <Typography variant={isMdOrUp ? 'body1' : 'body2'}>
                                                {row.residence || ""}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={isMdOrUp ? [10, 25, 100] : [10]}
                component="div"
                count={props.drivers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

        </Grid>
    );
}