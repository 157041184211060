import Grid from "@mui/material/Grid";
import {Divider} from "@mui/material";
import PickRowReverse from "../PickRowReverse";
import Paper from "@mui/material/Paper";
import {TwoWidePickBlockProps} from "./PickBlock";

export default function PickBlockReverse({pick, result, updatePick, picks_locked}: Readonly<TwoWidePickBlockProps>){
    return (
        <Grid container component={Paper} m={1} sx={{
            display: 'flex',
            flexDirection: "column",
            height: 'fit-content',
            width: 'fit-content',
        }}>
            <PickRowReverse
                competitor_number={1}
                pick={pick}
                result={result}
                onClick={updatePick}
                picks_locked={picks_locked}
                fourWide={false}
            />
            <Divider/>
            <PickRowReverse
                competitor_number={2}
                pick={pick}
                result={result}
                onClick={updatePick}
                picks_locked={picks_locked}
                fourWide={false}
            />
        </Grid>
    )
}