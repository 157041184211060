import Grid from "@mui/material/Grid";
import {Driver} from "../../../data/models/bracket_api/Driver";
import Typography from "@mui/material/Typography";
import {Theme, useMediaQuery, useTheme} from "@mui/material";
import {pickProps} from "./PickRow";
import Helmet from "../../../assets/images/helmet.png";
import {
    determineActualDriver, determineActualPosition,
    determinePickBackgroundColor,
    determinePickRowText,
    determinePickTextColor,
    determinePosition, handleClick, handleError
} from "./PickRowFunctions";
import {useEffect, useState} from "react";
import {FourWideResult} from "../../../data/models/bracket_api/FourWideResult";


export default function PickRowReverse({
                                           competitor_number,
                                           pick,
                                           result,
                                           picks_locked,
                                           record,
                                           onClick,
                                           fourWide
                                       }: Readonly<pickProps>) {
    let successColor = useTheme().palette.success.main.toString();
    const theme = useTheme();
    const rowColor = theme.palette.mode === 'dark' ? '#333333' : '#ffffff';
    const textColor = theme.palette.mode === 'dark' ? '#DDDDDD' : '#000000';
    let position: "1st" | "2nd" | "3rd" | "4th" | "" = "";
    let actualDriver: Driver | undefined = determineActualDriver(pick, competitor_number, result);
    const [byeRace, setByeRace] = useState(false);
    let props = determinePickRowText(competitor_number, pick, result);
    let driver = props.driver;
    let seed = props.seed;
    let time_or_condition = props.time_or_condition;
    let actualPosition = determineActualPosition(fourWide, result, actualDriver)
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));

    position = determinePosition(fourWide, driver, pick);

    useEffect(() => {
        if(driver === undefined || driver === null){
            if(competitor_number === 1 && (result?.competitor_1_game_code === undefined || result?.competitor_1_game_code === null)){
                setByeRace(true);
            }
            else if(competitor_number === 2 && (result?.competitor_2_game_code === undefined || result?.competitor_2_game_code === null)){
                setByeRace(true);
            }
            else if(competitor_number === 3 && ((result as FourWideResult)?.competitor_3_game_code === undefined || (result as FourWideResult)?.competitor_3_game_code === null)){
                setByeRace(true);
            }
            else if(competitor_number === 4 && ((result as FourWideResult)?.competitor_4_game_code === undefined || (result as FourWideResult)?.competitor_4_game_code === null)){
                setByeRace(true);
            }
            else{
                setByeRace(false);
            }
        }
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [result, competitor_number, seed, driver, time_or_condition]);

    return (
        <Grid container sx={{
            display: "flex",
            flexDirection: "row",
            padding: .5,
            transition: "background-color 0.75s ease",
            width: "fit-content",
            minWidth: !isMdOrUp ? (!fourWide ? "260px": "330px"): "330px",
            height: "36px",
            flexWrap: "nowrap",
            alignItems: 'center',
            backgroundColor: determinePickBackgroundColor(picks_locked, fourWide, result, pick, driver, competitor_number, successColor, rowColor),
            color: determinePickTextColor(picks_locked, pick, driver, result, fourWide, textColor),
            cursor: driver !== undefined && !picks_locked ? "pointer" : "default",
        }}
              onClick={() => {
                  handleClick(driver, picks_locked, fourWide, pick, seed as number, onClick);
              }}
        >
            <Typography
                align={"left"}
                ml={1}
                fontSize={".85rem"}
                variant={"body1"}
            >
                {seed === undefined || seed === 0 ? "" : seed}
            </Typography>
            {fourWide &&
                <Typography
                    variant={"body1"}
                    textOverflow={"fade"}
                    fontSize={".85rem"}
                    align={"right"}
                    noWrap
                    mx={1}
                >
                    {actualPosition !== "" && actualPosition !== position ?
                        <span
                            style={{textDecoration: 'line-through'}}>{`${position} `}
                        </span>
                        :
                        `${position} `
                    }
                    {actualPosition !== undefined && actualPosition !== position ? actualPosition : ''}
                </Typography>
            }
            <Typography
                variant={"body1"}
                textOverflow={"fade"}
                align={"left"}
                noWrap
                mx={1}
            >
                {picks_locked ? time_or_condition : record}
            </Typography>
            {driver !== undefined ?
                <Typography
                    align={"right"}
                    variant={"body1"}
                    fontSize={".85rem"}
                    fontWeight={"bold"}
                    noWrap
                    flex={1}
                    marginRight={'4px'}
                >
                    {actualDriver !== undefined && actualDriver !== driver ?
                        <span
                            style={{textDecoration: 'line-through'}}>{`${driver.first_name[0]} ${driver.last_name}`}</span>
                        :
                        `${driver.first_name} ${driver.last_name}`
                    }
                    {actualDriver !== undefined && actualDriver !== driver ? ` (${actualDriver.first_name[0]} ${actualDriver.last_name})` : ''}
                </Typography> :
                <Typography
                    align={"center"}
                    mx={1}
                    variant={"body1"}
                    fontSize={".85rem"}
                    fontWeight={"bold"}
                    noWrap
                    flex={1}
                >
                    {byeRace ? "BYE" : ""}
                </Typography>
            }
            {driver &&
                (
                    actualDriver?.thumbnail ?
                        <img src={actualDriver.thumbnail} onError={handleError}
                             alt={actualDriver.first_name + actualDriver.last_name + "'s headshot"} height={25}
                             style={{float: "right"}}/>
                        :
                        driver.thumbnail ?
                            <img src={driver.thumbnail} onError={handleError}
                                 alt={driver.first_name + driver.last_name + "'s headshot"} height={25}
                                 style={{float: "right"}}/>
                            :
                            <img src={Helmet} alt={"Helmet Thumbnail"} height={25}
                                 style={{float: "right"}}/>
                )
            }
        </Grid>
    )
}