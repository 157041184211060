import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {RaceClass} from "../../../data/models/bracket_api/RaceClass";
import {Driver} from "../../../data/models/bracket_api/Driver";
import Paper from "@mui/material/Paper";
import * as React from "react";
import FirstRoundPicksTable from "./FirstRoundPicksTable";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 0}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

type FirstRoundPicksProps = {
    first_round_picks: {
        "Top Fuel": [
            {
                "Competitor 1": string,
                "Seed 1": number,
                "Competitor 2": string,
                "Seed 2": number,
                "Competitor 1 Pick Percentage": string,
                "Competitor 2 Pick Percentage": string,
            }
        ];
        "Funny Car": [
            {
                "Competitor 1": string,
                "Seed 1": number,
                "Competitor 2": string,
                "Seed 2": number,
                "Competitor 1 Pick Percentage": string,
                "Competitor 2 Pick Percentage": string,
            }
        ];
        "Pro Stock": [
            {
                "Competitor 1": string,
                "Seed 1": number,
                "Competitor 2": string,
                "Seed 2": number,
                "Competitor 1 Pick Percentage": string,
                "Competitor 2 Pick Percentage": string,
            }
        ];
        "Pro Stock Motorcycle": [
            {
                "Competitor 1": string,
                "Seed 1": number,
                "Competitor 2": string,
                "Seed 2": number,
                "Competitor 1 Pick Percentage": string,
                "Competitor 2 Pick Percentage": string,
            }
        ];
    }
}

export default function FirstRoundPicks(props: Readonly<FirstRoundPicksProps>) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Paper sx={{width: '75%', m: '16px auto'}}>
            <Box sx={{width: '100%'}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={value} variant="scrollable" onChange={handleChange}>
                        <Tab sx={{fontWeight: 'bolder'}} label="Top Fuel" {...a11yProps(0)} />
                        <Tab sx={{fontWeight: 'bolder'}} label="Funny Car" {...a11yProps(1)} />
                        <Tab sx={{fontWeight: 'bolder'}} label="Pro Stock" {...a11yProps(2)} />
                        <Tab sx={{fontWeight: 'bolder'}} label="Pro Stock Motorcycle" {...a11yProps(3)} />
                    </Tabs>
                </Box>

                <CustomTabPanel value={value} index={0}>
                    <FirstRoundPicksTable rows={props.first_round_picks["Top Fuel"]}/>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                    <FirstRoundPicksTable rows={props.first_round_picks["Funny Car"]}/>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={2}>
                    <FirstRoundPicksTable rows={props.first_round_picks["Pro Stock"]}/>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={3}>
                    <FirstRoundPicksTable rows={props.first_round_picks["Pro Stock Motorcycle"]}/>
                </CustomTabPanel>

            </Box>

        </Paper>
    )
}