import Grid from "@mui/material/Grid";
import {Driver} from "../../../data/models/bracket_api/Driver";
import Typography from "@mui/material/Typography";
import {grey} from "@mui/material/colors";
import {SyntheticEvent, useEffect, useState} from "react";
import {useTheme} from "@mui/material";
import Helmet from "../../../assets/images/helmet.png";
import {FourWideResult} from "../../../data/models/bracket_api/FourWideResult";
import {Result} from "../../../data/models/bracket_api/Result";
import {handleError} from "../picks/PickRowFunctions";

export type resultProps = {
    result?: Result | FourWideResult;
    competition_number: number;
    seed?: number;
    driver?: Driver;
    time_or_condition?: string | number;
    winner?: boolean;
}

export default function ConfigRow({result, competition_number, seed, driver, time_or_condition, winner}: Readonly<resultProps>) {
    let successColor = useTheme().palette.success.main.toString();
    const theme = useTheme();
    const [byeRace, setByeRace] = useState(false);
    const [rowColor, setRowColor] = useState(theme.palette.mode === 'dark' ? '#333333' : '#ffffff');
    const [textColor, setTextColor] = useState(theme.palette.mode === 'dark' ? '#DDDDDD' : '#000000');

    useEffect(() => {
        if(driver === undefined || driver === null){
            if(competition_number === 1 && (result?.competitor_1_game_code === undefined || result?.competitor_1_game_code === null)){
                setByeRace(true);
            }
            else if(competition_number === 2 && (result?.competitor_2_game_code === undefined || result?.competitor_2_game_code === null)){
                setByeRace(true);
            }
            else if(competition_number === 3 && ((result as FourWideResult)?.competitor_3_game_code === undefined || (result as FourWideResult)?.competitor_3_game_code === null)){
                setByeRace(true);
            }
            else if(competition_number === 4 && ((result as FourWideResult)?.competitor_4_game_code === undefined || (result as FourWideResult)?.competitor_4_game_code === null)){
                setByeRace(true);
            }
            else{
                setByeRace(false);
            }
        }
        else{
            setByeRace(false);
        }
        setRowColor(winner ? successColor : (theme.palette.mode === 'dark' ? '#333333' : '#ffffff'));
        setTextColor(winner ? "#ffffff" : (theme.palette.mode === 'dark' ? '#DDDDDD' : '#000000'));
    }, [result, competition_number, seed, driver, time_or_condition, winner]);

    return (
        <Grid container sx={{
            display: "flex",
            flexDirection: "row",
            padding: .5,
            transition: "background-color 0.75s ease",
            width: "fit-content",
            minWidth: "260px",
            height: "36px",
            flexWrap: "nowrap",
            alignItems: 'center',
            backgroundColor: winner ? successColor : rowColor,
            color: winner ? "#ffffff" : textColor,
        }}>
            {driver &&
                (
                    driver.thumbnail ?
                        <img src={driver.thumbnail} onError={handleError} alt={driver.first_name + driver.last_name + "'s headshot"} height={25}
                             style={{float: "left"}}/>
                        :
                        <img src={Helmet} alt={"Helmet Thumbnail"} height={25}
                             style={{float: "left"}}/>
                )
            }
            {byeRace &&
                <Typography
                    align={"center"}
                    mx={1}
                    variant={"body1"}
                    fontSize={".85rem"}
                    fontWeight={"bold"}
                    noWrap
                    flex={1}
                >
                    {byeRace ? "BYE" : ""}
                </Typography>
            }
            {driver &&
                <Typography
                    color={winner ? "#ffffff" : textColor}
                    align={"left"}
                    mx={1}
                    variant={"body1"}
                    noWrap
                    flex={1}
                >
                    {driver.first_name + " " + driver.last_name}
                </Typography>
            }
            <Typography
                color={textColor}
                variant={"body1"}
                textOverflow={"fade"}
                align={"right"}
                noWrap
                mx={1}
            >
                {time_or_condition}
            </Typography>
            <Typography
                color={winner ? "#ffffff" : textColor}
                align={"right"}
                ml={1}
                variant={"body1"}
            >
                {seed === undefined || seed === 0 ? "" : seed}
            </Typography>
        </Grid>
    )
}