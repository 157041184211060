import Layout from "../utilities/Layout";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {List, Theme, useMediaQuery} from "@mui/material";
import LeagueRow from "../components/league/LeagueRow";
import Button from "@mui/material/Button";
import ValidateSignIn from "../utilities/ValidateSignIn";
import {useEffect, useState} from "react";
import {League as league} from "../data/models/user_auth/League";
import * as LeagueAPI from "../apis/League";
import LeagueRowSkeleton from "../components/league/LeagueRowSkeleton";
import GA4EventSend from "../utilities/GA4EventSend";
import {Helmet} from "react-helmet";
import AdSpot from "../components/common/AdSpot";
import * as React from "react";

export default function Leagues() {
    const [leagues, setLeagues] = useState<Array<league>>([]);
    const [eventId, setEventId] = useState<string>('');
    const [bracketsCompleted, setBracketsCompleted] = useState<Array<number>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));
    const [eventActive, setEventActive] = useState<boolean>(false);
    const [bracketsOpen, setBracketsOpen] = useState<boolean>(false);
    const [bracketsViewable, setBracketsViewable] = useState<boolean>(false);
    let user_id: number = 0;
    try {
        user_id= JSON.parse(localStorage.getItem('user') as string).id;
    }
    catch{
        let pathname = encodeURIComponent(window.location.pathname);
        window.location.href = '/signin/redirect?path=' + pathname;
    }

    useEffect(() => {
        if (loading) {
            LeagueAPI.leagues_screen_load(user_id).then((response: LeagueAPI.LeagueResponse) => {
                if (response.succeeded) {
                    let leagues = response.data['leagues'];
                    leagues.sort((a: league, b: league) => {
                        // Put "Drag Race Bracket Bonanza" first
                        if (a.name === "Drag Race Bracket Bonanza") return -1;
                        if (b.name === "Drag Race Bracket Bonanza") return 1;

                        // Then sort the rest alphabetically
                        return a.name.localeCompare(b.name);
                    });
                    setLeagues(leagues);
                    setEventActive(response.data['event_active']);
                    setBracketsOpen(response.data['brackets_open']);
                    setEventId(response.data['event_id']);
                    setBracketsCompleted(response.data['brackets_completed'])
                    setBracketsViewable(response.data['brackets_viewable'])
                    setLoading(false);
                } else {
                    setLoading(false);
                }
                GA4EventSend("leagues", "load", "leagues", response.statusCode, false);
            });
        }
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [loading, leagues, isMdOrUp]);

    return (
        <Layout>
            <Grid container sx={{
                display: 'flex',
                flexDirection: isMdOrUp ? 'row' : 'column-reverse',
                minHeight: 'calc(100vh - 384px)',
            }}>
                <Helmet>
                    <title>{"DRBB | Leagues"}</title>
                </Helmet>
                <Grid container sx={{
                    direction: 'column',
                    justifyContent: 'center',
                    flex: .67,
                }}>
                    {!loading ?
                        leagues.length === 0 ?
                            <Typography variant={'h6'}>
                                You are not apart of any leagues, create or join one!
                            </Typography>
                            :
                            <List sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'auto',
                                maxHeight: '100%',
                                width: '100%',
                                padding: 2,
                            }}>
                                {leagues.map((league: league) => (
                                    <LeagueRow
                                        key={league.id}
                                        user_id={user_id}
                                        league={league}
                                        event_id={eventId}
                                        brackets_completed={bracketsCompleted}
                                        event_active={eventActive}
                                        brackets_open={bracketsOpen}
                                        leagues={leagues}
                                        brackets_viewable={bracketsViewable}
                                    />
                                ))}
                            </List> :
                        <>
                            <LeagueRowSkeleton/>
                            <LeagueRowSkeleton/>
                            <LeagueRowSkeleton/>
                        </>
                    }
                </Grid>
                <Grid container sx={{
                    flex: .34,
                    direction: 'column',
                    justifyContent: 'center',
                    alignContent: 'start',
                    padding: 2
                }}>
                    <Button variant={'contained'} sx={{
                        margin: 2,
                        width: '100%',
                        backgroundColor: "#486ce7",
                        color: "#ffffff",
                        ":hover": {
                            backgroundColor: "#c40000",
                            color: "#ffffff",
                        }
                    }}
                            onClick={() => {
                                window.location.href = '/league/create'
                            }}
                    >
                        <Typography variant={'h6'}>
                            Create League
                        </Typography>
                    </Button>
                    <Button variant={'contained'} sx={{
                        margin: 2,
                        width: '100%',
                        backgroundColor: "#486ce7",
                        color: "#ffffff",
                        ":hover": {
                            backgroundColor: "#c40000",
                            color: "#ffffff",
                        }
                    }}
                            onClick={() => {
                                window.location.href = '/join'
                            }}
                    >
                        <Typography variant={'h6'}>
                            Join League
                        </Typography>
                    </Button>
                    {
                        isMdOrUp ? <AdSpot id={"leagues-1"} sx={{m: 2, width: '100%'}}/> :
                            <AdSpot id={"leagues-1-mobile"} sx={{m: 2, width: '100%'}}/>
                    }
                </Grid>
            </Grid>
        </Layout>
    )
}