import Layout from "../utilities/Layout";
import {ChangeEvent, useEffect, useState} from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import * as React from "react";
import {useMediaQuery, useTheme} from "@mui/material";
import {Theme} from "@mui/material";
import Paper from '@mui/material/Paper';
import StatsTable from "../components/driverstats/StatsTable";
import {RaceClass} from "../data/models/bracket_api/RaceClass";
import {Driver} from "../data/models/bracket_api/Driver";
import {DriverResponse, get_drivers} from "../apis/Driver";
import RaceClassFromInt from "../utilities/RaceClassTools";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {Grid, Typography} from "@mui/material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import {Helmet} from "react-helmet";
import AdSpot from "../components/common/AdSpot";

//Tabs
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 0}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

type RawDriver = {
    first_name: string,
    last_name: string,
    id: number,
    divisions: number[],
    thumbnail: string,
    date_of_birth: string,
    residence: string,
    is_active: boolean,
}

export default function Stats() {
    // Check to make sure user is logged in and we have there info, if not redirect to login
    const [open, setOpen] = useState<boolean>(false);
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));
    const [value, setValue] = React.useState(0);
    const [drivers, setDrivers] = React.useState<Driver[]>([]);
    const [activeDrivers, setActiveDrivers] = React.useState<Driver[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showAll, setShowAll] = useState<boolean>(true);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    useEffect(() => {
        if (loading) {
            let result: RawDriver[] = [];
            try {
                result = JSON.parse(localStorage.getItem('drivers') ?? "[]");
            } catch (e) {
                get_drivers().then((response: DriverResponse) => {
                    localStorage.setItem('drivers', JSON.stringify(response.data));
                    let drivers2: Driver[] = [];
                    result.forEach((driver: RawDriver) => {
                        drivers2.push({
                            id: driver.id,
                            first_name: driver.first_name,
                            last_name: driver.last_name,
                            divisions: RaceClassFromInt(driver.divisions),
                            thumbnail: driver.thumbnail,
                            date_of_birth: driver.date_of_birth,
                            residence: driver.residence,
                            is_active: driver.is_active
                        });
                    });
                    drivers2 = drivers2.filter((driver) => {
                        return driver.id !== 573
                    });
                    setDrivers(drivers2);
                    let activeDrivers: Driver[] = drivers2.filter((driver) => {
                        return driver.is_active
                    });
                    setActiveDrivers(activeDrivers);
                });
            }
            if (result.length === 0) {
                get_drivers().then((response: DriverResponse) => {
                    localStorage.setItem('drivers', JSON.stringify(response.data));
                    let drivers2: Driver[] = [];
                    result.forEach((driver: RawDriver) => {
                        drivers2.push({
                            id: driver.id,
                            first_name: driver.first_name,
                            last_name: driver.last_name,
                            divisions: RaceClassFromInt(driver.divisions),
                            thumbnail: driver.thumbnail,
                            date_of_birth: driver.date_of_birth,
                            residence: driver.residence,
                            is_active: driver.is_active
                        });
                    });
                    drivers2 = drivers2.filter((driver) => {
                        return driver.id !== 573
                    });
                    setDrivers(drivers2);
                    let activeDrivers: Driver[] = drivers2.filter((driver) => {
                        return driver.is_active
                    });
                    setActiveDrivers(activeDrivers);
                });
            }
            let drivers2: Driver[] = [];
            result.forEach((driver: RawDriver) => {
                drivers2.push({
                    id: driver.id,
                    first_name: driver.first_name,
                    last_name: driver.last_name,
                    divisions: RaceClassFromInt(driver.divisions),
                    thumbnail: driver.thumbnail,
                    date_of_birth: driver.date_of_birth,
                    residence: driver.residence,
                    is_active: driver.is_active
                });
            });
            drivers2 = drivers2.filter((driver) => {
                return driver.id !== 573
            });
            setDrivers(drivers2);
            let activeDrivers: Driver[] = drivers2.filter((driver) => {
                return driver.is_active
            });
            setActiveDrivers(activeDrivers);
            const handleResize = () => {
                setIsMdOrUp(window.innerWidth >= 960);
            };

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
        setLoading(false);
    }, [drivers, activeDrivers, loading, isMdOrUp])

    const toggleSwitch = () => {
        setShowAll(!showAll);
    }

    function handleSearchChange(event: React.SyntheticEvent, value: string) {
        let name: string[] = [];
        name = value.split(" ");
        let driverFound: Driver[] = drivers.filter((driver) => {
            if(name.length === 2) {
                if (driver.first_name === name[0] && driver.last_name === name[1]) {
                    return driver;
                }
            }
            else if (name.length === 3) {
                if (driver.first_name === name[0] && driver.last_name === name[1] + " " + name[2]) {
                    return driver;
                }
            }
        })
        if (driverFound.length !== 0) {
            let path: string = window.location.href;
            window.location.assign(path + '/detail?' + encodeURIComponent(`id=${driverFound[0].id}`));
        } else {
            console.log("unsuccessful search")
        }
    }


    return (
        <Layout>
            <Helmet>
                <title>DRBB | Drivers</title>
            </Helmet>
            <Grid width="100%">
                <Grid display="flex" flexDirection={isMdOrUp ? "row" : "column"} width={"75%"}
                      justifyContent="space-between" alignItems="center" position={"relative"} m={"auto"}>
                    <Autocomplete
                        sx={{
                            width: "100%",
                            backgroundColor: 'background.paper',
                            borderRadius: '5px',
                            margin: isMdOrUp ? "8px": "8px 0",
                        }}
                        freeSolo
                        disableClearable
                        onChange={handleSearchChange}
                        options={drivers.map((driver) => (driver.first_name + " " + driver.last_name))}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Search Driver"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                        )}/>
                    <Box component={Paper} elevation={3} width={isMdOrUp ? 'fit-content' : '100%'} display={"flex"} justifyContent={"center"}>
                        <FormGroup sx={{
                            display: 'flex',
                            fontWeight: 'bold',
                            borderRadius: '5px',
                            justifyContent: 'center',
                            width: 'fit-content',
                        }}>
                            <FormControlLabel onChange={toggleSwitch} control={<Switch defaultChecked={false}/>}
                                              label="Active Drivers Only" sx={{ml: '5px'}}/>
                        </FormGroup>
                    </Box>
                </Grid>
                {showAll ?
                    <Paper sx={{width: '75%', m: '16px auto'}}>
                        <Box sx={{width: '100%'}}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <Tabs value={value} variant="scrollable" onChange={handleChange}>
                                    <Tab sx={{fontWeight: 'bolder'}} label="Top Fuel" {...a11yProps(0)} />
                                    <Tab sx={{fontWeight: 'bolder'}} label="Funny Car" {...a11yProps(1)} />
                                    <Tab sx={{fontWeight: 'bolder'}} label="Pro Stock" {...a11yProps(2)} />
                                    <Tab sx={{fontWeight: 'bolder'}} label="Pro Stock Motorcycle" {...a11yProps(3)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <StatsTable
                                    drivers={drivers.filter((driver) => {
                                        return (driver.divisions as RaceClass[]).includes(RaceClass.TOP_FUEL)
                                    }).sort((a: Driver, b: Driver) => {
                                        const lastNameComparison = a.last_name.localeCompare(b.last_name);
                                        if (lastNameComparison !== 0) {
                                            return lastNameComparison;
                                        } else {
                                            return a.first_name.localeCompare(b.first_name);
                                        }
                                    })}/>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <StatsTable
                                    drivers={drivers.filter((driver) => {
                                        return (driver.divisions as RaceClass[]).includes(RaceClass.FUNNY_CAR)
                                    }).sort((a: Driver, b: Driver) => {
                                        const lastNameComparison = a.last_name.localeCompare(b.last_name);
                                        if (lastNameComparison !== 0) {
                                            return lastNameComparison;
                                        } else {
                                            return a.first_name.localeCompare(b.first_name);
                                        }
                                    })}/>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                                <StatsTable
                                    drivers={drivers.filter((driver) => {
                                        return (driver.divisions as RaceClass[]).includes(RaceClass.PRO_STOCK)
                                    }).sort((a: Driver, b: Driver) => {
                                        const lastNameComparison = a.last_name.localeCompare(b.last_name);
                                        if (lastNameComparison !== 0) {
                                            return lastNameComparison;
                                        } else {
                                            return a.first_name.localeCompare(b.first_name);
                                        }
                                    })}/>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={3}>
                                <StatsTable
                                    drivers={drivers.filter((driver) => {
                                        return (driver.divisions as RaceClass[]).includes(RaceClass.PRO_STOCK_MOTORCYCLE)
                                    }).sort((a: Driver, b: Driver) => {
                                        const lastNameComparison = a.last_name.localeCompare(b.last_name);
                                        if (lastNameComparison !== 0) {
                                            return lastNameComparison;
                                        } else {
                                            return a.first_name.localeCompare(b.first_name);
                                        }
                                    })}/>
                            </CustomTabPanel>
                        </Box>
                    </Paper> :
                    <Paper sx={{width: '75%', m: '16px auto'}}>
                        <Box sx={{width: '100%'}}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <Tabs value={value} variant="scrollable" onChange={handleChange}>
                                    <Tab sx={{fontWeight: 'bolder'}} label="Top Fuel" {...a11yProps(0)} />
                                    <Tab sx={{fontWeight: 'bolder'}} label="Funny Car" {...a11yProps(1)} />
                                    <Tab sx={{fontWeight: 'bolder'}} label="Pro Stock" {...a11yProps(2)} />
                                    <Tab sx={{fontWeight: 'bolder'}} label="Pro Stock Motorcycle" {...a11yProps(3)} />
                                </Tabs>
                            </Box>

                            <CustomTabPanel value={value} index={0}>
                                <StatsTable
                                    drivers={activeDrivers.filter((driver) => {
                                        return (driver.divisions as RaceClass[]).includes(RaceClass.TOP_FUEL)
                                    }).sort((a: Driver, b: Driver) => {
                                        const lastNameComparison = a.last_name.localeCompare(b.last_name);
                                        if (lastNameComparison !== 0) {
                                            return lastNameComparison;
                                        } else {
                                            return a.first_name.localeCompare(b.first_name);
                                        }
                                    })}/>
                            </CustomTabPanel>

                            <CustomTabPanel value={value} index={1}>
                                <StatsTable
                                    drivers={activeDrivers.filter((driver) => {
                                        return (driver.divisions as RaceClass[]).includes(RaceClass.FUNNY_CAR)
                                    }).sort((a: Driver, b: Driver) => {
                                        const lastNameComparison = a.last_name.localeCompare(b.last_name);
                                        if (lastNameComparison !== 0) {
                                            return lastNameComparison;
                                        } else {
                                            return a.first_name.localeCompare(b.first_name);
                                        }
                                    })}/>
                            </CustomTabPanel>

                            <CustomTabPanel value={value} index={2}>
                                <StatsTable
                                    drivers={activeDrivers.filter((driver) => {
                                        return (driver.divisions as RaceClass[]).includes(RaceClass.PRO_STOCK)
                                    }).sort((a: Driver, b: Driver) => {
                                        const lastNameComparison = a.last_name.localeCompare(b.last_name);
                                        if (lastNameComparison !== 0) {
                                            return lastNameComparison;
                                        } else {
                                            return a.first_name.localeCompare(b.first_name);
                                        }
                                    })}/>
                            </CustomTabPanel>

                            <CustomTabPanel value={value} index={3}>
                                <StatsTable
                                    drivers={activeDrivers.filter((driver) => {
                                        return (driver.divisions as RaceClass[]).includes(RaceClass.PRO_STOCK_MOTORCYCLE)
                                    }).sort((a: Driver, b: Driver) => {
                                        const lastNameComparison = a.last_name.localeCompare(b.last_name);
                                        if (lastNameComparison !== 0) {
                                            return lastNameComparison;
                                        } else {
                                            return a.first_name.localeCompare(b.first_name);
                                        }
                                    })}/>
                            </CustomTabPanel>

                        </Box>

                    </Paper>
                }
                <AdSpot id={"stats-table-1"} sx={{m: '16px auto', width: '75%'}}/>
            </Grid>
        </Layout>
    );
}