import Layout from "../utilities/Layout";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {League} from "../data/models/user_auth/League";
import {useEffect, useState} from "react";
import ValidateSignIn from "../utilities/ValidateSignIn";
import * as LeagueAPI from "../apis/League";
import {Alert, FormControl, InputLabel, MenuItem, Select, Snackbar} from "@mui/material";
import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CssBaseline from "@mui/material/CssBaseline";
import GA4EventSend from "../utilities/GA4EventSend";
import {Helmet} from "react-helmet";
/*
1) TODO put field for an email and which league they want to invite you to
2) TODO both should display error / success message
 */
export default function Invite() {
    const [selectedLeague, setSelectedLeague] = useState<number>(1);
    const [leagues, setLeagues] = useState<League[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [email, setEmail] = useState<string>("");
    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<String>("");
    const [severity, setSeverity] = useState<"success" | "info" | "warning" | "error" | undefined>("error"); // ["success", "info", "warning", "error"
    const [isClicked, setIsClicked] = useState(false);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        if (loading) {
            ValidateSignIn().then(() => {
                // Get user id from local storage and convert it to number
                let user_id: number = JSON.parse(localStorage.getItem('user') as string).id;
                LeagueAPI.get_leagues_per_player(user_id).then((response: LeagueAPI.LeagueResponse) => {
                    if (response.succeeded) {
                        setLeagues(response.data);
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }
                    GA4EventSend("leagues", "load", "leagues", response.statusCode, false);
                });
            });
        }
    }, [loading, leagues]);

    async function inviteFriend() {
        setIsClicked(true);
        if (email.length === 0) {
            setMessage("Please enter an email");
            setSeverity("error");
            setOpen(true);
            return;
        }
        if (selectedLeague < 1) {
            setMessage("Please select a league");
            setSeverity("error");
            setOpen(true);
            return;
        }
        let user_id: number = JSON.parse(localStorage.getItem('user') as string).id;
        LeagueAPI.invite_player_to_league(email, user_id, selectedLeague).then((response: LeagueAPI.LeagueResponse) => {
            if (response.succeeded) {
                setMessage("Successfully invited friend to league");
                setSeverity("success");
                setOpen(true);
            } else {
                setMessage(response.message);
                setSeverity("error");
                setOpen(true);
            }
            GA4EventSend("league_invite", "submit", "league_invite", response.statusCode, false);
        });
    }

    return (
        <Layout>
            <Helmet>
                <title>DRBB | Invite</title>
            </Helmet>
            <CssBaseline/>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={open} onClose={handleClose}
                      autoHideDuration={6000}>
                <Alert elevation={6} severity={severity} sx={{width: '100%'}} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
            <Grid container sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%"
            }}>
                <Typography variant={"h4"}>
                    Invite a friend
                </Typography>
                <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={(event) => {
                        setEmail(event.target.value);
                    }}
                    sx={{
                        width: 'fit-content',
                        minWidth: '300px',
                        marginY: 1,
                    }}
                />
                {loading ? <></> :
                    <FormControl sx={{
                        width: 'fit-content',
                        minWidth: '300px',
                        marginY: 1,
                    }}>
                        <InputLabel id="league-label">Select League to Join</InputLabel>
                        <Select
                            labelId="league-label"
                            id="league-select"
                            label={"Select League to Join"}
                            value={selectedLeague}
                            onChange={(event) => {
                                setSelectedLeague(event.target.value as number)
                            }}
                        >
                            {leagues.map((l: League) => {
                                return <MenuItem key={l.id} value={l.id}>{l.name}</MenuItem>
                            })};
                        </Select>
                    </FormControl>
                }
                {leagues.length > 1 ?
                    <Button
                        variant={"contained"}
                        disabled={email.length === 0 || selectedLeague < 1 || isClicked}
                        onClick={() => {
                            setIsClicked(true)
                            inviteFriend().then(() => {
                                setIsClicked(false);
                            })
                        }}
                    >
                        <Typography variant={"h6"}>
                            {
                                selectedLeague !== 0 ?
                                    email.length > 0 ?
                                        "Invite friend to " + leagues.find((l: League) => l.id === selectedLeague)?.name
                                        : "Please enter email"
                                    : "Please select a league"
                            }
                        </Typography>
                    </Button> :
                    <Button
                        variant={"contained"}
                        disabled={email.length === 0 || isClicked}
                        onClick={() => {
                            setIsClicked(true)
                            inviteFriend().then(() => {
                                setIsClicked(false);
                            })
                        }}
                    >
                        <Typography variant={"h6"}>
                            {selectedLeague ? "Invite friend to Drag race Bracket Bonanza" : "Please enter email"}
                        </Typography>
                    </Button>
                }
            </Grid>
        </Layout>
    );
}