import Grid from "@mui/material/Grid";
import PrintRow from "../PrintRow";
import {Divider} from "@mui/material";
import {Pick} from "../../../../data/models/bracket_api/Pick";
import Paper from "@mui/material/Paper";
import {Result} from "../../../../data/models/bracket_api/Result";

export type TwoWidePickBlockProps = {
    pick?: Pick,
    result?: Result,
    updatePick: (pick: Pick) => void,
    picks_locked?: boolean
}

export default function PrintBlock({pick, result, updatePick, picks_locked}: Readonly<TwoWidePickBlockProps>){
    return (
        <Grid container my={0.5} sx={{
            display: 'flex',
            flexDirection: "column",
            height: 'fit-content',
            width: 'fit-content',
            border: '1px solid black',
        }}>
            <PrintRow
                competitor_number={1}
                pick={pick}
                result={result}
                fourWide={false}
            />
            <PrintRow
                competitor_number={2}
                pick={pick}
                result={result}
                fourWide={false}
            />
        </Grid>
    )
}