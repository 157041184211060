export const SubmitButtonStyles = {
    type: "submit",
    backgroundColor: "#00FF00",
    margin: "8px",
    borderRadius: "0px !important"
}

export const PrimaryButtonStyles = {
    borderRadius: "0px !important",
    width: '100%'
}

export const SecondaryButtonStyles = {

}