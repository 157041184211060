import {Bracket} from "../../../data/models/bracket_api/Bracket";
import {Result} from "../../../data/models/bracket_api/Result";
import {FourWideResult} from "../../../data/models/bracket_api/FourWideResult";
import {Event} from "../../../data/models/bracket_api/Event";
import Grid from "@mui/material/Grid";
import ResultsCard from "./ResultsCard";
import {Qualifier} from "../../../data/models/bracket_api/Qualifier";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import {Modal, Theme, useMediaQuery} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import QualifierCarousel from "../../qualifiers/QualifierCarousel";

export type ResultsCardContainerProps = {
    results: Result[] | FourWideResult[],
    event?: Event,
    brackets: Bracket[],
    qualifiers: Qualifier[],
}

export default function ResultsCardContainer(props: Readonly<ResultsCardContainerProps>) {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));

    useEffect(() => {

        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp]);

    let filteredResults: Result[] | FourWideResult[] = [];
    if(props.results) {
        if (props.event && props.event.is_four_wide) {
            for (let i = 0; i < props.results.length; i++) {
                let result = props.results[i] as FourWideResult;
                let drivers = [result.competitor_1, result.competitor_2, result.competitor_3, result.competitor_4];
                let blankCount = 0;
                for (let driver of drivers) {
                    if (driver === null) {
                        blankCount++;
                    }
                }
                if (blankCount < 2) {
                    filteredResults.push(props.results[i]);
                }
            }
        } else {
            for (let i = 0; i < props.results.length; i++) {
                let result = props.results[i] as Result;
                let drivers = [result.competitor_1, result.competitor_2];
                let blankCount = 0;
                for (let driver of drivers) {
                    if (driver === null) {
                        blankCount++;
                    }
                }
                if (blankCount < 1) {
                    filteredResults.push(props.results[i]);
                }
            }
        }
    }

    filteredResults = filteredResults.sort((a, b) => {
        if(a.bracket.race_class < b.bracket.race_class){
            return -1;
        }
        else if(a.bracket.race_class > b.bracket.race_class){
            return 1;
        }
        else {
            if (a.round < b.round) {
                return 1;
            } else if (a.round > b.round) {
                return -1;
            } else {
                if (a.game < b.game) {
                    return -1;
                } else if (a.game > b.game) {
                    return 1;
                }
                return 0
            }
        }
    });



    return (
        <Grid container sx={{
            maxHeight: isMdOrUp ? '55vh' : '100vh',
            width: isMdOrUp ? '40vw' : '100vw',
            display: 'flex',
            flexDirection: 'column',
            padding: 1,
            flexWrap: 'nowrap',
        }}>
            <Modal open={openModal} onClose={() => {
                setOpenModal(false)
            }} sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                height: '100%',
                background: 'transparent',
                boxShadow: 'shadow.5',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Grid container sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    padding: 1,
                    backgroundColor: 'background.paper',
                    height: '500px',
                    width: 'fit-content',
                    borderRadius: '5px',
                }}>
                    <CloseIcon sx={{
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        margin: '1rem',
                        cursor: 'pointer',
                    }} onClick={() => {
                        setOpenModal(false)
                    }}/>
                    <QualifierCarousel
                        event_name={props.event ? props.event.name : ""}
                        qualifiers={props.qualifiers}
                    />
                </Grid>
            </Modal>
            <Grid container sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 1,
                justifyContent: "space-between",
                backgroundColor: 'background.paper',
                borderRadius: '5px',
            }}>
                <Typography variant={"h5"} fontWeight={"bold"}>
                    {props.event ? props.event.name + " Results": ""}
                </Typography>
                <Button
                    key={"view-qualifiers"}
                    variant="contained" style={{margin: '8px'}} sx={{
                    backgroundColor: "#486ce7",
                    color: "#ffffff",
                    borderRadius: 0,
                    width: '100%',
                    ":hover": {
                        backgroundColor: "#c40000",
                        color: "#ffffff",
                    }
                }}
                        onClick={() => {setOpenModal(true)}}
                >
                    <Typography variant={"h6"}>
                        View Qualifiers
                    </Typography>
                </Button>
            </Grid>
            <Grid container sx={{
                height: '100%',
                overflowX: 'auto',
                display: 'flex',
                flexDirection: 'column',
            }}>
                {filteredResults.map((result) => {
                    return <ResultsCard result={result} qualifiers={props.qualifiers}/>
                })}
            </Grid>
        </Grid>
    )
}