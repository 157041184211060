import Grid from "@mui/material/Grid";
import {Pick} from "../../../data/models/bracket_api/Pick";
import Typography from "@mui/material/Typography";
import {PickStatus} from "../../../data/models/bracket_api/PickStatus";
import {Result} from "../../../data/models/bracket_api/Result";
import {Driver} from "../../../data/models/bracket_api/Driver";
import {FourWidePick} from "../../../data/models/bracket_api/FourWidePick";
import {FourWideResult} from "../../../data/models/bracket_api/FourWideResult";
import {determinePosition} from "../picks/PickRowFunctions";

export type pickProps = {
    competitor_number?: number,
    pick?: Pick | FourWidePick,
    result?: Result | FourWideResult,
    fourWide: boolean
}

export default function PrintRow({
                                    competitor_number,
                                    pick,
                                    result,
                                    fourWide
                                }: Readonly<pickProps>) {
    let position: "1st" | "2nd" | "3rd" | "4th" | "" = "";

    let byeRace = pick?.pick_status === PickStatus.BYE;

    let driver: Driver | undefined;
    let seed: number | undefined;
    if (competitor_number === 1) {
        driver = pick?.competitor_1;
        seed = pick?.seed_1;
    } else if (competitor_number === 2) {
        driver = pick?.competitor_2;
        seed = pick?.seed_2;
    } else if (competitor_number === 3) {
        driver = (pick as FourWidePick)?.competitor_3;
        seed = (pick as FourWidePick)?.seed_3;
    } else if (competitor_number === 4) {
        driver = (pick as FourWidePick)?.competitor_4;
        seed = (pick as FourWidePick)?.seed_4;
    }
    position = determinePosition(fourWide, driver, pick);

    return (
        <Grid container my={'1px'} sx={{
            display: "flex",
            flexDirection: "row",
            padding: .5,
            width: "fit-content",
            minWidth: fourWide ? "145px" : "110px",
            height: "16px",
            flexWrap: "nowrap",
            alignItems: 'center',
            backgroundColor: "#ffffff",
            color: "#000000",
            borderBottom: fourWide && competitor_number !== 4 ? "1px solid #000000" : !fourWide && competitor_number !== 2 ? "1px solid #000000" : "none",
        }}>
            {driver !== undefined ?
                <Typography
                    align={"left"}
                    variant={"subtitle1"}
                    fontSize={".6rem !important"}
                    fontWeight={"bold"}
                    noWrap
                    flex={1}
                >
                    {driver.first_name + " " + driver.last_name}
                </Typography> :
                <Typography
                    align={"center"}
                    variant={"subtitle1"}
                    fontSize={".6rem !important"}
                    fontWeight={"bold"}
                    noWrap
                    flex={1}
                >
                    {byeRace ? "BYE" : ""}
                </Typography>
            }
            {fourWide &&
                <Typography
                    variant={"subtitle1"}
                    fontSize={".55rem !important"}
                    textOverflow={"fade"}
                    align={"right"}
                    noWrap
                    mx={1}
                >
                    {position}
                </Typography>
            }
            <Typography
                align={"right"}
                fontSize={".55rem !important"}
                variant={"subtitle1"}
            >
                {seed === undefined || seed === 0 ? "" : seed}
            </Typography>
        </Grid>
    )
}