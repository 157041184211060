import React, {useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Logo from "../../assets/images/logo.png";
import Copyright from "../../utilities/Copyright";
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Link from "@mui/material/Link";
import {Theme, useMediaQuery} from "@mui/material";

export default function Footer() {
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));
    useEffect(() => {
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp]);
    return (
        <Grid data-testid={"footer"} container sx={{
            left: 0,
            bottom: 0,
            width: '100%',
            height: 'fit-content',
            backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : "#141414",
            justifyContent: "center",
        }}
              p={5}
        >
            <Grid item justifyContent={"center"} sx={{flex: 1, m: isMdOrUp ? "" : "auto"}}>
                <Grid>
                    <img data-testid={"drbb-logo"} src={Logo} height={'100'} alt={"DRBB Logo"}/>
                </Grid>
                <Copyright sx={{textAlign: "left"}}/>
                <Link href={"/terms"} target={"_blank"}>
                    <Typography variant="body2" color="text.secondary" align="center" textAlign={"left"}>
                        Terms of Service
                    </Typography>
                </Link>
                <Link href={"/privacy"} target={"_blank"}>
                    <Typography variant="body2" color="text.secondary" align="center" textAlign={"left"}>
                        Privacy Policy
                    </Typography>
                </Link>
            </Grid>
            <Grid display="flex" flexDirection="row">
                <Grid item sx={{m: 3}}>
                    <Typography variant="body1" color="text" align="left" fontWeight={"bold"}>
                        Company
                    </Typography>
                    <Link href="/help">
                        <Typography variant="body2" color="text.secondary" align="left">
                            Help
                        </Typography>
                    </Link>
                    <Link href="mailto:elon@dragracebracketbonanza.com" target="_blank">
                        <Typography variant="body2" color="text.secondary" align="left">
                            Contact Us
                        </Typography>
                    </Link>
                    <Link href="/about">
                        <Typography variant="body2" color="text.secondary" align="left">
                            About Us
                        </Typography>
                    </Link>
                </Grid>
                <Grid item sx={{m: 3}}>
                    <Typography variant="body1" color="text" align="left" fontWeight={"bold"}>
                        Social Media
                    </Typography>
                    <Link href={"https://facebook.com/DragRaceBracketBonanza/"} target={"_blank"} rel={"noopener"}>
                        <Grid container>
                            <FacebookIcon style={{color: '#1877f2', paddingRight: 2}}/>
                            <Typography variant="body2" color="text.secondary" align="left">
                                Facebook
                            </Typography>
                        </Grid>
                    </Link>
                    <Link href={"https://twitter.com/dragracebb"} target={"_blank"} rel={"noopener"}>
                        <Grid container>
                            <TwitterIcon style={{color: '#1da1f2', paddingRight: 2}}/>
                            <Typography variant="body2" color="text.secondary" align="left">
                                Twitter
                            </Typography>
                        </Grid>
                    </Link>
                    <Link href={"https://instagram.com/dragracebracketbonanza/"} target={"_blank"} rel={"noopener"}>
                        <Grid container>
                            <InstagramIcon style={{color: '#c32aa3', paddingRight: 2}}/>
                            <Typography variant="body2" color="text.secondary" align="left">
                                Instagram
                            </Typography>
                        </Grid>
                    </Link>
                    <Link href={"https://www.youtube.com/@DragRaceBracketBonanza"} target={"_blank"} rel={"noopener"}>
                        <Grid container>
                            <YouTubeIcon style={{color: '#FF0000', paddingRight: 2}}/>
                            <Typography variant="body2" color="text.secondary" align="left">
                                YouTube
                            </Typography>
                        </Grid>
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    );
};


