import * as React from "react";
import {getRaceExceptionString, RaceException} from "../../../data/models/bracket_api/RaceException";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Helmet from "../../../assets/images/helmet.png";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {FourWideResult} from "../../../data/models/bracket_api/FourWideResult";

export default class FourWideResultForm extends React.Component<{
    fullScreen: boolean,
    open: boolean,
    onClose: () => void,
    result: FourWideResult,
    onImageError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void,
    changeException1: (event: React.SyntheticEvent<HTMLFormElement, Event>) => void,
    raceException: RaceException,
    updateException1: (event: any) => void,
    exception1Row: (key: any) => React.JSX.Element,
    raceException1: RaceException,
    raceException2: RaceException,
    raceException3: RaceException,
    updateException2: (event: any) => void,
    exception2Row: (key: any) => React.JSX.Element,
    exception3Row: (key: any) => React.JSX.Element,
    exception4Row: (key: any) => React.JSX.Element,
    firstId: number,
    updateFirst: (event: any) => void
    updateException3: (event: any) => void,
    updateException4: (event: any) => void,
    secondId: number,
    updateSecond: (event: any) => void,
    thirdId: number,
    updateThird: (event: any) => void,
    fourthId: number,
    updateFourth: (event: any) => void,
}> {
    render() {
        return <Dialog
            fullScreen={this.props.fullScreen}
            maxWidth={"lg"}
            open={this.props.open}
            onClose={this.props.onClose}
            aria-labelledby="responsive-dialog-title"
            sx={{
                margin: "auto",
                minWidth: "900px"
            }}
        >
            <DialogTitle id="responsive-dialog-title" fontWeight={"bold"} textAlign={"center"}>
                {this.props.result?.bracket.race_class} Round {this.props.result?.round} Race {this.props.result?.game} Results
            </DialogTitle>
            <DialogContent>
                <Grid container direction={"column"}>
                    <Grid container direction={"row"} sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                    }}>
                        {this.props.result?.competitor_1?.thumbnail ?
                            <img
                                src={this.props.result?.competitor_1?.thumbnail}
                                onError={this.props.onImageError}
                                alt={"Driver headshot"}
                                style={{flex: 0.5, margin: "12px"}}
                            /> :
                            <img
                                src={Helmet}
                                alt={"Driver headshot"}
                                style={{flex: 0.5, margin: "12px"}}
                            />
                        }
                        {this.props.result?.competitor_2?.thumbnail ?
                            <img
                                src={this.props.result?.competitor_2?.thumbnail}
                                onError={this.props.onImageError}
                                alt={"Driver headshot"}
                                style={{flex: 0.5, margin: "12px"}}
                            /> :
                            <img
                                src={Helmet}
                                alt={"Driver headshot"}
                                style={{flex: 0.5, margin: "12px"}}
                            />
                        }
                        {this.props.result?.competitor_3?.thumbnail ?
                            <img
                                src={this.props.result?.competitor_3?.thumbnail}
                                onError={this.props.onImageError}
                                alt={"Driver headshot"}
                                style={{flex: 0.5, margin: "12px"}}
                            /> :
                            <img
                                src={Helmet}
                                alt={"Driver headshot"}
                                style={{flex: 0.5, margin: "12px"}}
                            />
                        }
                        {this.props.result?.competitor_4?.thumbnail ?
                            <img
                                src={this.props.result?.competitor_4?.thumbnail}
                                onError={this.props.onImageError}
                                alt={"Driver headshot"}
                                style={{flex: 0.5, margin: "12px"}}
                            /> :
                            <img
                                src={Helmet}
                                alt={"Driver headshot"}
                                style={{flex: 0.5, margin: "12px"}}
                            />
                        }
                    </Grid>
                    <Grid container direction={"row"} sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                    }}>
                        <Typography variant={"h6"} sx={{
                            flex: 0.5,
                            textAlign: "center",
                        }}>
                            {this.props.result?.competitor_1?.first_name + " " + this.props.result?.competitor_1?.last_name}
                        </Typography>
                        <Typography variant={"h6"} sx={{
                            flex: 0.5,
                            textAlign: "center",
                        }}>
                            {this.props.result?.competitor_2?.first_name + " " + this.props.result?.competitor_2?.last_name}
                        </Typography>
                        <Typography variant={"h6"} sx={{
                            flex: 0.5,
                            textAlign: "center",
                        }}>
                            {this.props.result?.competitor_3?.first_name + " " + this.props.result?.competitor_3?.last_name}
                        </Typography>
                        <Typography variant={"h6"} sx={{
                            flex: 0.5,
                            textAlign: "center",
                        }}>
                            {this.props.result?.competitor_4?.first_name + " " + this.props.result?.competitor_4?.last_name}
                        </Typography>
                    </Grid>
                    <Divider/>
                    <Box component={"form"} onChange={this.props.changeException1}>
                        <Grid container direction={"row"} sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                        }}>
                            <TextField
                                type={"text"}
                                label={"Elapsed Time"}
                                variant={"filled"}
                                name={"competitor_1_time"}
                                defaultValue={this.props.result?.competitor_1_time}
                                sx={{
                                    flex: 0.5,
                                    margin: "8px"
                                }}
                            />
                            <TextField
                                type={"text"}
                                label={"Elapsed Time"}
                                variant={"filled"}
                                name={"competitor_2_time"}
                                defaultValue={this.props.result?.competitor_2_time}
                                sx={{
                                    flex: 0.5,
                                    margin: "8px"
                                }}
                            />
                            <TextField
                                type={"text"}
                                label={"Elapsed Time"}
                                variant={"filled"}
                                name={"competitor_3_time"}
                                defaultValue={this.props.result?.competitor_3_time}
                                sx={{
                                    flex: 0.5,
                                    margin: "8px"
                                }}
                            />
                            <TextField
                                type={"text"}
                                label={"Elapsed Time"}
                                variant={"filled"}
                                name={"competitor_4_time"}
                                defaultValue={this.props.result?.competitor_4_time}
                                sx={{
                                    flex: 0.5,
                                    margin: "8px"
                                }}
                            />
                        </Grid>
                        <Grid container direction={"row"} sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                        }}>
                            <TextField
                                type={"text"}
                                label={"Speed"}
                                variant={"filled"}
                                name={"competitor_1_speed"}
                                defaultValue={this.props.result?.competitor_1_speed}
                                sx={{
                                    flex: 0.5,
                                    margin: "8px"
                                }}
                            />
                            <TextField
                                type={"text"}
                                label={"Speed"}
                                variant={"filled"}
                                name={"competitor_2_speed"}
                                defaultValue={this.props.result?.competitor_2_speed}
                                sx={{
                                    flex: 0.5,
                                    margin: "8px"
                                }}
                            />
                            <TextField
                                type={"text"}
                                label={"Speed"}
                                variant={"filled"}
                                name={"competitor_3_speed"}
                                defaultValue={this.props.result?.competitor_3_speed}
                                sx={{
                                    flex: 0.5,
                                    margin: "8px"
                                }}
                            />
                            <TextField
                                type={"text"}
                                label={"Speed"}
                                variant={"filled"}
                                name={"competitor_4_speed"}
                                defaultValue={this.props.result?.competitor_4_speed}
                                sx={{
                                    flex: 0.5,
                                    margin: "8px"
                                }}
                            />
                        </Grid>
                        <Grid container direction={"row"} sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                        }}>
                            <TextField
                                type={"text"}
                                label={"Reaction Time"}
                                variant={"filled"}
                                name={"competitor_1_reaction_time"}
                                defaultValue={this.props.result?.competitor_1_reaction_time}
                                sx={{
                                    flex: 0.5,
                                    margin: "8px"
                                }}
                            />
                            <TextField
                                type={"text"}
                                label={"Reaction Time"}
                                variant={"filled"}
                                name={"competitor_2_reaction_time"}
                                defaultValue={this.props.result?.competitor_2_reaction_time}
                                sx={{
                                    flex: 0.5,
                                    margin: "8px"
                                }}
                            />
                            <TextField
                                type={"text"}
                                label={"Reaction Time"}
                                variant={"filled"}
                                name={"competitor_3_reaction_time"}
                                defaultValue={this.props.result?.competitor_3_reaction_time}
                                sx={{
                                    flex: 0.5,
                                    margin: "8px"
                                }}
                            />
                            <TextField
                                type={"text"}
                                label={"Reaction Time"}
                                variant={"filled"}
                                name={"competitor_4_reaction_time"}
                                defaultValue={this.props.result?.competitor_4_reaction_time}
                                sx={{
                                    flex: 0.5,
                                    margin: "8px"
                                }}
                            />
                        </Grid>
                        <Grid container direction={"row"} sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                        }}>
                            <FormControl sx={{
                                flex: 0.5,
                                margin: "8px"
                            }}>
                                <InputLabel id="competitor_1_exception">Race Exception</InputLabel>
                                <Select
                                    labelId="competitor_1_exception"
                                    defaultValue={getRaceExceptionString(this.props.raceException)}
                                    label={"Race Exception"}
                                    onChange={this.props.updateException1}
                                >
                                    {Object.keys(RaceException).map(this.props.exception1Row)}
                                </Select>
                            </FormControl>
                            <FormControl sx={{
                                flex: 0.5,
                                margin: "8px"
                            }}>
                                <InputLabel id="competitor_2_exception">Race Exception</InputLabel>
                                <Select
                                    labelId="competitor_2_exception"
                                    defaultValue={getRaceExceptionString(this.props.raceException1)}
                                    label={"Race Exception"}
                                    onChange={this.props.updateException2}
                                >
                                    {Object.keys(RaceException).map(this.props.exception2Row)}
                                </Select>
                            </FormControl>
                            <FormControl sx={{
                                flex: 0.5,
                                margin: "8px"
                            }}>
                                <InputLabel id="competitor_3_exception">Race Exception</InputLabel>
                                <Select
                                    labelId="competitor_3_exception"
                                    defaultValue={getRaceExceptionString(this.props.raceException2)}
                                    label={"Race Exception"}
                                    onChange={this.props.updateException3}
                                >
                                    {Object.keys(RaceException).map(this.props.exception3Row)}
                                </Select>
                            </FormControl>
                            <FormControl sx={{
                                flex: 0.5,
                                margin: "8px"
                            }}>
                                <InputLabel id="competitor_4_exception">Race Exception</InputLabel>
                                <Select
                                    labelId="competitor_4_exception"
                                    defaultValue={getRaceExceptionString(this.props.raceException3)}
                                    label={"Race Exception"}
                                    onChange={this.props.updateException4}
                                >
                                    {Object.keys(RaceException).map(this.props.exception4Row)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <FormControl sx={{
                            width: "100%",
                            margin: "8px",
                        }}>
                            <InputLabel id={"first-label" + this.props.result?.game_code}>First</InputLabel>
                            <Select
                                labelId={"first-label" + this.props.result?.game_code}
                                defaultValue={this.props.firstId}
                                onChange={this.props.updateFirst}
                                label={"First"}
                            >
                                <MenuItem value={0}>None</MenuItem>
                                <MenuItem
                                    value={this.props.result?.competitor_1?.id}>{this.props.result?.competitor_1?.first_name + " " + this.props.result?.competitor_1?.last_name}</MenuItem>
                                <MenuItem
                                    value={this.props.result?.competitor_2?.id}>{this.props.result?.competitor_2?.first_name + " " + this.props.result?.competitor_2?.last_name}</MenuItem>
                                <MenuItem
                                    value={this.props.result?.competitor_3?.id}>{this.props.result?.competitor_3?.first_name + " " + this.props.result?.competitor_3?.last_name}</MenuItem>
                                <MenuItem
                                    value={this.props.result?.competitor_4?.id}>{this.props.result?.competitor_4?.first_name + " " + this.props.result?.competitor_4?.last_name}</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{
                            width: "100%",
                            margin: "8px",
                        }}>
                            <InputLabel id={"second-label" + this.props.result?.game_code}>Second</InputLabel>
                            <Select
                                labelId={"second-label" + this.props.result?.game_code}
                                defaultValue={this.props.secondId}
                                onChange={this.props.updateSecond}
                                label={"Second"}
                            >
                                <MenuItem value={0}>None</MenuItem>
                                <MenuItem
                                    value={this.props.result?.competitor_1?.id}>{this.props.result?.competitor_1?.first_name + " " + this.props.result?.competitor_1?.last_name}</MenuItem>
                                <MenuItem
                                    value={this.props.result?.competitor_2?.id}>{this.props.result?.competitor_2?.first_name + " " + this.props.result?.competitor_2?.last_name}</MenuItem>
                                <MenuItem
                                    value={this.props.result?.competitor_3?.id}>{this.props.result?.competitor_3?.first_name + " " + this.props.result?.competitor_3?.last_name}</MenuItem>
                                <MenuItem
                                    value={this.props.result?.competitor_4?.id}>{this.props.result?.competitor_4?.first_name + " " + this.props.result?.competitor_4?.last_name}</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{
                            width: "100%",
                            margin: "8px",
                        }}>
                            <InputLabel id={"third-label" + this.props.result?.game_code}>Third</InputLabel>
                            <Select
                                labelId={"third-label" + this.props.result?.game_code}
                                defaultValue={this.props.thirdId}
                                onChange={this.props.updateThird}
                                label={"Third"}
                            >
                                <MenuItem value={0}>None</MenuItem>
                                <MenuItem
                                    value={this.props.result?.competitor_1?.id}>{this.props.result?.competitor_1?.first_name + " " + this.props.result?.competitor_1?.last_name}</MenuItem>
                                <MenuItem
                                    value={this.props.result?.competitor_2?.id}>{this.props.result?.competitor_2?.first_name + " " + this.props.result?.competitor_2?.last_name}</MenuItem>
                                <MenuItem
                                    value={this.props.result?.competitor_3?.id}>{this.props.result?.competitor_3?.first_name + " " + this.props.result?.competitor_3?.last_name}</MenuItem>
                                <MenuItem
                                    value={this.props.result?.competitor_4?.id}>{this.props.result?.competitor_4?.first_name + " " + this.props.result?.competitor_4?.last_name}</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{
                            width: "100%",
                            margin: "8px",
                        }}>
                            <InputLabel id={"fourth-label" + this.props.result?.game_code}>Fourth</InputLabel>
                            <Select
                                labelId={"second-label" + this.props.result?.game_code}
                                defaultValue={this.props.fourthId}
                                onChange={this.props.updateFourth}
                                label={"Fourth"}
                            >
                                <MenuItem value={0}>None</MenuItem>
                                <MenuItem
                                    value={this.props.result?.competitor_1?.id}>{this.props.result?.competitor_1?.first_name + " " + this.props.result?.competitor_1?.last_name}</MenuItem>
                                <MenuItem
                                    value={this.props.result?.competitor_2?.id}>{this.props.result?.competitor_2?.first_name + " " + this.props.result?.competitor_2?.last_name}</MenuItem>
                                <MenuItem
                                    value={this.props.result?.competitor_3?.id}>{this.props.result?.competitor_3?.first_name + " " + this.props.result?.competitor_3?.last_name}</MenuItem>
                                <MenuItem
                                    value={this.props.result?.competitor_4?.id}>{this.props.result?.competitor_4?.first_name + " " + this.props.result?.competitor_4?.last_name}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={this.props.onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }
}