import {useEffect, useState} from "react";
import {Event} from "../../data/models/bracket_api/Event";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LinearProgress from '@mui/material/LinearProgress';
import {Divider, Theme, useMediaQuery, useTheme} from "@mui/material";
import BracketIcon from "../../assets/images/bracket.svg";
import BracketIconDark from "../../assets/images/bracket_black.svg"
import Button from "@mui/material/Button";

type TUseTimer = {
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
};

const formatNumber = (num: number) => {
    return num < 10 ? `0${num}` : `${num}`;
};

const DAYS_IN_MS = 1000 * 60 * 60 * 24;
const HOURS_IN_MS = 1000 * 60 * 60;
const MIN_IN_MS = 1000 * 60;
const SEC_IN_MS = 1000;

const getTimeDiff = (diffInMSec: number): TUseTimer => {
    let diff = diffInMSec;
    const days = Math.floor(diff / DAYS_IN_MS);
    diff -= days * DAYS_IN_MS;
    const hours = Math.floor(diff / HOURS_IN_MS);
    diff -= hours * HOURS_IN_MS;
    const minutes = Math.floor(diff / MIN_IN_MS);
    diff -= minutes * MIN_IN_MS;
    const seconds = Math.floor(diff / SEC_IN_MS);
    return {
        days: days as unknown as string,
        hours: formatNumber(hours),
        minutes: formatNumber(minutes),
        seconds: formatNumber(seconds),
    };
};

const useTimer = (targetTime: number): TUseTimer => {
    const [timeLeft, setTimeLeft] = useState(targetTime - Date.now());

    useEffect(() => {
        const id = setTimeout(() => {
            // We can set conditions here like timeLeft > 0
            setTimeLeft((prev) => prev - 1000);
        }, 1000);

        return () => {
            clearTimeout(id);
        };
    });

    return getTimeDiff(timeLeft);
};

type BracketsOpenContainerProps = {
    event: Event | undefined
}

export default function BracketsOpenContainer(props: Readonly<BracketsOpenContainerProps>) {
    const theme = useTheme();
    const darkMode = theme.palette.mode === 'dark';
    const [deadline, setDeadline] = useState<Date>(new Date(props.event?.open_date || new Date()));
    const time = useTimer(deadline.getTime());
    const [bracketsOpen, setBracketsOpen] = useState(!props.event?.picks_locked || false);
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));
    const [bracketsOpenState, setBracketOpenState] = useState<"countdown" | "late" | "open" | "closed">("countdown");

    useEffect(() => {
        if(bracketsOpen){
            setBracketOpenState("open")
        }
        else if(!bracketsOpen && props.event?.close_date && new Date() < new Date(props.event?.close_date) && new Date() > new Date(props.event?.open_date)){
            setBracketOpenState("late")
        }
        else if(!bracketsOpen && props.event?.close_date && new Date() > new Date(props.event?.close_date)){
            setBracketOpenState("closed")
        }
        else{
            setBracketOpenState("countdown")
        }

        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [bracketsOpen]);

    // Calculate total time in seconds
    const startDate = props.event ? new Date(props.event?.start_date) : new Date();
    const oneWeekBeforeStartDate = new Date(startDate.getTime() - 7 * 24 * 60 * 60 * 1000);
    const startTime = new Date() < oneWeekBeforeStartDate ? new Date() : oneWeekBeforeStartDate;
    const totalTime = Math.floor((deadline.getTime() - startTime.getTime()) / 1000);

    // Calculate remaining time in seconds
    const remainingTime = parseInt(time.days) * 24 * 60 * 60 + parseInt(time.hours) * 60 * 60 + parseInt(time.minutes) * 60 + parseInt(time.seconds);

    // Calculate progress percentage
    const progress = ((totalTime - remainingTime) / totalTime) * 100;

    return(
        props.event ?
        <Grid container sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 2,
            maxWidth: isMdOrUp ? '80vw' : '95vw',
            width: '100%',
            backgroundColor: 'background.paper',
            borderRadius: '10px',
            my: 2,
            maxHeight: 'fit-content',
        }}>
            {bracketsOpenState === "countdown" ?
                <Grid item width={"100%"}>
                    <Grid container display={"flex"} flexDirection={"row"} position={"relative"} alignItems={"center"}>
                        <Typography variant={isMdOrUp ? "h2": "h4"} fontWeight={"bold"} sx={{fontStyle: 'italic', width: isMdOrUp ? '75%': '100%'}}>
                            {`Brackets For ${props.event?.name} Open In: ${time.days}:${time.hours}:${time.minutes}:${time.seconds}`}
                        </Typography>
                        {isMdOrUp && <img src={darkMode ? BracketIcon : BracketIconDark} alt={"Bracket Icon"} style={{maxWidth: '25%', maxHeight: '220px', transform: 'skewX(-20deg)'}}/>}
                    </Grid>
                    <Divider sx={{marginY: 2, width: '100%'}} />
                    <LinearProgress variant="determinate" value={progress} sx={{height: '30px', backgroundColor: "#c40000", transform: 'skewX(-20deg)'}}/>
                </Grid> :
                bracketsOpenState === "late" ?
                <Grid item width={"100%"}>
                    <Grid container display={"flex"} flexDirection={"row"} position={"relative"}>
                        <Typography variant={isMdOrUp ? "h2": "h4"} fontWeight={"bold"} sx={{fontStyle: 'italic', width: isMdOrUp ? '75%': '100%'}}>
                            {`Brackets Open For ${props.event?.name} Soon`}
                        </Typography>
                        {isMdOrUp && <img src={darkMode ? BracketIcon : BracketIconDark} alt={"Bracket Icon"} style={{maxWidth: '25%', maxHeight: '220px', transform: 'skewX(-20deg)'}}/>}
                    </Grid>
                </Grid> :
                bracketsOpenState === "open" ?
                <Grid item width={"100%"}>
                    <Grid container display={"flex"} flexDirection={"row"} position={"relative"}>
                        <Typography variant={isMdOrUp ? "h2": "h4"} fontWeight={"bold"} sx={{fontStyle: 'italic', width: isMdOrUp ? '75%': '100%'}}>
                            {`Brackets Open For ${props.event?.name}. Brackets Will Close At: ${new Date(props.event?.close_date).toLocaleTimeString(undefined, {hour12: true, timeStyle: 'short'})}`}
                        </Typography>
                        {isMdOrUp && <img src={darkMode ? BracketIcon : BracketIconDark} alt={"Bracket Icon"} style={{maxWidth: '25%', maxHeight: '220px', transform: 'skewX(-20deg)'}}/>}
                    </Grid>
                    <Divider sx={{marginY: 2, width: '100%'}} />
                    <Button variant={"contained"} sx={{
                        backgroundColor: "#486ce7",
                        color: "#ffffff",
                        width: '100%',
                        ":hover": {
                            backgroundColor: "#c40000",
                            color: "#ffffff",
                        }
                    }}
                    onClick={() => {
                        if(props.event) {
                            window.location.href = "/bracket/?" + encodeURIComponent("event_id=" + props.event.id + "&league_id=1");
                        }
                    }}
                    >
                        <Typography variant={"h4"}>
                            Fill Out Bracket
                        </Typography>
                    </Button>
                </Grid>
                :
                <Grid item width={"100%"}>
                    <Grid container display={"flex"} flexDirection={"row"} position={"relative"}>
                        <Typography variant={isMdOrUp ? "h2": "h4"} fontWeight={"bold"} sx={{fontStyle: 'italic', width: isMdOrUp ? '75%': '100%'}}>
                            {`Brackets Closed For ${props.event?.name}`}
                        </Typography>
                        {isMdOrUp && <img src={darkMode ? BracketIcon : BracketIconDark} alt={"Bracket Icon"} style={{maxWidth: '25%', maxHeight: '220px', transform: 'skewX(-20deg)'}}/>}
                    </Grid>
                </Grid>
            }
        </Grid>
            : null
    )
}