import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Logo from "../assets/images/logo.png"
import {Config} from "../Config";
import CssBaseline from "@mui/material/CssBaseline";
import {useTheme} from "@mui/material";
import {Helmet} from "react-helmet";
import Box from "@mui/material/Box";

const config: Config = new Config()
export default function ErrorScreen() {
    const theme = useTheme();
    const darkMode = theme.palette.mode === 'dark';

    async function pingBackend() {
        const intervalId = setInterval(async () => {
            try {
                const response = await fetch(config.backendURL);

                if (response.ok) {
                    clearInterval(intervalId); // stop the interval
                    window.location.href = '/'; // redirect to home page
                }
            } catch (error) {
                console.error('Failed to ping backend:', error);
            }
        }, 5000); // 5000 milliseconds = 5 seconds
    }

    pingBackend();

    return (
        <Grid container
              direction="column"
              alignItems="center"
              justifyContent="center"
              width={'100vw'}
              height={'100vh'}
              sx={{
                  background: darkMode ?
                      'linear-gradient(135deg, white 10%, #121212 10%, #121212 12%, white 12%, white 22%, transparent 22%);'
                      :
                      'linear-gradient(135deg, #7F9AF5 10%, white 10%, white 12%, #7F9AF5 12%, #7F9AF5 22%, transparent 22%);',
                  backgroundAttachment: 'fixed',
              }}
        >
            <CssBaseline/>
            <Helmet>
                <title>DRBB | Error</title>
            </Helmet>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                maxWidth: 'min(400px, 80vw)',
                position: 'relative'
            }}>
                <img src={Logo} alt='DRBB logo' style={{width: '250px'}}/>
                <Typography variant={"h6"} margin={'20px'} align={"center"}>
                    Failed to connect to DRBB servers. This may be due to scheduled maintenance or poor internet quality. If you are not redirected, please try again later.
                </Typography>
            </Box>
        </Grid>
    );
}
