import Layout from "../utilities/Layout";
import {useEffect, useState} from "react";
import {Event} from "../data/models/bracket_api/Event";
import {LeaderboardEntry, LeaderboardProps} from "../components/leaderboard/Leaderboard";
import {get_event} from "../apis/Event";
import Grid from "@mui/material/Grid";
import {Accordion, AccordionDetails, AccordionSummary, Divider, Stack, Theme, useMediaQuery} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LeaderboardCarousel from "../components/leaderboard/LeaderboardCarousel";
import Loader from "../components/common/Loader";
import Box from "@mui/material/Box";
import {Helmet} from "react-helmet";

type RaceDetailProps = {
    event: Event,
    leaderboard: LeaderboardProps[],
    pastWinners: any
}
export default function RaceDetail() {
    let searchParams = window.location.href.substring(window.location.href.indexOf("?") + 1);
    let decoded = decodeURIComponent(searchParams);
    const queryParams = new URLSearchParams(decoded);
    const event_id = queryParams.get("event_id");
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));
    const [loading, setLoading] = useState<boolean>(true);
    const [props, setProps] = useState<RaceDetailProps | undefined>(undefined);

    useEffect(() => {
        if (loading) {
            get_event(event_id as string).then((response) => {
                if (response.succeeded) {
                    let leaderboards: LeaderboardProps[] = [];
                    Object.entries(response.data.leaderboard).forEach(([key, value]) => {
                        let leaderboard_rows: LeaderboardEntry[] = [];
                        for (const element of value as any) {
                            leaderboard_rows.push({
                                rank: element.rank,
                                nickname: element.nickname,
                                points: element.points,
                                possible_points: element.possible_points,
                            })
                        }
                        leaderboards.push({
                            display_name: key,
                            entries: leaderboard_rows,
                            viewable: false,
                            event_id: event_id as string,
                            league_id: "1",
                        });
                    });
                    setProps({
                        event: response.data.event,
                        leaderboard: leaderboards,
                        pastWinners: response.data.past_winners,
                    });
                    setLoading(false)
                }
            });
        }
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [loading, isMdOrUp]);
    return (
        <Layout>
            <Helmet>
                <title>{"DRBB | Race Detail"}</title>
            </Helmet>
            {
                loading ?
                    <Box sx={{width: '100vw', height: 'calc(100vh - 384px)', position: 'relative', display: 'flex'}}>
                        <Loader/>
                    </Box>
                    :
                    <Grid container sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 1,
                        maxWidth: "100vw",
                        overflowX: 'hidden',
                        position: 'relative'
                    }}>
                        <Stack direction={"column"} spacing={1} sx={{
                            backgroundColor: "background.paper",
                            padding: 1,
                            borderRadius: '10px',
                            maxWidth: '80vw',
                        }}>
                            <Typography variant={isMdOrUp ? "h3" : "h5"} fontWeight={"bold"}
                                        sx={{fontStyle: "italic"}}>
                                {props?.event.name}
                            </Typography>
                            <Divider/>
                            {props !== undefined && props.event.image_link !== null &&
                                <img src={props.event.image_link} alt={props?.event.name}
                                     style={{width: 'min(1500px, 80vw)', margin: '16px auto'}}/>
                            }
                            <Typography variant={"h6"}>
                                {props?.event.description}
                            </Typography>
                        </Stack>
                        <Accordion expanded={true} sx={{
                            width: '80vw',
                            backgroundColor: 'background.paper',
                            borderRadius: '10px',
                            height: 'fit-content',
                            maxHeight: '100%',
                            overflowY: 'auto',
                        }}>
                            <AccordionSummary
                                aria-controls="brackets-content"
                                id="brackets-header"
                            >
                                <Typography
                                    variant={"h6"}>Past {props?.event.name.substring(5)} Champions</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {props?.pastWinners && Object.entries(props.pastWinners).map(([key, value]) => {
                                    return (
                                        <Accordion key={key} sx={{
                                            backgroundColor: 'background.paper',
                                            borderRadius: '10px',
                                        }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls={key + "-content"}
                                                id={key + "-header"}
                                            >
                                                <Typography variant={"h6"}>{key}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {
                                                    Object.entries(value as any).map(([key2, value2]) => {
                                                        return (
                                                            <Typography key={key2} variant={"body1"}>
                                                                {`${key2}: ${value2 as string} ${value2 === 1 ? " victory" : " victories"}`}
                                                            </Typography>
                                                        )
                                                    })
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                })}
                            </AccordionDetails>
                        </Accordion>
                        {props?.leaderboard &&
                            <LeaderboardCarousel league_name={"Drag Race Bracket Bonanza"}
                                                 leaderboards={props.leaderboard} league_id={String(1)}
                                                 event_id={event_id as string} sx={{justifyContent: 'center', m: 2}}/>
                        }
                    </Grid>
            }
        </Layout>
    )
}