import Layout from "../utilities/Layout";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {Divider, Stack, Theme, useMediaQuery} from "@mui/material";
import MailIcon from '@mui/icons-material/Mail';
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import StorageIcon from '@mui/icons-material/Storage';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import {Config} from "../Config";
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import {Helmet} from "react-helmet";
import {useEffect, useState} from "react";
import {Event} from "../data/models/bracket_api/Event";
import {AdminLoad, BracketStatResponse, GetAdminBracketStats, GetAdminUserStats, UserStatResponse} from "../apis/Admin";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {LineChart, PieChart} from "@mui/x-charts";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import * as React from "react";
import Box from "@mui/material/Box";
import FirstRoundPicks from "../components/admin/admin_dashboard/FirstRoundPicks";


const config: Config = new Config();

export default function Admin() {
    if (!JSON.parse(localStorage.getItem('user') as string).is_staff) {
        window.location.href = '/';
    }
    const [events, setEvents] = useState<Event[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [userStats, setUserStats] = useState<UserStatResponse | undefined>();
    const [loadingNewReport, setLoadingNewReport] = useState<boolean>(false);
    const [event_id, setEventId] = useState<string>("");
    const [bracketStats, setBracketStats] = useState<BracketStatResponse | undefined>();
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));

    useEffect(() => {

        if (loading) {
            AdminLoad().then((response) => {
                if (response.events) {
                    let e: Event[] = response.events;
                    e.sort((a: Event, b: Event) => {
                        return a.open_date > b.open_date ? -1 : 1;
                    });
                    setEvents(e);
                }
            });
            GetAdminUserStats().then((response) => {
                if (response.succeeded) {
                    response.data?.user_list.forEach((user) => {
                        user.last_login = new Date(user.last_login);
                        user.sign_up_date = new Date(user.sign_up_date);
                    });
                    setUserStats(response.data);
                }
            });
        }
        setLoading(false);
        let previousEventId = event_id;

        const interval = setInterval(() => {
            GetAdminUserStats().then((response) => {
                if (response.succeeded) {
                    response.data?.user_list.forEach((user) => {
                        user.last_login = new Date(user.last_login);
                        user.sign_up_date = new Date(user.sign_up_date);
                    });
                    setUserStats(response.data);
                }
            });
            if(event_id !== "" && event_id !== previousEventId) {
                GetAdminBracketStats(event_id).then((response) => {
                    if (response.succeeded) {
                        setBracketStats(response.data);
                    }
                });
                previousEventId = event_id;
            }
        }, 10000);

        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            clearInterval(interval);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let sortedUserList = [];
    let xAxisDataCount: number[] = [];
    let yAxisDataCount: number[] = [];

    if (userStats) {
        // First, sort the user list by sign up date
        sortedUserList = [...userStats.user_list].sort((a, b) => a.sign_up_date.getTime() - b.sign_up_date.getTime());
        // Get rid of initial spike from data migration
        sortedUserList = sortedUserList.filter(user => user.sign_up_date.getTime() > 1708975175661)
        // Then, generate the xAxis and yAxis data
        xAxisDataCount = sortedUserList.map(user => user.sign_up_date.getTime());
        yAxisDataCount = sortedUserList.map((user, index) => {
            return 1790 + index
        });
    }

    function handleSearchChange(event: React.SyntheticEvent, value: string) {
        let eventFound: Event[] = events.filter((event2) => {
            if (event2.name === value) {
                return event2;
            }
        })
        if (eventFound.length !== 0) {
            setLoadingNewReport(true);
            setEventId(eventFound[0].id as string);
            GetAdminBracketStats(eventFound[0].id as string).then((response) => {
                if (response.succeeded) {
                    setBracketStats(response.data);
                }
                setLoadingNewReport(false);
            });
        }
    }


    return (
        <Layout>
            <Helmet>
                <title>DRBB | Admin Panel</title>
            </Helmet>
            <Grid container sx={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                width: '100%',
                height: 'available',
                alignContent: 'flex-start'
            }}>
                <Typography variant={'h3'} align={'left'} fontWeight={'bold'} sx={{
                    textTransform: 'uppercase',
                    backgroundColor: 'background.paper',
                    borderRadius: '10px',
                    width: 'fit-content',
                    marginX: 2,
                    padding: 1
                }}>
                    Admin Panel
                </Typography>
                <Divider sx={{
                    width: '100%',
                    height: '2px',
                    marginY: '1rem',
                }}/>
                <Grid container sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: isMdOrUp ? 'nowrap' : 'wrap',
                    justifyContent: 'center',
                    width: '100%',
                    alignContent: 'flex-start',
                    position: 'relative',
                }}>
                    <Grid container sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'fit-content',
                        justifyContent: 'center',
                        p: 2,
                        m: 2,
                        width: 'fit-content',
                        backgroundColor: 'background.paper',
                        borderRadius: '10px',
                        minWidth: '250px',
                    }}>

                        <Typography variant={'h6'} align={'left'}>
                            Useful Links
                        </Typography>
                        <Link href={"https://us21.admin.mailchimp.com/audience/"} rel={"noopener"} target={"_blank"}>
                            <Button variant={"contained"} sx={{
                                width: '100%',
                                marginY: 1,
                            }}>
                                <MailIcon/>
                                <Typography variant={'body1'} fontSize={24} align={'center'} paddingX={1}>
                                    Mailchimp
                                </Typography>
                            </Button>
                        </Link>

                        <Link
                            href={"https://us-west-2.console.aws.amazon.com/console/home?nc2=h_ct&region=us-west-2&src=header-signin#"}
                            rel={"noopener"} target={"_blank"}>
                            <Button variant={"contained"} sx={{
                                width: '100%',
                                marginY: 1,
                            }}>
                                <StorageIcon/>
                                <Typography variant={'body1'} fontSize={24} align={'center'} paddingX={1}>
                                    Amazon Web Services
                                </Typography>
                            </Button>
                        </Link>

                        <Link
                            href={"https://analytics.google.com/analytics/web/#/p406282542/reports/reportinghub?params=_u..nav%3Dmaui"}
                            rel={"noopener"} target={"_blank"}>
                            <Button variant={"contained"} sx={{
                                width: '100%',
                                marginY: 1,
                            }}>
                                <AnalyticsIcon/>
                                <Typography variant={'body1'} fontSize={24} align={'center'} paddingX={1}>
                                    Google Analytics
                                </Typography>
                            </Button>
                        </Link>

                        <Link href={"https://accounts.shopify.com/"} rel={"noopener"} target={"_blank"}>
                            <Button variant={"contained"} sx={{
                                width: '100%',
                                marginY: 1,
                            }}>
                                <ShoppingCartIcon/>
                                <Typography variant={'body1'} fontSize={24} align={'center'} paddingX={1}>
                                    Shopify
                                </Typography>
                            </Button>
                        </Link>
                        <Typography variant={'h6'} align={'left'}>
                            Action Items
                        </Typography>
                        <Link href={"/admin/events"}>
                            <Button variant={"contained"} sx={{
                                width: '100%',
                                marginY: 1,
                            }}>
                                <EditCalendarIcon/>
                                <Typography variant={'body1'} fontSize={24} align={'center'} paddingX={1}>
                                    Create / Edit Event
                                </Typography>
                            </Button>
                        </Link>
                        <Link
                            href={config.backendURL + "/admin/"}
                            rel={"noopener"} target={"_blank"}>
                            <Button variant={"contained"} sx={{
                                width: '100%',
                                marginY: 1,
                            }}>
                                <DataThresholdingIcon/>
                                <Typography variant={'body1'} fontSize={24} align={'center'} paddingX={1}>
                                    Create / Edit Other Models
                                </Typography>
                            </Button>
                        </Link>
                    </Grid>
                    <Stack flexDirection={"row"} display={"flex"} flexWrap={"wrap"}  p={2}
                           justifyContent={"space-evenly"} sx={{overflowY: "auto", backgroundColor: 'background.paper', borderRadius: '1px'}}>
                        <Card sx={{minWidth: 250, height: 200, p: 1, m: 1}}>
                            <CardContent>
                                <Typography variant={"h6"}>
                                    User Summary
                                </Typography>
                                <Typography variant={"body1"}>
                                    <ul>
                                        <li>Total Users: {userStats?.user_count}</li>
                                        <li>Mailchimp Subscribers: {userStats?.subscription_count}</li>
                                        <li>Leagues Formed: {userStats?.leagues_formed}</li>
                                    </ul>
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{minWidth: 250, height: 200, p: 1, m: 1}}>
                            <CardContent>
                                <Typography variant={"h6"}>
                                    Number of Sign Ins
                                </Typography>
                                <Typography variant={"body1"}>
                                    <ul>
                                        <li>Last 24 hours: {userStats?.sign_ins.last_24_hours}</li>
                                        <li>Last 7 days: {userStats?.sign_ins.last_7_days}</li>
                                        <li>Last 30 days: {userStats?.sign_ins.last_30_days}</li>
                                    </ul>
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{minWidth: 250, height: 200, p: 1, m: 1}}>
                            <CardContent>
                                <Typography variant={"h6"}>
                                    Number of Sign Ups
                                </Typography>
                                <Typography variant={"body1"}>
                                    <ul>
                                        <li>Last 24 hours: {userStats?.sign_ups.last_24_hours}</li>
                                        <li>Last 7 days: {userStats?.sign_ups.last_7_days}</li>
                                        <li>Last 30 days: {userStats?.sign_ups.last_30_days}</li>
                                    </ul>
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{minWidth: 250, height: 200, p: 1, m: 1}}>
                            <CardContent>
                                <Typography variant={"h6"}>
                                    Users By Source
                                </Typography>
                                <Typography variant={"body1"}>
                                    <ul>
                                        {
                                            userStats?.users_by_source ? Object.entries(userStats?.users_by_source).map((source) => {
                                                return <li>{source[0]}: {source[1]}</li>
                                            }) : ""
                                        }
                                    </ul>
                                </Typography>
                            </CardContent>
                        </Card>
                        {userStats &&
                            <LineChart
                                width={isMdOrUp ? 1000: 600}
                                height={isMdOrUp ? 400: 400}
                                colors={["#486ce7"]}
                                series={[
                                    {

                                        data: yAxisDataCount,
                                        label: "Number of Users",
                                        showMark: false,
                                    },
                                ]}
                                xAxis={[{
                                    data: xAxisDataCount,
                                    label: "Sign Up Date",
                                    valueFormatter: (value) => {
                                        return new Date(value).toLocaleDateString();
                                    },
                                }]}
                            />
                        }
                        <Autocomplete
                            sx={{
                                minWidth: "300px",
                                width: "100%",
                                backgroundColor: 'background.paper',
                                borderRadius: '5px',
                            }}
                            freeSolo
                            disableClearable
                            onChange={handleSearchChange}
                            options={events.map((event) => (event.name))}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search Race"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}/>
                        {
                            bracketStats && !loadingNewReport && bracketStats.player_who_filled_out_bracket &&
                            <Typography variant={"h5"} m={2} textAlign={"left"} width={"100%"} fontWeight={"bold"}
                                        sx={{fontStyle: "italic"}}>
                                Players Who Completed Brackets: {bracketStats?.player_who_filled_out_bracket}
                            </Typography>
                        }
                        {
                            bracketStats && !loadingNewReport && bracketStats.bracket_count &&
                            <Typography variant={"h5"} m={2} textAlign={"left"} width={"100%"} fontWeight={"bold"}
                                        sx={{fontStyle: "italic"}}>
                                Number of Brackets Completed: {bracketStats?.bracket_count}
                            </Typography>
                        }
                        {
                            bracketStats && !loadingNewReport && bracketStats.bracket_winners && bracketStats.bracket_winners["Top Fuel"] && isMdOrUp &&
                            <Box flexGrow={1} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                <Typography variant={"h6"}  textAlign="center">Top Fuel Predicted Winners</Typography>
                                <PieChart
                                    width={875}
                                    height={450}
                                    colors={["#c40000", "#b64848", "#33a3ff", "#4863b0", "#486ce7"]}
                                    series={[{
                                        data: bracketStats.bracket_winners["Top Fuel"].map((driver) => {
                                            return {
                                                value: driver.times_picked,
                                                label: driver.driver + " - " + driver.pick_percentage
                                            }
                                        }),
                                    }]}
                                    slotProps={{
                                        legend: {
                                            itemGap: 5
                                        }
                                    }}
                                />
                            </Box>
                        }
                        {
                            bracketStats && !loadingNewReport && bracketStats.bracket_winners && bracketStats.bracket_winners["Funny Car"] && isMdOrUp &&
                            <Box flexGrow={1} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                <Typography variant={"h6"}  textAlign="center">Funny Car Predicted Winners</Typography>
                                <PieChart
                                    width={875}
                                    height={450}
                                    colors={["#c40000", "#b64848", "#33a3ff", "#4863b0", "#486ce7"]}
                                    series={[{
                                        data: bracketStats.bracket_winners["Funny Car"].map((driver) => {
                                            return {
                                                value: driver.times_picked,
                                                label: driver.driver + " - " + driver.pick_percentage
                                            }
                                        }),
                                    }]}
                                    slotProps={{
                                        legend: {
                                            itemGap: 5
                                        }
                                    }}
                                />
                            </Box>
                        }
                        {
                            bracketStats && !loadingNewReport && bracketStats.bracket_winners && bracketStats.bracket_winners["Pro Stock"] && isMdOrUp &&
                            <Box flexGrow={1} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                <Typography variant={"h6"}  textAlign="center">Pro Stock Predicted Winners</Typography>
                                <PieChart
                                    width={875}
                                    height={450}
                                    colors={["#c40000", "#b64848", "#33a3ff", "#4863b0", "#486ce7"]}
                                    series={[{
                                        data: bracketStats.bracket_winners["Pro Stock"].map((driver) => {
                                            return {
                                                value: driver.times_picked,
                                                label: driver.driver + " - " + driver.pick_percentage
                                            }
                                        }),
                                    }]}
                                    slotProps={{
                                        legend: {
                                            itemGap: 5
                                        }
                                    }}
                                />
                            </Box>
                        }
                        {
                            bracketStats && !loadingNewReport && bracketStats.bracket_winners && bracketStats.bracket_winners["Pro Stock Motorcycle"] && isMdOrUp &&
                            <Box flexGrow={1} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                <Typography variant={"h6"}  textAlign="center">Pro Stock Motorcycle Predicted Winners</Typography>
                                <PieChart
                                    width={875}
                                    height={450}
                                    colors={["#c40000", "#b64848", "#33a3ff", "#4863b0", "#486ce7"]}
                                    series={[{
                                        data: bracketStats.bracket_winners["Pro Stock Motorcycle"].map((driver) => {
                                            return {
                                                value: driver.times_picked,
                                                label: driver.driver + " - " + driver.pick_percentage
                                            }
                                        }),
                                    }]}
                                    slotProps={{
                                        legend: {
                                            itemGap: 5
                                        }
                                    }}
                                />
                            </Box>
                        }
                        {
                            bracketStats && !loadingNewReport && isMdOrUp && bracketStats.first_round_picks &&
                            <FirstRoundPicks first_round_picks={bracketStats.first_round_picks}/>
                        }
                    </Stack>
                </Grid>
            </Grid>
        </Layout>
    )
}