import Grid from "@mui/material/Grid";
import ConfigRow from "../ConfigRow";
import {
    Divider,
    MenuItem,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Paper from "@mui/material/Paper";
import {Result} from "../../../../data/models/bracket_api/Result";
import {Fragment, SyntheticEvent, useState} from "react";
import * as React from "react";
import Helmet from "../../../../assets/images/helmet.png";
import {
    getRaceExceptionFromString,
    getRaceExceptionString,
    RaceException
} from "../../../../data/models/bracket_api/RaceException";
import {Driver} from "../../../../data/models/bracket_api/Driver";
import ResultForm from "../../../admin/event_config/ResultForm";

type TwoWideConfigBlockProps = {
    result?: Result,
    updateResult?: (result: Result) => void
}

export default function ConfigBlock(props: Readonly<TwoWideConfigBlockProps>) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const [winnerId, setWinnerId] = useState<number>(props.result?.winner?.id ?? 0);
    const [exception1, setException1] = useState<RaceException>(props.result?.competitor_1_exception ?? RaceException.NONE);
    const [exception2, setException2] = useState<RaceException>(props.result?.competitor_2_exception ?? RaceException.NONE);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleImageError = (event: SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.src = Helmet;
    };

    const handleUpdateResult = (event: SyntheticEvent<HTMLFormElement, Event>) => {
        if(props.result && props.updateResult) {
            let data = new FormData(event.currentTarget);
            let competior_1_time = data.get('competitor_1_time') as unknown as number ?? null;
            let competior_2_time = data.get('competitor_2_time') as unknown as number ?? null;

            let competior_1_speed = data.get('competitor_1_speed') as unknown as number ?? null;
            let competior_2_speed = data.get('competitor_2_speed') as unknown as number ?? null;

            let competior_1_reaction_time = data.get('competitor_1_reaction_time') as unknown as number ?? null;
            let competior_2_reaction_time = data.get('competitor_2_reaction_time') as unknown as number ?? null;

            let updatedResult: Result = {
                ...props.result,
                competitor_1_time: competior_1_time,
                competitor_2_time: competior_2_time,
                competitor_1_speed: competior_1_speed,
                competitor_2_speed: competior_2_speed,
                competitor_1_reaction_time: competior_1_reaction_time,
                competitor_2_reaction_time: competior_2_reaction_time,
            }
            props.updateResult(updatedResult);
        }
    }

    return (
        <Fragment>
            <Grid container component={Paper} m={1} sx={{
                display: 'flex',
                flexDirection: "column",
                height: 'fit-content',
                width: 'fit-content',
                cursor: 'pointer'
            }}
                  onClick={() => {
                      handleClickOpen()
                  }}
            >
                <ConfigRow
                    competition_number={1}
                    result={props.result}
                    seed={props.result?.seed_1}
                    driver={props.result?.competitor_1}
                    winner={props.result?.competitor_1?.id !== undefined && props.result?.winner?.id === props.result?.competitor_1?.id}
                    time_or_condition={props.result?.competitor_1_exception !== RaceException.NONE && props.result?.competitor_1_exception ? props.result?.competitor_1_exception : props.result?.competitor_1_time as number}
                />
                <Divider/>
                <ConfigRow
                    competition_number={2}
                    result={props.result}
                    seed={props.result?.seed_2}
                    driver={props.result?.competitor_2}
                    winner={props.result?.competitor_2?.id !== undefined && props.result?.winner?.id === props.result?.competitor_2?.id}
                    time_or_condition={props.result?.competitor_2_exception !== RaceException.NONE && props.result?.competitor_2_exception ? props.result?.competitor_2_exception : props.result?.competitor_2_time as number}
                />
            </Grid>
            <ResultForm fullScreen={fullScreen} open={open} onClose={handleClose} result={props.result!}
                        onError={handleImageError} onChange={handleUpdateResult} raceException={exception1}
                        onChange1={(event) => {
                            setException1(getRaceExceptionFromString(event.target.value))
                            if (props.result && props.updateResult) {
                                props.updateResult({
                                    ...props.result,
                                    competitor_1_exception: getRaceExceptionFromString(event.target.value)
                                });
                            }
                        }} callbackfn={(key) => {
                let exception = getRaceExceptionString(RaceException[key as keyof typeof RaceException])
                return <MenuItem key={props.result?.game_code + "1" + key} value={exception}>{exception}</MenuItem>
            }} raceException1={exception2} onChange2={(event) => {
                setException2(getRaceExceptionFromString(event.target.value))
                if (props.result && props.updateResult) {
                    props.updateResult({
                        ...props.result,
                        competitor_2_exception: getRaceExceptionFromString(event.target.value)
                    });
                }
            }} callbackfn1={(key) => {
                let exception = getRaceExceptionString(RaceException[key as keyof typeof RaceException])
                return <MenuItem key={props.result?.game_code + "2" + key} value={exception}>{exception}</MenuItem>
            }} defaultValue={winnerId} onChange3={(event) => {
                setWinnerId(event.target.value as number)
                if (props.result && props.updateResult) {
                    let winner_id = event.target.value;
                    let winner: Driver | undefined;
                    let loser: Driver | undefined;
                    let winner_seed: number | undefined;
                    let loser_seed: number | undefined;
                    if (winner_id === props.result?.competitor_1?.id) {
                        winner = props.result?.competitor_1;
                        winner_seed = props.result?.seed_1;
                        loser = props.result?.competitor_2;
                        loser_seed = props.result?.seed_2;
                    } else if (winner_id === props.result?.competitor_2?.id) {
                        winner = props.result?.competitor_2;
                        winner_seed = props.result?.seed_2;
                        loser = props.result?.competitor_1;
                        loser_seed = props.result?.seed_1;
                    }
                    props.updateResult({
                        ...props.result,
                        winner: winner,
                        loser: loser,
                        winner_seed: winner_seed,
                        loser_seed: loser_seed,
                    })
                }
            }}/>
        </Fragment>
    )
}