import * as React from "react";
import {getRaceExceptionString, RaceException} from "../../../data/models/bracket_api/RaceException";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Helmet from "../../../assets/images/helmet.png";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Result} from "../../../data/models/bracket_api/Result";

export default class ResultForm extends React.Component<{
    fullScreen: boolean,
    open: boolean,
    onClose: () => void,
    result: Result,
    onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void,
    onChange: (event: React.SyntheticEvent<HTMLFormElement, Event>) => void,
    raceException: RaceException,
    onChange1: (event: any) => void,
    callbackfn: (key: any) => React.JSX.Element,
    raceException1: RaceException,
    onChange2: (event: any) => void,
    callbackfn1: (key: any) => React.JSX.Element,
    defaultValue: number,
    onChange3: (event: any) => void
}> {
    render() {
        return <Dialog
            fullScreen={this.props.fullScreen}
            maxWidth={"lg"}
            open={this.props.open}
            onClose={this.props.onClose}
            aria-labelledby="responsive-dialog-title"
            sx={{
                margin: "auto",
                minWidth: "900px"
            }}
        >
            <DialogTitle id="responsive-dialog-title" fontWeight={"bold"} textAlign={"center"}>
                {this.props.result?.bracket.race_class} Round {this.props.result?.round} Race {this.props.result?.game} Results
            </DialogTitle>
            <DialogContent>
                <Grid container direction={"column"}>
                    <Grid container direction={"row"} sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                    }}>
                        {this.props.result?.competitor_1?.thumbnail ?
                            <img
                                src={this.props.result?.competitor_1?.thumbnail}
                                onError={this.props.onError}
                                alt={"Driver headshot"}
                                style={{flex: 0.5, margin: "12px"}}
                            /> :
                            <img
                                src={Helmet}
                                alt={"Driver headshot"}
                                style={{flex: 0.5, margin: "12px"}}
                            />
                        }
                        {this.props.result?.competitor_2?.thumbnail ?
                            <img
                                src={this.props.result?.competitor_2?.thumbnail}
                                onError={this.props.onError}
                                alt={"Driver headshot"}
                                style={{flex: 0.5, margin: "12px"}}
                            /> :
                            <img
                                src={Helmet}
                                alt={"Driver headshot"}
                                style={{flex: 0.5, margin: "12px"}}
                            />
                        }
                    </Grid>
                    <Grid container direction={"row"} sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                    }}>
                        <Typography variant={"h6"} sx={{
                            flex: 0.5,
                            textAlign: "center",
                        }}>
                            {this.props.result?.competitor_1?.first_name + " " + this.props.result?.competitor_1?.last_name}
                        </Typography>
                        <Typography variant={"h6"} sx={{
                            flex: 0.5,
                            textAlign: "center",
                        }}>
                            {this.props.result?.competitor_2?.first_name + " " + this.props.result?.competitor_2?.last_name}
                        </Typography>
                    </Grid>
                    <Divider/>
                    <Box component={"form"} onChange={this.props.onChange}>
                        <Grid container direction={"row"} sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                        }}>
                            <TextField
                                type={"text"}
                                label={"Elapsed Time"}
                                variant={"filled"}
                                name={"competitor_1_time"}
                                defaultValue={this.props.result?.competitor_1_time}
                                sx={{
                                    flex: 0.5,
                                    margin: "8px"
                                }}
                            />
                            <TextField
                                type={"text"}
                                label={"Elapsed Time"}
                                variant={"filled"}
                                name={"competitor_2_time"}
                                defaultValue={this.props.result?.competitor_2_time}
                                sx={{
                                    flex: 0.5,
                                    margin: "8px"
                                }}
                            />
                        </Grid>
                        <Grid container direction={"row"} sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                        }}>
                            <TextField
                                type={"text"}
                                label={"Speed"}
                                variant={"filled"}
                                name={"competitor_1_speed"}
                                defaultValue={this.props.result?.competitor_1_speed}
                                sx={{
                                    flex: 0.5,
                                    margin: "8px"
                                }}
                            />
                            <TextField
                                type={"text"}
                                label={"Speed"}
                                variant={"filled"}
                                name={"competitor_2_speed"}
                                defaultValue={this.props.result?.competitor_2_speed}
                                sx={{
                                    flex: 0.5,
                                    margin: "8px"
                                }}
                            />
                        </Grid>
                        <Grid container direction={"row"} sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                        }}>
                            <TextField
                                type={"text"}
                                label={"Reaction Time"}
                                variant={"filled"}
                                name={"competitor_1_reaction_time"}
                                defaultValue={this.props.result?.competitor_1_reaction_time}
                                sx={{
                                    flex: 0.5,
                                    margin: "8px"
                                }}
                            />
                            <TextField
                                type={"text"}
                                label={"Reaction Time"}
                                variant={"filled"}
                                name={"competitor_2_reaction_time"}
                                defaultValue={this.props.result?.competitor_2_reaction_time}
                                sx={{
                                    flex: 0.5,
                                    margin: "8px"
                                }}
                            />
                        </Grid>
                        <Grid container direction={"row"} sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                        }}>
                            <FormControl sx={{
                                flex: 0.5,
                                margin: "8px"
                            }}>
                                <InputLabel id="competitor_1_exception">Race Exception</InputLabel>
                                <Select
                                    labelId="competitor_1_exception"
                                    defaultValue={getRaceExceptionString(this.props.raceException)}
                                    label={"Race Exception"}
                                    onChange={this.props.onChange1}
                                >
                                    {Object.keys(RaceException).map(this.props.callbackfn)}
                                </Select>
                            </FormControl>
                            <FormControl sx={{
                                flex: 0.5,
                                margin: "8px"
                            }}>
                                <InputLabel id="competitor_2_exception">Race Exception</InputLabel>
                                <Select
                                    labelId="competitor_2_exception"
                                    defaultValue={getRaceExceptionString(this.props.raceException1)}
                                    label={"Race Exception"}
                                    onChange={this.props.onChange2}
                                >
                                    {Object.keys(RaceException).map(this.props.callbackfn1)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <FormControl sx={{
                            width: "100%",
                            margin: "8px",
                        }}>
                            <InputLabel id={"winner-label" + this.props.result?.game_code}>Winner</InputLabel>
                            <Select
                                labelId={"winner-label" + this.props.result?.game_code}
                                defaultValue={this.props.defaultValue}
                                onChange={this.props.onChange3}
                                label={"Winner"}
                            >
                                <MenuItem value={0}>None</MenuItem>
                                <MenuItem
                                    value={this.props.result?.competitor_1?.id}>{this.props.result?.competitor_1?.first_name + " " + this.props.result?.competitor_1?.last_name}</MenuItem>
                                <MenuItem
                                    value={this.props.result?.competitor_2?.id}>{this.props.result?.competitor_2?.first_name + " " + this.props.result?.competitor_2?.last_name}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={this.props.onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }
}