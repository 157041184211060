import Grid from "@mui/material/Grid";
import Logo from "../../../../assets/images/logo.png";
import {useEffect, useRef, useState} from "react";
import {Event} from "../../../../data/models/bracket_api/Event";
import {Bracket} from "../../../../data/models/bracket_api/Bracket";
import {FourWideResult} from "../../../../data/models/bracket_api/FourWideResult";
import {RaceClass} from "../../../../data/models/bracket_api/RaceClass";
import Box from "@mui/material/Box";
import ConfigBracketFourWide from "./ConfigBracketFourWide";

type TwoWideConfigBracketContainerProps = {
    event: Event,
    brackets: Bracket[],
    results: FourWideResult[],
    updateResult: (result: FourWideResult) => void,
}

export default function FourWideConfigBracketContainer(props: Readonly<TwoWideConfigBracketContainerProps>) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isOverflowing, setIsOverflowing] = useState<boolean>(true);

    useEffect(() => {
        const container = containerRef.current;

        let isMouseDown = false;
        let startX: number;
        let scrollLeft: number;

        const element = containerRef.current;

        if (element) {
            setIsOverflowing(element.scrollWidth > element.clientWidth);
        }

        const handleMouseDown = (event: MouseEvent) => {
            isMouseDown = true;
            startX = event.pageX - container!.offsetLeft;
            scrollLeft = container!.scrollLeft;
        };

        const handleMouseUp = () => {
            isMouseDown = false;
        };

        const handleMouseMove = (event: MouseEvent) => {
            if (!isMouseDown) return;
            event.preventDefault();
            const x = event.pageX - container!.offsetLeft;
            const walk = (x - startX); // Adjust the scroll speed here
            container!.scrollLeft = scrollLeft - walk;
        };

        const handleResize = () => {
            const element = containerRef.current;

            if (element) {
                setIsOverflowing(element.scrollWidth > element.clientWidth);
            }
        }

        window.addEventListener('resize', handleResize);
        container!.addEventListener('mousedown', handleMouseDown);
        container!.addEventListener('mouseup', handleMouseUp);
        container!.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('resize', handleResize);
            container!.removeEventListener('mousedown', handleMouseDown);
            container!.removeEventListener('mouseup', handleMouseUp);
            container!.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    let topFuelBracket = props.brackets.find((bracket) => {
        return bracket.race_class === RaceClass.TOP_FUEL;
    })
    let topFuelResults = props.results.filter((result) => {
        return result.bracket.race_class === RaceClass.TOP_FUEL;
    });
    let funnyCarBracket = props.brackets.find((bracket) => {
        return bracket.race_class === RaceClass.FUNNY_CAR;
    })
    let funnyCarResults = props.results.filter((result) => {
        return result.bracket.race_class === RaceClass.FUNNY_CAR;
    });
    let proStockBracket = props.brackets.find((bracket) => {
        return bracket.race_class === RaceClass.PRO_STOCK;
    })
    let proStockResults = props.results.filter((result) => {
        return result.bracket.race_class === RaceClass.PRO_STOCK;
    });
    let proStockMotorcycleBracket = props.brackets.find((bracket) => {
        return bracket.race_class === RaceClass.PRO_STOCK_MOTORCYCLE;
    })
    let proStockMotorcycleResults = props.results.filter((result) => {
        return result.bracket.race_class === RaceClass.PRO_STOCK_MOTORCYCLE;
    });

    return (
        <Grid
            container
            ref={containerRef}
            sx={{
                width: 'fit-content',
                overflow: 'auto',
                cursor: isOverflowing ? 'grab' : 'default',
                userSelect: 'none',
            }}
        >

            <Grid container sx={{
                display: 'flex',
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
                alignContent: "center",
                height: 'fit-content',
                width: 'fit-content',
            }}>
                <Grid container sx={{
                    marginY: 1,
                    display: 'flex',
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    width: 'fit-content',
                }}>
                    {topFuelBracket ?
                        <ConfigBracketFourWide race_class={RaceClass.TOP_FUEL} updateResult={props.updateResult} bracket={topFuelBracket} results={topFuelResults}/> :
                        <Box sx={{visibility: "hidden"}}>
                            <ConfigBracketFourWide race_class={RaceClass.TOP_FUEL} updateResult={props.updateResult}/>
                        </Box>
                    }
                    {funnyCarBracket ?
                        <ConfigBracketFourWide race_class={RaceClass.FUNNY_CAR} updateResult={props.updateResult} bracket={funnyCarBracket} results={funnyCarResults}/> :
                        <Box sx={{visibility: "hidden"}}>
                            <ConfigBracketFourWide race_class={RaceClass.FUNNY_CAR} updateResult={props.updateResult}/>
                        </Box>
                    }
                </Grid>
                <Grid container sx={{
                    marginY: 1,
                    display: 'flex',
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    height: '1rem',
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    {
                        props.event.left_sponsor_image_link && (
                            <img src={props.event.left_sponsor_image_link} alt={"Sponsor Logo"} style={{maxHeight: '150px', maxWidth: '150px'}}/>
                        )
                    }
                    <img src={Logo} alt={"DRBB Logo"} height={'250px'} style={{margin: '50px'}}/>
                    {
                        props.event.right_sponsor_image_link && (
                            <img src={props.event.right_sponsor_image_link} alt={"Sponsor Logo"} style={{maxHeight: '150px', maxWidth: '150px'}}/>
                        )
                    }
                </Grid>
                <Grid container sx={{
                    marginY: 1,
                    display: 'flex',
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    width: 'fit-content',
                }}>
                    {proStockBracket ?
                        <ConfigBracketFourWide race_class={RaceClass.PRO_STOCK} updateResult={props.updateResult} bracket={proStockBracket} results={proStockResults}/> :
                        <Box sx={{visibility: "hidden"}}>
                            <ConfigBracketFourWide race_class={RaceClass.PRO_STOCK} updateResult={props.updateResult}/>
                        </Box>
                    }
                    {proStockMotorcycleBracket ?
                        <ConfigBracketFourWide race_class={RaceClass.PRO_STOCK_MOTORCYCLE} updateResult={props.updateResult} bracket={proStockMotorcycleBracket} results={proStockMotorcycleResults}/> :
                        <Box sx={{visibility: "hidden"}}>
                            <ConfigBracketFourWide race_class={RaceClass.PRO_STOCK_MOTORCYCLE} updateResult={props.updateResult}/>
                        </Box>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}