import {Config} from "../Config";

const config: Config = new Config();

export type AuthResponse = {
    succeeded: boolean;
    statusCode: number;
    message: string;
    data?: any;
}

export async function SignIn(email: string, password: string): Promise<AuthResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
    }
    try {
        if (email.length === 0 || password.length === 0) {
            return {succeeded: false, statusCode: 400, message: "Email or password is empty"};
        }
        // Make api endpoint call to login user with parameters
        let response: Response = await fetch(config.backendURL + config.endpoints.sign_in, {
            method: 'POST',
            headers: {
                'content-type': 'application/json;charset=UTF-8',
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        });
        let responseStatus: number = response.status;
        let data: any = await response.json();
        switch (responseStatus) {
            case 200:
                localStorage.setItem('access_token', data.access);
                localStorage.setItem('refresh_token', data.refresh);
                return {
                    succeeded: true, statusCode: 200, message: "Login successful", data: {
                        access: data.access,
                        refresh: data.refresh,
                    }
                };
            case 400:
                return {succeeded: false, statusCode: 400, message: "Login failed"};
            case 401:
                return {succeeded: false, statusCode: 401, message: data["detail"]};
            case 404:
                return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
            case 500:
                return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
            default:
                return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
        }
    } catch (e) {
        window.location.href = '/error';
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}

export async function SignUp(email: string, password: string, re_password: string, firstName: string, lastName: string, nickname: string, phone: string): Promise<AuthResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
    }
    try {
        if (email.length === 0 || password.length === 0 || re_password.length === 0 || firstName.length === 0 || lastName.length === 0 || nickname.length === 0) {
            return {succeeded: false, statusCode: 400, message: "Please enter all required* fields"};
        }
        if (phone.length !== 17) {
            phone = "";
        }
        // Make api endpoint call to sign up user with parameters
        let response: Response = await fetch(config.backendURL + config.endpoints.sign_up, {
            method: 'POST',
            headers: {
                'content-type': 'application/json;charset=UTF-8',
            },
            body: JSON.stringify({
                email: email,
                password: password,
                re_password: re_password,
                first_name: firstName,
                last_name: lastName,
                nickname: nickname,
                phone_number: phone,
            }),
        });
        let responseStatus: number = response.status;
        let data: any = await response.json();
        switch (responseStatus) {
            case 201:
                return {succeeded: true, statusCode: 201, message: "Account created successfully", data: data};
            case 400: {
                let error: string = data[Object.keys(data)[0]][0];
                error = error.charAt(0).toUpperCase() + error.slice(1);
                return {succeeded: false, statusCode: 400, message: error};
            }
            case 401:
                return {succeeded: false, statusCode: 401, message: data["detail"]};
            case 404:
                return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
            case 500:
                return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
            default:
                return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
        }
    } catch (e) {
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}

export async function ForgotPassword(email: string): Promise<AuthResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
    }
    try {
        if (email.length === 0) {
            return {succeeded: false, statusCode: 400, message: "Please enter email address"};
        }
        let response: Response = await fetch(config.backendURL + config.endpoints.reset_password, {
            method: 'POST',
            headers: {
                'content-type': 'application/json;charset=UTF-8',
            },
            body: JSON.stringify({
                email: email,
            }),
        });
        let responseStatus: number = response.status;
        switch (responseStatus) {
            case 200:
                return {succeeded: true, statusCode: 201, message: "Email has been sent successfully"};
            case 400:
                return {succeeded: false, statusCode: 400, message: "No account found for this email address"};
            case 404:
                return {succeeded: false, statusCode: 404, message: "Servers are down, please come back later"};
            case 500:
                return {succeeded: false, statusCode: 500, message: "Unable to send email, please try again later"};
            default:
                return {succeeded: false, statusCode: 500, message: "Unable to send email, please try again later"};
        }
    } catch (e) {
        window.location.href = '/error';
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}

export async function refresh_token(refresh_token: string): Promise<AuthResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
    }
    try {
        let response: Response = await fetch(config.backendURL + config.endpoints.refresh_token, {
            method: 'POST',
            headers: {
                'content-type': 'application/json;charset=UTF-8',
            },
            body: JSON.stringify({
                refresh: refresh_token,
            }),
        });
        let responseStatus: number = response.status;
        let data: any = await response.json();
        if (responseStatus < 400) {
            localStorage.setItem('access_token', data.access);
            localStorage.setItem('refresh_token', data.refresh);
            return {succeeded: true, statusCode: 200, message: "Refresh token successful", data: data};
        }
        else if (responseStatus === 401 || responseStatus === 400) {
            let pathname = encodeURIComponent(window.location.pathname);
            window.location.href = '/signin/redirect?path=' + pathname;
            return {succeeded: false, statusCode: 401, message: "Access / Refresh token is invalid"};
        }
        else {
            return {succeeded: false, statusCode: 401, message: "Refresh token is invalid"};
        }
    } catch (e) {
        window.location.href = '/error';
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}

export async function verify_token(refresh: string, access: string): Promise<AuthResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
    }
    try {
        if (access === null) {
            return {succeeded: false, statusCode: 401, message: "Access token is empty"};
        } else {
            let response: Response = await fetch(config.backendURL + config.endpoints.verify_token, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                },
                body: JSON.stringify({
                    token: access,
                }),
            });
            let responseStatus: number = response.status;
            if (responseStatus < 400) {
                return {succeeded: true, statusCode: 200, message: "Token verified successfully"};
            } else {
                let refresh_validation: AuthResponse = await refresh_token(refresh);
                if (refresh_validation.succeeded) {
                    return {succeeded: true, statusCode: 200, message: "Token verified successfully"};
                } else {
                    return {succeeded: false, statusCode: 401, message: "Access token is invalid"};
                }
            }
        }
    } catch
        (e) {
        window.location.href = '/error';
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}

export async function about_me(refresh: string, access: string): Promise<AuthResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
    }
    try {
        if (access === null) {
            return {succeeded: false, statusCode: 401, message: "Access token is empty"};
        } else {
            let response: Response = await fetch(config.backendURL + config.endpoints.about_me, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                    'Authorization': 'JWT ' + access,
                },
            });
            let responseStatus: number = response.status;
            let data: any = await response.json();
            if (responseStatus < 400) {
                return {succeeded: true, statusCode: 200, message: "User retrieved successfully", data: data};
            } else {
                let refresh_validation: AuthResponse = await refresh_token(refresh);
                if (refresh_validation.succeeded) {
                    return await about_me(refresh, data.access);
                } else {
                    return {succeeded: false, statusCode: 401, message: "Access token is invalid"};
                }
            }
        }
    } catch (e) {
        window.location.href = '/error';
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}

export async function set_acquisition_source(refresh: string, access: string, source: string): Promise<AuthResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
    }
    try {
        if (access === null) {
            return {succeeded: false, statusCode: 401, message: "Access token is empty"};
        } else {
            let response: Response = await fetch(config.backendURL + config.endpoints.set_acquisition_source, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                    'Authorization': 'JWT ' + access,
                },
                body: JSON.stringify({
                    source: source,
                }),
            });
            let responseStatus: number = response.status;
            if (responseStatus < 400) {
                return {succeeded: true, statusCode: 200, message: "Acquisition source set successfully"};
            } else {
                let refresh_validation: AuthResponse = await refresh_token(refresh);
                if (refresh_validation.succeeded) {
                    return await set_acquisition_source(refresh, access, source);
                } else {
                    return {succeeded: false, statusCode: 401, message: "Access token is invalid"};
                }
            }
        }
    } catch (e) {
        window.location.href = '/error';
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}


export async function reset_password_confirm(refresh: string, access: string, token: string, password: string): Promise<AuthResponse> {

    if (window.location.pathname === '/error') {
        return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
    }
    try {
        if (access === null) {
            return {succeeded: false, statusCode: 401, message: "Access token is empty"};
        } else {

            let response: Response = await fetch(config.backendURL + config.endpoints.reset_password_confirm, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                    'Authorization': 'JWT ' + access,
                },
                body: JSON.stringify({
                    token: token,
                    password: password,
                }),
            });
            let responseStatus: number = response.status;
            if (responseStatus < 400) {
                return {succeeded: true, statusCode: 200, message: "New password set successfully, redirecting you to the sign in page"};
            } else if(responseStatus === 401) {
                let refresh_validation: AuthResponse = await refresh_token(refresh);
                if (refresh_validation.succeeded) {
                    return await reset_password_confirm(refresh, access, token, password);
                } else {
                    return {succeeded: false, statusCode: 401, message: "Access token is invalid"};
                }
            } else {
                return {succeeded: false, statusCode: responseStatus, message: await response.json()}
            }
        }
    } catch (e) {
        window.location.href = '/error';
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}

export async function change_password(access: string, refresh: string, new_password: string, old_password: string): Promise<AuthResponse> {
        if (window.location.pathname === '/error') {
            return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
        }
        try {
            let response: Response = await fetch(config.backendURL + config.endpoints.change_password, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json;charset=UTF-8',
                        'Authorization': 'JWT ' + access,
                    },
                    body: JSON.stringify({
                    old_password: old_password,
                    new_password: new_password,
                }),
            });
            let data = await response.json();
            let responseStatus: number = response.status;
            if (responseStatus < 400) {
                return {succeeded: true, statusCode: 200, message: "Successfully changed password"};
            }
            else if(responseStatus === 401){
                let refresh_validation: AuthResponse = await refresh_token(refresh);
                if (refresh_validation.succeeded) {
                    return await change_password(access, refresh, new_password, old_password);
                } else {
                    return {succeeded: false, statusCode: 401, message: "Access token is invalid"};
                }
            }
            else{
                return {succeeded: false, statusCode: 400, message: "Failed to change password", data: data};
            }
        }
     catch (e) {
        if(e instanceof TypeError && e.message === "Failed to fetch"){
            window.location.href = '/error';
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}

export async function change_nickname(access: string, refresh: string, nickname: string): Promise<AuthResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
    }
    try {
        if (access === null) {
            return {succeeded: false, statusCode: 401, message: "Access token is empty"};
        } else {
            let response: Response = await fetch(config.backendURL + config.endpoints.change_nickname, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                    'Authorization': 'JWT ' + access,
                },
                body: JSON.stringify({
                    nickname: nickname,
                }),
            });
            let data = await response.json();
            let responseStatus: number = response.status;
            if (responseStatus < 400) {
                return {succeeded: true, statusCode: 200, message: "Successfully changed nickname to " + nickname};
            }
            else if(responseStatus === 401){
                let refresh_validation: AuthResponse = await refresh_token(refresh);
                if (refresh_validation.succeeded) {
                    return await change_nickname(access, refresh, nickname);
                } else {
                    return {succeeded: false, statusCode: 401, message: "Access token is invalid"};
                }
            }
            else{
                return {succeeded: false, statusCode: 400, message: data};
            }
        }
    } catch (e) {
        if(e instanceof TypeError && e.message === "Failed to fetch"){
            window.location.href = '/error';
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}


export async function change_email(access: string, refresh: string, email: string): Promise<AuthResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
    }
    try {
        if (access === null) {
            return {succeeded: false, statusCode: 401, message: "Access token is empty"};
        } else {
            let response: Response = await fetch(config.backendURL + config.endpoints.change_email, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                    'Authorization': 'JWT ' + access,
                },
                body: JSON.stringify({
                    email: email,
                }),
            });
            let data = await response.json();
            let responseStatus: number = response.status;
            if (responseStatus < 400) {
                return {succeeded: true, statusCode: 200, message: "Successfully changed email"};
            }
            else if(responseStatus === 401){
                let refresh_validation: AuthResponse = await refresh_token(refresh);
                if (refresh_validation.succeeded) {
                    return await change_email(access, refresh, email);
                } else {
                    return {succeeded: false, statusCode: 401, message: "Access token is invalid"};
                }
            }
            else{
                return {succeeded: false, statusCode: 400, message: data};
            }
        }
    } catch (e) {
        if(e instanceof TypeError && e.message === "Failed to fetch"){
            window.location.href = '/error';
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}


export async function change_phone(access: string, refresh: string, phone_number: string): Promise<AuthResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
    }
    try {
        if (access === null) {
            return {succeeded: false, statusCode: 401, message: "Access token is empty"};
        } else {
            let parsed_phone_number: string = phone_number.replace(/\D/g, '');
            parsed_phone_number = "+" + parsed_phone_number;
            let response: Response = await fetch(config.backendURL + config.endpoints.change_phone, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                    'Authorization': 'JWT ' + access,
                },
                body: JSON.stringify({
                    phone: parsed_phone_number,
                }),
            });
            let data = await response.json();
            let responseStatus: number = response.status;
            if (responseStatus < 400) {
                return {succeeded: true, statusCode: 200, message: "Successfully changed phone number"};
            }
            else if(responseStatus === 401){
                let refresh_validation: AuthResponse = await refresh_token(refresh);
                if (refresh_validation.succeeded) {
                    return await change_phone(access, refresh, phone_number);
                } else {
                    return {succeeded: false, statusCode: 401, message: "Access token is invalid"};
                }
            }
            else{
                return {succeeded: false, statusCode: 400, message: data};
            }
        }
    } catch (e) {
        if(e instanceof TypeError && e.message === "Failed to fetch"){
            window.location.href = '/error';
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}


export async function delete_user(access: string, refresh: string):Promise<AuthResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false, statusCode: 400, message: "Servers are down, please come back later"};
    }
    try {
        if (access === null) {
            return {succeeded: false, statusCode: 401, message: "Access token is empty"};
        } else {
            let response: Response = await fetch(config.backendURL + config.endpoints.delete_user, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                    'Authorization': 'JWT ' + access,
                },
            });
            let data = await response.json();
            let responseStatus: number = response.status;
            if (responseStatus < 400) {
                return {succeeded: true, statusCode: 200, message: "Successfully deleted user"};
            }
            else if(responseStatus === 401){
                let refresh_validation: AuthResponse = await refresh_token(refresh);
                if (refresh_validation.succeeded) {
                    return await delete_user(access, refresh);
                } else {
                    return {succeeded: false, statusCode: 401, message: "Access token is invalid"};
                }
            }
            else{
                return {succeeded: false, statusCode: 400, message: data};
            }
        }
    } catch (e) {
        if(e instanceof TypeError && e.message === "Failed to fetch"){
            window.location.href = '/error';
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}
