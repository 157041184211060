import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Logo from "../assets/images/logo.png";
import CssBaseline from "@mui/material/CssBaseline";
import {useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import {Helmet} from "react-helmet";

export default function Privacy(){
    const theme = useTheme();
    const darkMode = theme.palette.mode === "dark";
    return (
        <Grid container direction={"column"} sx={{
            maxWidth: "100%",
            overflowY: "auto",
            flexWrap: "nowrap",
            padding: "1rem",
            alignItems: "center",
            background: darkMode ?
                'linear-gradient(135deg, white 10%, #121212 10%, #121212 12%, white 12%, white 22%, transparent 22%);'
                :
                'linear-gradient(135deg, #7F9AF5 10%, white 10%, white 12%, #7F9AF5 12%, #7F9AF5 22%, transparent 22%);',
            backgroundAttachment: 'fixed',
        }}>
            <Helmet>
                <title>DRBB | Privacy Policy</title>
            </Helmet>
            <CssBaseline/>
            <img src={Logo} alt={"DRBB Logo"} style={{maxWidth: "300px", marginBottom: '1rem'}}/>
            <Box sx={{
                backgroundColor: 'background.paper',
                borderRadius: '10px',
                p: 2,
            }}>
                <Typography variant={"h5"} fontWeight={"bold"} textAlign={"center"}>
                    Privacy Policy
                </Typography>
                <br/>
                <Typography variant={"h6"} textAlign={"left"}>
                    Your privacy is important to us. This privacy statement explains the personal data Drag Race Bracket
                    Bonanza processes,
                    how Drag Race Bracket Bonanza processes it, and for what purposes. This statement applies to the
                    interactions Drag Race Bracket Bonanza has with you.
                </Typography>
                <br/>
                <Typography variant={"h6"} textAlign={"left"}>
                    This notice highlights the following parts:
                </Typography>
                <br/>
                <Typography variant={"h6"} textAlign={"left"}>
                    <span style={{fontWeight: 'bold'}}>Transparency:</span> We are transparent about how your personal information is collected and used.
                </Typography>
                <br/>
                <Typography variant={"h6"} textAlign={"left"}>
                    <span style={{fontWeight: 'bold'}}>Control:</span> We put you in control of your personal information, including accessing, correcting, and
                    deleting your personal information.
                </Typography>
                <br/>
                <Typography variant={"h6"} textAlign={"left"}>
                    <span style={{fontWeight: 'bold'}}>Benefits to You:</span> We use your personal information to benefit you and to make your experiences
                    better.
                </Typography>
                <br/>
                <Typography variant={"h6"} textAlign={"left"}>
                    The data we collect depends on the context of your interactions with Drag Race Bracket Bonanza and
                    the choices you make,
                    including your privacy settings and the features you use. We also obtain data about you from third
                    parties.
                </Typography>
                <br/>
                <Typography variant={"h6"} textAlign={"left"}>
                    You have choices when it comes to the technology you use and the data you share. When we ask you to
                    provide personal data, you can decline.
                    Many of our products require some personal data to provide you with a service such as SMS
                    notifications. If you choose not to provide data
                    -required to provide you with a product or feature, you cannot use that product or feature. Where
                    providing the data is optional,
                    and you choose not to share personal data, features like personalization that use such data will not
                    work for you.
                </Typography>
                <br/>
                <Typography variant={"h6"} textAlign={"left"}>
                    Drag Race Bracket Bonanza uses the data we collect to provide you with an interactive and seamless
                    experience. <br/>
                    We use data to:
                    <br/>
                    <ul>
                        <li>Improve and develop our products.</li>
                        <li>Personalize our products and make recommendations.</li>
                        <li>Advertise and market to you, which includes sending promotional communications, targeting
                            advertising, and presenting you with relevant offers.
                        </li>
                    </ul>
                </Typography>
                <Typography variant={"h6"} textAlign={"left"}>
                    We also use the data to operate our business, which includes analyzing our performance, promoting
                    our business, creating and sharing statistical analysis and doing research.
                </Typography>
                <br/>
                <Typography variant={"h6"} textAlign={"left"}>
                    We also use the data to operate our business, which includes analyzing our performance, promoting
                    our business, creating and sharing statistical analysis and doing research.
                </Typography>
            </Box>
        </Grid>
    )
}