import Layout from "../utilities/Layout";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import * as React from "react";
import {Alert, InputAdornment, List, ListItem, Snackbar, Theme, useMediaQuery} from "@mui/material";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import {change_password} from "../apis/Auth";
import Typography from "@mui/material/Typography";
import GA4EventSend from "../utilities/GA4EventSend";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CssBaseline from "@mui/material/CssBaseline";
import {Helmet} from "react-helmet";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export default function ChangePassword() {
    if (localStorage.getItem("user") === null) {
        let pathname = encodeURIComponent("/change-password");
        window.location.href = "/signin/redirect?path=" + pathname;
    }

    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isClicked, setIsClicked] = useState(false);
    const [passwordIsValid, setPasswordIsValid] = useState<boolean>(false);
    const [repasswordIsValid, setRepasswordIsValid] = useState<boolean>(false);
    const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"success" | "error" | "info" | "warning" | undefined>("error");
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showRePassword, setShowRePassword] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp]);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    function handleChange(event: React.ChangeEvent<HTMLFormElement>) {
        const data = new FormData(event.currentTarget);
        const password: string = data.get('password') as string ?? "";
        const valid_password: boolean = password.length >= 8 && !RegExp(/^\d+$/).exec(password);
        setPasswordIsValid(valid_password);
        const repassword: string = data.get('re-password') as string ?? "";
        const repasswordIsValid: boolean = repassword.length >= 8 && !RegExp(/^\d+$/).exec(repassword) && repassword === password;
        setRepasswordIsValid(repasswordIsValid);
        setButtonEnabled(
            data.get('old-password') !== "" &&
            data.get('password') !== "" &&
            data.get('re-password') !== "" &&
            valid_password && repasswordIsValid
        );
    }

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setIsClicked(true);
        const data = new FormData(event.currentTarget);
        const old_password = data.get('old-password');
        const password = data.get('password');
        const repassword = data.get('re-password');
        if (password === repassword) {
            let response = await change_password(localStorage.getItem("access_token") ?? "", localStorage.getItem("refresh_token") ?? "", password as string, old_password as string);
            if (response.succeeded) {
                setSeverity("success");
                setMessage("Password changed successfully");
                setOpen(true);
            } else {
                let message = response.data["error"] ?? response.message;
                setSeverity("error");
                setMessage(message);
                setOpen(true);
            }
            GA4EventSend("change_password", "user", "change_password", response.statusCode, false);
        } else {
            setMessage("Passwords do not match");
            setOpen(true);
        }
        setIsClicked(false);
    }

    return (
        <Layout>
            <CssBaseline/>
            <Helmet>
                <title>DRBB | Change Password</title>
            </Helmet>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={open} onClose={handleClose}
                      autoHideDuration={6000}>
                <Alert elevation={6} severity={severity} sx={{width: '100%'}} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
            <Box sx={{
                height: isMdOrUp ? '75vh' : 'fit-content',
                width: '100%',
                display: 'flex',
                m: 2,
                borderRadius: '10px'
            }}>
                <Paper elevation={3} sx={{
                    padding: 2,
                    margin: 'auto',
                    width: 'fit-content',
                    height: 'fit-content'
                }}>
                    <Grid container direction={"column"} component={"form"} onSubmit={handleSubmit}
                          onChange={handleChange} sx={{
                        maxWidth: "300px",
                        margin: "auto",
                        p: 1,
                    }}>
                        <Typography variant={"h4"} sx={{textAlign: "center", mb: 2}} fontWeight={"bold"}>
                            Change Password
                        </Typography>
                        <TextField
                            required
                            fullWidth
                            name="old-password"
                            label="Old Password"
                            type={showOldPassword ? "text" : "password"}
                            id="old-password"
                            onChange={(event) => {}}
                            sx={{
                                mb: 2
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowOldPassword(!showOldPassword)}
                                        >
                                            {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            color={passwordIsValid ? "success" : "error"}
                            required
                            fullWidth
                            name="password"
                            label="New Password"
                            type={showPassword ? "text" : "password"}
                            id="password"
                            autoComplete="new-password"
                            onChange={(event) => {
                            }}
                            sx={{
                                mb: 2
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            color={repasswordIsValid ? "success" : "error"}
                            required
                            fullWidth
                            name="re-password"
                            label="New Password Again"
                            type={showRePassword ? "text" : "password"}
                            id="re-password"
                            onChange={(event) => {
                            }}
                            sx={{
                                mb: 2
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowRePassword(!showRePassword)}
                                        >
                                            {showRePassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <List sx={{listStyleType: 'disc'}}>
                            <ListItem sx={{display: 'list-item'}}>
                                Password must be at least 8 characters long.
                            </ListItem>
                            <ListItem sx={{display: 'list-item'}}>
                                Password can not be all numbers.
                            </ListItem>
                            <ListItem sx={{display: 'list-item'}}>
                                Password can not be too similar to your name, nickname, or email.
                            </ListItem>
                        </List>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                            disabled={isClicked || !buttonEnabled}
                        >
                            Change Password
                        </Button>
                    </Grid>
                </Paper>
            </Box>
        </Layout>
    );
}