import {Config} from "../Config";
import {Event} from "../data/models/bracket_api/Event";

const config: Config = new Config();
export type UserStatResponse = {
    user_count: number;
    subscription_count: number;
    sign_ins: {
        "last_30_days": number;
        "last_7_days": number;
        "last_24_hours": number;
    },
    sign_ups: {
        "last_30_days": number;
        "last_7_days": number;
        "last_24_hours": number;
    },
    "users_by_source": {
        "Twitter": number;
        "Facebook": number;
        "Instagram": number;
        "Email": number;
        "Other": number;
        "NHRA TV": number;
    }
    "user_list": [
        {
            "id": number;
            "acquisition_source": string;
            "last_login": Date;
            "sign_up_date": Date;
        }
    ],
    "leagues_formed": number;
}

export type AdminUserResponse = {
    succeeded: boolean;
    message: string;
    data?: UserStatResponse;
}

export type BracketStatResponse = {
    player_who_filled_out_bracket?: number,
    bracket_count?: number;
    bracket_winners?: {
        "Top Fuel": [
            {
                "driver": string;
                "seed": number;
                "pick_percentage": string,
                "times_picked": number,
            }
        ];
        "Funny Car": [
            {
                "driver": string;
                "seed": number;
                "pick_percentage": string,
                "times_picked": number,
            }
        ];
        "Pro Stock": [
            {
                "driver": string;
                "seed": number;
                "pick_percentage": string,
                "times_picked": number,
            }
        ];
        "Pro Stock Motorcycle": [
            {
                "driver": string;
                "seed": number;
                "pick_percentage": string,
                "times_picked": number,
            }
        ];
    },
    first_round_picks?: {
        "Top Fuel": [
            {
                "Competitor 1": string,
                "Seed 1": number,
                "Competitor 2": string,
                "Seed 2": number,
                "Competitor 1 Pick Percentage": string,
                "Competitor 2 Pick Percentage": string,
            }
        ];
        "Funny Car": [
            {
                "Competitor 1": string,
                "Seed 1": number,
                "Competitor 2": string,
                "Seed 2": number,
                "Competitor 1 Pick Percentage": string,
                "Competitor 2 Pick Percentage": string,
            }
        ];
        "Pro Stock": [
            {
                "Competitor 1": string,
                "Seed 1": number,
                "Competitor 2": string,
                "Seed 2": number,
                "Competitor 1 Pick Percentage": string,
                "Competitor 2 Pick Percentage": string,
            }
        ];
        "Pro Stock Motorcycle": [
            {
                "Competitor 1": string,
                "Seed 1": number,
                "Competitor 2": string,
                "Seed 2": number,
                "Competitor 1 Pick Percentage": string,
                "Competitor 2 Pick Percentage": string,
            }
        ];
    }
}

export type AdminBracketResponse = {
    succeeded: boolean;
    message: string;
    data?: BracketStatResponse;
}

export type AdminLoadResponse = {
    events?: Event[];
}

export async function GetAdminUserStats(): Promise<AdminUserResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false,  message: "Servers are down, please come back later"};
    }
    try {
        let response: Response = await fetch(config.backendURL + config.endpoints.user_statistics, {
            method: 'GET',
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                'Authorization': 'JWT ' + localStorage.getItem('access_token') as string
            },
        });
        let responseStatus: number = response.status;
        let data: UserStatResponse = await response.json();
        if (responseStatus < 400) {
            return {succeeded: true, message: "Refresh token successful", data: data};
        }
        else if (responseStatus === 401 || responseStatus === 400) {
            let pathname = encodeURIComponent(window.location.pathname);
            window.location.href = '/signin/redirect?path=' + pathname;
            return {succeeded: false, message: "Access / Refresh token is invalid", data: undefined};
        }
        else {
            return {succeeded: false,message: "Refresh token is invalid"};
        }
    } catch (e) {
        window.location.href = '/error';
        return {succeeded: false, message: "Something went wrong on our end"};
    }
}

export async function GetAdminBracketStats(event_id: string): Promise<AdminBracketResponse> {
    if (window.location.pathname === '/error') {
        return {succeeded: false,  message: "Servers are down, please come back later"};
    }
    try {
        let response: Response = await fetch(config.backendURL + config.endpoints.bracket_statistics + event_id + '/', {
            method: 'GET',
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                'Authorization': 'JWT ' + localStorage.getItem('access_token') as string
            },
        });
        let responseStatus: number = response.status;
        let data: BracketStatResponse = await response.json();
        if (responseStatus < 400) {
            return {succeeded: true, message: "Successfully fetched bracket stats", data: data};
        }
        else if (responseStatus === 401 || responseStatus === 400) {
            let pathname = encodeURIComponent(window.location.pathname);
            window.location.href = '/signin/redirect?path=' + pathname;
            return {succeeded: false, message: "Access / Refresh token is invalid", data: {}};
        }
        else {
            return {succeeded: false,  message: "Servers are down, please come back later"};
        }
    } catch (e) {
        window.location.href = '/error';
        return {succeeded: false,  message: "Servers are down, please come back later"};
    }
}


export async function AdminLoad(): Promise<AdminLoadResponse> {
    if (window.location.pathname === '/error') {
        return {};
    }
    try {
        let response: Response = await fetch(config.backendURL + config.endpoints.admin_load, {
            method: 'GET',
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                'Authorization': 'JWT ' + localStorage.getItem('access_token') as string
            },
        });
        let responseStatus: number = response.status;
        let data: Event[] = await response.json();
        if (responseStatus < 400) {
            return {events: data};
        }
        else if (responseStatus === 401 || responseStatus === 400) {
            let pathname = encodeURIComponent(window.location.pathname);
            window.location.href = '/signin/redirect?path=' + pathname;
            return {};
        }
        else {
            return {};
        }
    } catch (e) {
        window.location.href = '/error';
        return {};
    }
}