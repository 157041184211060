import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Theme, useMediaQuery,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {Qualifier} from "../../data/models/bracket_api/Qualifier";
import {RaceClass} from "../../data/models/bracket_api/RaceClass";
import {useEffect, useState} from "react";



export type QualifierTableProps = {
    race_class: RaceClass,
    entries: Qualifier[],
}
export default function QualifierTable(props: Readonly<QualifierTableProps>){

    return(
        <TableContainer component={Paper} sx={{
            maxHeight: 'max(calc(100vh - 384px), 70vh)',
            maxWidth: '95vw',
            margin: 'auto',
            overflowX: 'hidden',
        }}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={4}>
                            <Typography variant={"h4"} fontWeight={"bold"} align={"center"}>
                                {props.race_class}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography variant={"body1"} fontWeight={"bold"}>
                                Seed
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant={"body1"} fontWeight={"bold"}>
                                Driver
                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography variant={"body1"} fontWeight={"bold"}>
                                ET
                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography variant={"body1"} fontWeight={"bold"}>
                                Speed
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.entries.map((row) => (
                        <TableRow
                            key={row.seed}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                        >
                            <TableCell component="th" scope="row">
                                <Typography variant={"body1"}>
                                    {row.seed}
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant={"body1"} fontWeight={"600"} textOverflow={""}>
                                    {row.driver ? `${row.driver.first_name} ${row.driver.last_name}` : ""}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant={"body1"}>
                                    {row.elapsed_time ? row.elapsed_time : ""}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant={"body1"}>
                                    {row.speed ? row.speed : ""}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell component="th" scope="row">
                        </TableCell>
                        <TableCell align="left">
                        </TableCell>
                        <TableCell align="right">
                        </TableCell>
                        <TableCell align="right">
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}