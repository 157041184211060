import Grid from "@mui/material/Grid";
import Layout from "../utilities/Layout";
import {NewsPost} from "../data/models/sponsor_api/NewsPost";
import {Result} from "../data/models/bracket_api/Result";
import {FourWideResult} from "../data/models/bracket_api/FourWideResult";
import {useEffect, useState} from "react";
import {home_load} from "../apis/ScreenLoad";
import NewsFeed from "../components/home/news/NewsFeed";
import {Theme, useMediaQuery} from "@mui/material";
import UpcomingEventsCarousel from "../components/home/UpcomingEventsCarousel";
import {Event} from "../data/models/bracket_api/Event";
import {Bracket} from "../data/models/bracket_api/Bracket";
import Joyride, {STATUS, EVENTS, ACTIONS} from 'react-joyride';
import {Helmet} from "react-helmet";
import Loader from "../components/common/Loader";
import ResultsCardContainer from "../components/home/results/ResultsCardContainer";
import {Qualifier} from "../data/models/bracket_api/Qualifier";
import {RaceClass} from "../data/models/bracket_api/RaceClass";
import {LeaderboardEntry, LeaderboardProps} from "../components/leaderboard/Leaderboard";
import LeaderboardCarousel from "../components/leaderboard/LeaderboardCarousel";
import BracketsOpenContainer from "../components/home/BracketsOpenContainer";
import AdSpot from "../components/common/AdSpot";

type HomeProps = {
    news: NewsPost[],
    active_qualifiers?: Qualifier[],
    active_results?: Result[] | FourWideResult[],
    active_event?: Event,
    active_brackets?: Bracket[],
    events: Event[],
    leaderboards?: LeaderboardProps[],
}

export default function Home() {

    const steps = [
        {
            target: '#Home-button',
            content: 'The page you are currently on is the home page. Here you can see live race results, news, and upcoming events.',
        },
        {
            target: '#Leagues-button',
            content: 'Clicking this button will take you to the leagues screen. There you can see all the leagues you are in, join a league, and create your own league.',
        },
        {
            target: '#Races-button',
            content: 'Clicking this button will take you to the races screen, where you can view the past winners for each national event.',
        },
        {
            target: '#Stats-button',
            content: 'Clicking this button will take you to the drivers stats screen, where you can find tons of interesting facts and stats about your favorite drivers.',
        },
        {
            target: '#Account-button',
            content: 'Lastly, clicking this button will take you to your account screen, where you can update your account information, change your password, and delete your account.',
        },
        // Add as many steps as you need...
    ];

    const [run, setRun] = useState(localStorage.getItem("home") === "true");
    const [stepIndex, setStepIndex] = useState(0);

    const handleJoyrideCallback = (data: any) => {
        const { action, index, status, type } = data;

        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            // Update state to advance the tour
            setStepIndex(stepIndex + (action === ACTIONS.PREV ? -1 : 1));
        }
        else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            // Need to set our running state to false, so we can restart if we click start again.
            setRun(false);
        }

        console.groupCollapsed(type);
        console.groupEnd();
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [props, setProps] = useState<HomeProps>({
        news: [],
        events: [],
    });
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));

    useEffect(() => {
        if (loading) {
            home_load().then((response) => {
                if (response.succeeded) {
                    console.log(response.data)
                    if(response.data.active_brackets !== undefined && response.data.active_brackets !== null && response.data.active_results !== undefined) {
                        for (let i = 0; i < response.data.active_brackets.length; i++) {
                            let race_class = response.data.active_brackets[i].race_class;
                            switch (race_class) {
                                case 1:
                                    race_class = RaceClass.TOP_FUEL;
                                    break;
                                case 2:
                                    race_class = RaceClass.FUNNY_CAR;
                                    break;
                                case 3:
                                    race_class = RaceClass.PRO_STOCK;
                                    break;
                                case 4:
                                    race_class = RaceClass.PRO_STOCK_MOTORCYCLE;
                                    break;
                                default:
                                    race_class = RaceClass.TOP_FUEL;
                            }
                            response.data.active_brackets[i].race_class = race_class;
                            if (response.data.active_results !== undefined && response.data.active_results !== null) {
                                response.data.active_results.forEach((result: any) => {
                                    if (result.bracket.id === response.data.active_brackets[i].id) {
                                        result.race_class = race_class;
                                    }
                                });
                            }
                            if (response.data.active_qualifiers !== undefined && response.data.active_qualifiers !== null) {
                                response.data.active_qualifiers.forEach((qualifier: any) => {
                                    if (qualifier.bracket.id === response.data.active_brackets[i].id) {
                                        qualifier.race_class = race_class;
                                    }
                                });
                            }
                        }
                    }
                    let leaderboards: LeaderboardProps[] = [];
                    if (response.data.active_event && response.data.leaderboard) {
                        Object.entries(response.data.leaderboard).forEach(([key, value]) => {
                            let leaderboard_rows: LeaderboardEntry[] = [];
                            for (const element of value as any) {
                                leaderboard_rows.push({
                                    rank: element.rank,
                                    nickname: element.nickname,
                                    points: element.points,
                                    possible_points: element.possible_points,
                                })
                            }
                            leaderboards.push({
                                display_name: key,
                                entries: leaderboard_rows,
                                viewable: false,
                                event_id: response.data.active_event.id,
                                league_id: "1",
                            });
                        });
                    }
                    setProps({...response.data, leaderboards: leaderboards});
                    setLoading(false);
                } else {
                    setLoading(false);
                }

            });
            localStorage.removeItem("home");
        }
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [loading, props, isMdOrUp]);

    return (
        <div>
            <Joyride
                steps={steps}
                run={run}
                stepIndex={stepIndex}
                callback={handleJoyrideCallback}
                continuous={true}
                scrollToFirstStep={true}
                styles={{
                    options: {
                        arrowColor: "#c40000",
                        primaryColor: "#486CE7"
                    },
                    tooltipFooter: {
                        marginTop: 0,
                    },
                    tooltipContent: {
                        textAlign: "left",
                    },
                }}
            />
            <Helmet>
                <title>DRBB | Home</title>
            </Helmet>
            <Layout>
                {loading ?
                    <Grid container sx={{
                        height: 'calc(100vh - 384px)',
                    }}>
                        <Loader/>
                    </Grid>
                    :
                    <Grid container sx={{
                        direction: "column",
                        height: 'fit-content',
                        justifyContent: 'center',
                    }}>
                        <BracketsOpenContainer event={props.active_event}/>
                        <AdSpot id={"home-1"} sx={{
                            width: isMdOrUp ? '80vw' : '90vw',
                            margin: '16px auto',
                        }}/>
                        <UpcomingEventsCarousel events={props.events}/>
                        {props.leaderboards !== null && props.leaderboards !== undefined && props.leaderboards.length > 0 && props.active_event !== undefined  && props.active_event.id !== undefined &&
                            <LeaderboardCarousel league_name={'Drag Race Bracket Bonanza'} leaderboards={props.leaderboards} league_id={"1"} event_id={props.active_event.id} sx={{
                                justifyContent: "center",
                                width: isMdOrUp ? '80vw' : '100vw'
                            }}/>
                        }
                        <AdSpot id={"home-2"} sx={{
                            width: isMdOrUp ? '80vw' : '90vw',
                            margin: '16px auto',
                        }}/>
                        <Grid container sx={{
                            display: 'flex',
                            flexDirection: isMdOrUp ? 'row' : 'column-reverse',
                            justifyContent: 'center',
                            height: 'fit-content',
                            maxWidth: isMdOrUp ? '80vw': '100vw',
                        }}>
                            <Grid container flex={0.5}>
                                {loading || props.news === null ? null : <NewsFeed news={props.news}/>}
                            </Grid>
                            {
                                props.active_results !== undefined &&
                                props.active_results !== null &&
                                props.active_results.length > 0 &&
                                props.active_brackets !== undefined  &&
                                props.active_event !== undefined &&
                                props.active_qualifiers !== undefined ?
                                <Grid container flex={.5}>
                                    <ResultsCardContainer
                                        results={props.active_results}
                                        brackets={props.active_brackets}
                                        event={props.active_event}
                                        qualifiers={props.active_qualifiers}
                                    />
                                </Grid>
                                    : null
                            }
                        </Grid>
                        <AdSpot id={"home-3"} sx={{
                            width: isMdOrUp ? '80vw' : '90vw',
                            margin: '16px auto',
                        }}/>
                    </Grid>
                }
            </Layout>
        </div>
    );
}