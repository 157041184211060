import {Bracket} from "../../../../data/models/bracket_api/Bracket";
import {FourWideResult} from "../../../../data/models/bracket_api/FourWideResult";
import {RaceClass} from "../../../../data/models/bracket_api/RaceClass";
import {useTheme} from "@mui/material";
import {Driver} from "../../../../data/models/bracket_api/Driver";
import TopFuelDark from "../../../../assets/images/bracket_crests/top_fuel_dark.png";
import TopFuel from "../../../../assets/images/bracket_crests/top_fuel.png";
import ProStockDark from "../../../../assets/images/bracket_crests/pro_stock_dark.png";
import ProStock from "../../../../assets/images/bracket_crests/pro_stock.png";
import FunnyCarDark from "../../../../assets/images/bracket_crests/funny_car_dark.png";
import FunnyCar from "../../../../assets/images/bracket_crests/funny_car.png";
import ProStockMotorcycleDark from "../../../../assets/images/bracket_crests/pro_stock_motorcycle_dark.png";
import ProStockMotorcycle from "../../../../assets/images/bracket_crests/pro_stock_motorcycle.png";
import Grid from "@mui/material/Grid";
import FourWideConfigBlock from "./FourWideConfigBlock";
import BracketConnector from "../../BracketConnector";
import Typography from "@mui/material/Typography";
import FourWideConfigBlockReverse from "./FourWideConfigBlockReverse";
import BracketConnectorReverse from "../../BracketConnectorReverse";

export type TwoWideConfigBracketProps = {
    race_class: RaceClass,
    bracket?: Bracket,
    results?: FourWideResult[],
    updateResult: (results: FourWideResult) => void,
}
export default function ConfigBracketFourWide(props: Readonly<TwoWideConfigBracketProps>) {
    const theme = useTheme();
    let winner: Driver | undefined = props.results?.find(result => {
        return result.round === 3 && result.first
    })?.first;

    const determineCrestImage = () => {
        switch (props.race_class) {
            case RaceClass.TOP_FUEL:
                return theme.palette.mode === 'dark' ? TopFuelDark : TopFuel;
            case RaceClass.PRO_STOCK:
                return theme.palette.mode === 'dark' ? ProStockDark : ProStock;
            case RaceClass.FUNNY_CAR:
                return theme.palette.mode === 'dark' ? FunnyCarDark : FunnyCar;
            case RaceClass.PRO_STOCK_MOTORCYCLE:
                return theme.palette.mode === 'dark' ? ProStockMotorcycleDark : ProStockMotorcycle;
            default:
                return TopFuel;
        }
    }

    const crestImage = determineCrestImage();

    return (
        props.race_class === RaceClass.TOP_FUEL || props.race_class === RaceClass.PRO_STOCK ?
            <Grid container sx={{
                display: 'flex',
                flexDirection: "row",
                position: "relative",
                minWidth: 'fit-content',
                flexWrap: "nowrap",
            }}>
                <Grid container sx={{
                    flex: .3,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <FourWideConfigBlock
                        result={props.results?.find((result) => {return result.round === 1 && result.game === 1})}
                        updateResult={props.updateResult}
                    />
                    <FourWideConfigBlock
                        result={props.results?.find((result) => {return result.round === 1 && result.game === 2})}
                        updateResult={props.updateResult}
                    />
                    <FourWideConfigBlock
                        result={props.results?.find((result) => {return result.round === 1 && result.game === 3})}
                        updateResult={props.updateResult}
                    />
                    <FourWideConfigBlock
                        result={props.results?.find((result) => {return result.round === 1 && result.game === 4})}
                        updateResult={props.updateResult}
                    />
                </Grid>
                <Grid container sx={{
                    flex: .05,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <BracketConnector height={"25.5%"}/>
                    <BracketConnector height={"25.5%"}/>
                </Grid>
                <Grid container sx={{
                    flex: .3,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <FourWideConfigBlock
                        result={props.results?.find((result) => {return result.round === 2 && result.game === 1})}
                        updateResult={props.updateResult}
                    />
                    <img src={crestImage} alt={"Crest"} width={'270px'}/>
                    <FourWideConfigBlock
                        result={props.results?.find((result) => {return result.round === 2 && result.game === 2})}
                        updateResult={props.updateResult}
                    />
                </Grid>
                <Grid container sx={{
                    flex: .05,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <BracketConnector height={"51%"}/>
                </Grid>
                <Grid container sx={{
                    flex: .3,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: '100%',
                    position: "relative",
                }}>
                    {winner && (
                        <Typography variant="h6" component="div" textAlign={"center"} position={"absolute"} top={"20%"} width={"100%"}>
                            Winner: {winner.first_name + " " + winner.last_name}
                        </Typography>
                    )}
                    {props.bracket?.tiebreaker !== undefined && props.bracket.tiebreaker > 0 && (
                        <Typography variant="h6" component="div" textAlign={"center"} position={"absolute"} top={"30%"} width={"100%"}>
                            Fastest Speed: {props.bracket.tiebreaker} MPH
                        </Typography>
                    )}
                    <FourWideConfigBlock
                        result={props.results?.find((result) => {return result.round === 3 && result.game === 1})}
                        updateResult={props.updateResult}
                    />
                </Grid>
            </Grid>
            :
            <Grid container sx={{
                display: 'flex',
                flexDirection: "row",
                position: "relative",
                minWidth: 'fit-content',
                flexWrap: "nowrap",
            }}>
                <Grid container sx={{
                    flex: .3,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "center",
                    position: "relative",
                }}>
                    {winner && (
                        <Typography variant="h6" component="div" textAlign={"center"} position={"absolute"} top={"20%"} width={"100%"}>
                            Winner: {winner.first_name + " " + winner.last_name}
                        </Typography>
                    )}
                    {props.bracket && props.bracket.tiebreaker && props.bracket.tiebreaker > 0 && (
                        <Typography variant="h6" component="div" textAlign={"center"} position={"absolute"} top={"30%"} width={"100%"}>
                            Fastest Speed: {props.bracket.tiebreaker} MPH
                        </Typography>
                    )}
                    <FourWideConfigBlockReverse
                        result={props.results?.find((result) => {return result.round === 3 && result.game === 1})}
                        updateResult={props.updateResult}
                    />
                </Grid>
                <Grid container sx={{
                    flex: .1,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                }}>
                    <BracketConnectorReverse height={"51%"}/>
                </Grid>
                <Grid container sx={{
                    flex: .3,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center"
                }}>
                    <FourWideConfigBlockReverse
                        result={props.results?.find((result) => {return result.round === 2 && result.game === 1})}
                        updateResult={props.updateResult}
                    />
                    <img src={crestImage} alt={"Crest"} width={'270px'}/>
                    <FourWideConfigBlockReverse
                        result={props.results?.find((result) => {return result.round === 2 && result.game === 2})}
                        updateResult={props.updateResult}
                    />
                </Grid>
                <Grid container sx={{
                    flex: .05,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <BracketConnectorReverse height={"25.5%"}/>
                    <BracketConnectorReverse height={"25.5%"}/>
                </Grid>
                <Grid container sx={{
                    flex: .3,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <FourWideConfigBlockReverse
                        result={props.results?.find((result) => {return result.round === 1 && result.game === 1})}
                        updateResult={props.updateResult}
                    />
                    <FourWideConfigBlockReverse
                        result={props.results?.find((result) => {return result.round === 1 && result.game === 2})}
                        updateResult={props.updateResult}
                    />
                    <FourWideConfigBlockReverse
                        result={props.results?.find((result) => {return result.round === 1 && result.game === 3})}
                        updateResult={props.updateResult}
                    />
                    <FourWideConfigBlockReverse
                        result={props.results?.find((result) => {return result.round === 1 && result.game === 4})}
                        updateResult={props.updateResult}
                    />
                </Grid>
            </Grid>
    )
}