import {Event} from "../../../data/models/bracket_api/Event";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Card from "@mui/material/Card";
import {useEffect, useState} from "react";
import {EventResponse, get_events} from "../../../apis/Event";
import {Theme, useMediaQuery} from "@mui/material";

type EventRowProps = {
    event: Event
    deleteFunction: (event: Event) => void
}

export default function EventRow(props: Readonly<EventRowProps>) {
    function editEvent() {
        window.location.href = '/admin/events/' + props.event.id;
    }

    function deleteEvent() {
        props.deleteFunction(props.event)
    }

    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));

    useEffect(() => {

        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp]);

    return (
        <Card sx={{
            display: 'flex',
            flexDirection: "row",
            width: '100%',
            minWidth: 'fit-content',
            alignItems: "center",
            flexWrap: "nowrap",
            borderRadius: '0 !important',
            padding: '12px',
            marginY: '12px',
        }}>
            <Grid container sx={{
                flex: 1,
                display: 'flex',
                flexDirection: "column",
            }}>
                <Typography variant={"h4"} fontWeight={"bold"}>
                    {props.event.name}
                </Typography>
                { props.event.series &&
                <Typography variant={"body1"}>
                    {props.event.series.name} - {new Date(props.event.open_date).getFullYear()}
                </Typography>
                }
                <Typography variant={"subtitle1"}>
                    {props.event.location}
                </Typography>
            </Grid>
            {isMdOrUp &&
            <Typography variant={"h6"} sx={{
                marginX: '12px',
            }}>
                {new Date(props.event.open_date).toDateString() + " - " + new Date(props.event.close_date).toDateString()}
            </Typography>}
            <EditIcon onClick={editEvent} sx={{
                marginX: '12px',
                height: '2rem',
                width: '2rem',
                cursor: 'pointer',
            }}/>
            <DeleteIcon onClick={deleteEvent}  sx={{
                marginX: '12px',
                height: '2rem',
                width: '2rem',
                cursor: 'pointer',
            }}/>
        </Card>
    );
}