import {UUID} from "crypto";
import Layout from "../utilities/Layout";
import Grid from "@mui/material/Grid";
import {RaceClass, raceClassFromInt, raceClassToInt} from "../data/models/bracket_api/RaceClass";
import * as React from "react";
import {useEffect, useState} from "react";
import EventDetailForm from "../components/admin/event_config/EventDetailsForm";
import QualifierForm from "../components/admin/event_config/QualifiersForm";
import {Driver} from "../data/models/bracket_api/Driver";
import RaceClassFromInt from "../utilities/RaceClassTools";
import {Bracket} from "../data/models/bracket_api/Bracket";
import {Qualifier} from "../data/models/bracket_api/Qualifier";
import {Event} from "../data/models/bracket_api/Event";
import {FourWideResult} from "../data/models/bracket_api/FourWideResult";
import {Result} from "../data/models/bracket_api/Result";
import {Series} from "../data/models/bracket_api/Series";
import {uuidv4} from "../utilities/UUIDGeneration";
import {event_config_load, EventConfigLoadResponse, event_config_post} from "../apis/ScreenLoad";
import Box from "@mui/material/Box";
import ConfigBracketContainer from "../components/bracket/config/two_wide/ConfigBracketContainer";
import {
    generateResultsFromQualifiers,
    generateResultsFromQualifiersFourWide
} from "../utilities/bracket_tools/ResultGeneration";
import {DriverResponse, get_drivers} from "../apis/Driver";
import FourWideConfigBracketContainer from "../components/bracket/config/four_wide/FourWideConfigBracketContainer";
import EventDetailFormSkeleton from "../components/admin/event_config/EventDetailFormSkeleton";
import {Helmet} from "react-helmet";

type RawDriver = {
    first_name: string,
    last_name: string,
    id: number,
    divisions: number[],
    thumbnail: string,
}

type EventConfigProps = {
    drivers: Driver[],
    event: Event,
    brackets: Bracket[],
    qualifiers: Qualifier[],
    results: Result[] | FourWideResult[],
    series: Series[],
}

const blankEvent: Event = {
    name: "",
    description: "",
    location: "",
    start_date: new Date(),
    open_date: new Date(),
    close_date: new Date(),
    end_date: new Date(),
    picks_locked: true,
    is_four_wide: false,
}

export default function EventConfig() {
    let id: UUID = window.location.href.split('/').pop() as string as UUID;
    const [loading, setLoading] = useState<boolean>(true);
    const [props, setProps] = useState<EventConfigProps>({
        drivers: [],
        event: blankEvent,
        brackets: [],
        qualifiers: [],
        results: [],
        series: [],
    });

    useEffect(() => {
        if (loading) {
            let result: RawDriver[] = [];
            try {
               result = JSON.parse(localStorage.getItem('drivers') ?? "[]");
            }
            catch (e) {
                get_drivers().then((response: DriverResponse) => {
                    localStorage.setItem('drivers', JSON.stringify(response.data));
                });
            }
            if (result.length === 0) {
                get_drivers().then((response: DriverResponse) => {
                    localStorage.setItem('drivers', JSON.stringify(response.data));
                });
            }
            let drivers: Driver[] = [];
            result.forEach((driver: RawDriver) => {
                drivers.push({
                    id: driver.id,
                    first_name: driver.first_name,
                    last_name: driver.last_name,
                    divisions: RaceClassFromInt(driver.divisions),
                    thumbnail: driver.thumbnail,
                });
            });
            event_config_load(id).then((eventConfig: EventConfigLoadResponse) => {
                for(const element of eventConfig.data.brackets) {
                    element.race_class = raceClassFromInt(element.race_class as number);
                }
                for(const element of eventConfig.data.qualifiers) {
                    element.race_class = raceClassFromInt(element.race_class as number);
                    if(element.driver) {
                        for (const element2 in element.driver.divisions) {
                            element.driver.divisions[element2] = raceClassFromInt(element.driver.divisions[element2] as number);
                        }
                    }
                }
                let raceClasses = [];
                for(const element of eventConfig.data.brackets) {
                    raceClasses.push(element.race_class);
                }
                let qualifiers: { [key: string]: Qualifier[] } = {
                    "Top Fuel": [],
                    "Funny Car": [],
                    "Pro Stock": [],
                    "Pro Stock Motorcycle": [],
                }
                for(const raceClass of raceClasses) {
                    let currentBracket: Bracket = eventConfig.data.brackets.find((bracket: Bracket) => {
                        return bracket.race_class === raceClass;
                    })
                    if(currentBracket.number_of_competitors) {
                        for (let i = 0; i < currentBracket.number_of_competitors; i++) {
                            let q: Qualifier = eventConfig.data.qualifiers.find((qualifier: Qualifier) => {
                                return qualifier.seed === i + 1 && qualifier.race_class === raceClass;
                            })
                            if(q){
                                qualifiers[raceClass].push(q);
                            }
                            else{
                                qualifiers[raceClass].push({
                                    seed: i + 1,
                                    race_class: raceClass,
                                    bracket: currentBracket,
                                    event: eventConfig.data.event,
                                })
                            }
                        }
                    }
                }
                for(const element of eventConfig.data.results) {
                    element.bracket.race_class = raceClassFromInt(element.bracket.race_class as number);
                    if(element.competitor_1) {
                        for (const element2 in element.competitor_1.divisions) {
                            element.competitor_1.divisions[element2] = raceClassFromInt(element.competitor_1.divisions[element2] as number);
                        }
                    }
                    if(element.competitor_2) {
                        for (const element2 in element.competitor_2.divisions) {
                            element.competitor_2.divisions[element2] = raceClassFromInt(element.competitor_2.divisions[element2] as number);
                        }
                    }
                    if(eventConfig.data.event.is_four_wide){
                        if(element.competitor_3) {
                            for (const element2 in element.competitor_3.divisions) {
                                element.competitor_3.divisions[element2] = raceClassFromInt(element.competitor_3.divisions[element2] as number);
                            }
                        }
                        if(element.competitor_4) {
                            for (const element2 in element.competitor_4.divisions) {
                                element.competitor_4.divisions[element2] = raceClassFromInt(element.competitor_4.divisions[element2] as number);
                            }
                        }
                        if(element.first) {
                            for (const element2 in element.first.divisions) {
                                element.first.divisions[element2] = raceClassFromInt(element.first.divisions[element2] as number);
                            }
                        }
                        if(element.second) {
                            for (const element2 in element.second.divisions) {
                                element.second.divisions[element2] = raceClassFromInt(element.second.divisions[element2] as number);
                            }
                        }
                        if(element.third) {
                            for (const element2 in element.third.divisions) {
                                element.third.divisions[element2] = raceClassFromInt(element.third.divisions[element2] as number);
                            }
                        }
                        if(element.fourth) {
                            for (const element2 in element.fourth.divisions) {
                                element.fourth.divisions[element2] = raceClassFromInt(element.fourth.divisions[element2] as number);
                            }
                        }
                    }
                    else{
                        if(element.winner) {
                            for (const element2 in element.winner.divisions) {
                                element.winner.divisions[element2] = raceClassFromInt(element.winner.divisions[element2] as number);
                            }
                        }
                        if(element.loser) {
                            for (const element2 in element.loser.divisions) {
                                element.loser.divisions[element2] = raceClassFromInt(element.loser.divisions[element2] as number);
                            }
                        }
                    }
                }
                eventConfig.data.qualifiers = qualifiers["Top Fuel"].concat(qualifiers["Funny Car"]).concat(qualifiers["Pro Stock"]).concat(qualifiers["Pro Stock Motorcycle"]);
                let blankEvent2 = blankEvent;
                blankEvent2.id = id;
                setProps({
                    drivers: drivers,
                    event: eventConfig.data.event ?? blankEvent2,
                    brackets: eventConfig.data.brackets ?? [],
                    qualifiers: eventConfig.data.qualifiers ?? [],
                    results: eventConfig.data.results ?? [],
                    series: eventConfig.data.series ?? [],
                });
                setLoading(false);
            });
        }
    }, [loading]);


    function hasDuplicateDrivers(qualifiers: Qualifier[]): boolean {
        for (const qualifier of qualifiers) {
            if (qualifier.driver) {
                if(qualifiers.filter((q) => {return q.driver?.id === qualifier.driver?.id}).length > 1){
                    return true;
                }
            }
        }
        return false;
    }


    async function submitEventConfig(): Promise<EventConfigLoadResponse> {
        if (hasDuplicateDrivers(props.qualifiers)) {
            return {succeeded: false, data: undefined, message: "Duplicate Drivers Found in Qualifiers"};
        }
        else {
            let results_to_save: any[] = JSON.parse(JSON.stringify(props.results));
            results_to_save.forEach((result: any) => {
                result.bracket_id = result.bracket.id;
                delete result.bracket
                result.competitor_1_id = result.competitor_1?.id;
                delete result.competitor_1
                result.competitor_2_id = result.competitor_2?.id;
                delete result.competitor_2
                result.winner_id = result.winner?.id;
                delete result.winner
                result.loser_id = result.loser?.id;
                delete result.loser
                result.competitor_1_speed = parseFloat(result.competitor_1_speed);
                result.competitor_2_speed = parseFloat(result.competitor_2_speed);
                result.competitor_1_time = parseFloat(result.competitor_1_time);
                result.competitor_2_time = parseFloat(result.competitor_2_time);
                result.competitor_1_reaction_time = parseFloat(result.competitor_1_reaction_time);
                result.competitor_2_reaction_time = parseFloat(result.competitor_2_reaction_time);
            });
            results_to_save.forEach(obj => {
                Object.entries(obj).forEach(([key, value]) => {
                    if (value === null) {
                        delete obj[key];
                    }
                });
            });
            let four_wide_results_to_save: any[] = JSON.parse(JSON.stringify(props.results));
            four_wide_results_to_save.forEach((result: any) => {
                result.bracket_id = result.bracket.id;
                delete result.bracket
                result.competitor_1_id = result.competitor_1?.id;
                delete result.competitor_1
                result.competitor_2_id = result.competitor_2?.id;
                delete result.competitor_2
                result.competitor_3_id = result.competitor_3?.id;
                delete result.competitor_3
                result.competitor_4_id = result.competitor_4?.id;
                delete result.competitor_4
                result.first_id = result.first?.id;
                delete result.first
                result.second_id = result.second?.id;
                delete result.second
                result.third_id = result.third?.id;
                delete result.third
                result.fourth_id = result.fourth?.id;
                delete result.fourth
                result.competitor_1_speed = parseFloat(result.competitor_1_speed);
                result.competitor_2_speed = parseFloat(result.competitor_2_speed);
                result.competitor_3_speed = parseFloat(result.competitor_3_speed);
                result.competitor_4_speed = parseFloat(result.competitor_4_speed);
                result.competitor_1_time = parseFloat(result.competitor_1_time);
                result.competitor_2_time = parseFloat(result.competitor_2_time);
                result.competitor_3_time = parseFloat(result.competitor_3_time);
                result.competitor_4_time = parseFloat(result.competitor_4_time);
                result.competitor_1_reaction_time = parseFloat(result.competitor_1_reaction_time);
                result.competitor_2_reaction_time = parseFloat(result.competitor_2_reaction_time);
                result.competitor_3_reaction_time = parseFloat(result.competitor_3_reaction_time);
                result.competitor_4_reaction_time = parseFloat(result.competitor_4_reaction_time);
            });
            let qualifiers_to_save: any[] = JSON.parse(JSON.stringify(props.qualifiers));
            qualifiers_to_save.forEach((qualifier: any) => {
                qualifier.event_id = qualifier.event.id;
                delete qualifier.event
                qualifier.bracket_id = qualifier.bracket.id;
                delete qualifier.bracket
                qualifier.driver_id = qualifier.driver?.id;
                delete qualifier.driver
                qualifier.race_class = raceClassToInt(qualifier.race_class);
            });
            return await event_config_post({
                event: props.event,
                brackets: props.brackets,
                qualifiers: qualifiers_to_save,
                results: results_to_save,
                four_wide_results: four_wide_results_to_save,
            });
        }

    }


    function handleEventChange(updatedEvent: Event): void {
        if(updatedEvent.is_four_wide !== props.event.is_four_wide){
            setProps(prevProps => ({
                ...prevProps,
                results: [],
            }));
        }
        let brackets: Bracket[] = props.brackets;
        let qualifiers: Qualifier[] = props.qualifiers;
        brackets.forEach(bracket => {bracket.event = updatedEvent});
        qualifiers.forEach(qualifier => {qualifier.event = updatedEvent});
        updatedEvent.id = id;
        setProps(prevProps => ({
            ...prevProps,
            event: updatedEvent,
            brackets: brackets,
            qualifiers: qualifiers,
        }));
    }

    function toggleBrackets(classes: string[]): void {
        let brackets: Bracket[] = props.brackets;
        const raceClasses = [RaceClass.TOP_FUEL, RaceClass.FUNNY_CAR, RaceClass.PRO_STOCK, RaceClass.PRO_STOCK_MOTORCYCLE];
        raceClasses.forEach(raceClass => {
            if (classes.includes(raceClass) && !brackets.some(bracket => bracket.race_class === raceClass)) {
                brackets.push({
                    id: uuidv4() as UUID,
                    event: props.event,
                    race_class: raceClass,
                });
            } else if (!classes.includes(raceClass) && brackets.some(bracket => bracket.race_class === raceClass)) {
                brackets = brackets.filter(bracket => bracket.race_class !== raceClass);
            }
        });
        setProps(prevProps => ({
            ...prevProps,
            brackets: brackets
        }));
    }

    function adjustNumberOfCompetitors(division: RaceClass, numberOfCompetitors: number): void {
        let brackets: Bracket[] = props.brackets;
        let currentBracket: Bracket;
        brackets.forEach(bracket => {
            if (bracket.race_class === division) {
                bracket.number_of_competitors = numberOfCompetitors;
                currentBracket = bracket;
                let effectedQualifiers: Qualifier[] = props.qualifiers.filter(qualifier => {
                    return qualifier.race_class === bracket.race_class
                });
                let otherQualifiers: Qualifier[] = props.qualifiers.filter(qualifier => {
                    return qualifier.race_class !== bracket.race_class
                });
                if (effectedQualifiers.length < numberOfCompetitors) {
                    let position = effectedQualifiers.length + 1;
                    while (effectedQualifiers.length < numberOfCompetitors) {
                        effectedQualifiers.push({
                            seed: position,
                            race_class: division,
                            bracket: currentBracket,
                            event: props.event,
                        });
                        position++;
                    }
                } else if (effectedQualifiers.length > numberOfCompetitors) {
                    effectedQualifiers = effectedQualifiers.slice(0, numberOfCompetitors);
                }
                let qualifiers: Qualifier[] = effectedQualifiers.concat(otherQualifiers);
                setProps(prevProps => ({
                    ...prevProps,
                    brackets: brackets,
                    qualifiers: qualifiers
                }));
            }
        });
    }

    function editQualifier(division: RaceClass, seed: number, elapsed_time?: number, speed?: number, driver?: Driver): void {
        let qualifiers: Qualifier[] = props.qualifiers;
        qualifiers.forEach(qualifier => {
            if (qualifier.race_class === division && qualifier.seed === seed) {
                qualifier.driver = driver;
                qualifier.elapsed_time = elapsed_time;
                qualifier.speed = speed;
            }
        });
        if (props.event.is_four_wide) {
            let changed_qualifiers = qualifiers.filter(qualifier => {
                return qualifier.race_class === division;
            });
            let bracket = props.brackets.find(bracket => {
                return bracket.race_class === division
            });
            let results: FourWideResult[] = (props.results as FourWideResult[]).filter(result => {
                return result.bracket.race_class !== division;
            });
            let generatedResults = generateResultsFromQualifiersFourWide(changed_qualifiers, bracket!);
            results = results.concat(generatedResults);
            setProps(prevProps => ({
                ...prevProps,
                qualifiers: qualifiers,
                results: results,
            }));
        } else {
            let changed_qualifiers = qualifiers.filter(qualifier => {
                return qualifier.race_class === division;
            });
            let bracket = props.brackets.find(bracket => {
                return bracket.race_class === division
            });
            let results: Result[] = (props.results as Result[]).filter(result => {
                return result.bracket.race_class !== division;
            });
            let generatedResults = generateResultsFromQualifiers(changed_qualifiers, bracket!);
            results = results.concat(generatedResults);
            setProps(prevProps => ({
                ...prevProps,
                qualifiers: qualifiers,
                results: results,
            }));
        }
    }

    function setTiebreakerTwoWide(race_class: RaceClass) {
        let fastestSpeed: number = 0;
        let results = (props.results as Result[]).filter((result: Result) => {return result.bracket.race_class === race_class});
        if(results.filter((result: Result) => result.winner !== undefined && result.round === 4).length !== 0) {
            for (const result of results) {
                const speeds = [result.competitor_1_speed, result.competitor_2_speed];
                for(const speed of speeds){
                    if(speed && +speed > fastestSpeed){
                        fastestSpeed = +speed;
                    }
                }
            }
            props.brackets.forEach((bracket: Bracket) => {
                if(bracket.race_class === race_class){
                    bracket.tiebreaker = Math.ceil(fastestSpeed * 100) / 100;
                    setProps(prevProps => ({
                        ...prevProps,
                        brackets: props.brackets,
                    }));
                }
            });
        }
    }


    function setTiebreakerFourWide(race_class: RaceClass) {
        let fastestSpeed = 0;
        let results = (props.results as FourWideResult[]).filter((result: Result) => {return result.bracket.race_class === race_class});
        if(results.filter((result: FourWideResult) => result.first !== undefined && result.first !== null && result.round === 3).length !== 0) {
            for (const result of results) {
                const speeds = [result.competitor_1_speed, result.competitor_2_speed, result.competitor_3_speed, result.competitor_4_speed];
                for(const speed of speeds){
                    if(speed && +speed > fastestSpeed){
                        fastestSpeed = +speed;
                    }
                }
            }
            props.brackets.forEach((bracket: Bracket) => {
                if(bracket.race_class === race_class){
                    bracket.tiebreaker = Math.ceil(fastestSpeed * 100) / 100;
                    setProps(prevProps => ({
                        ...prevProps,
                        brackets: props.brackets,
                    }));
                }
            });
        }
    }

    function propogateAndValidateWinners(result: Result, results: Result[]): Result[] {
        results.forEach((currentResult) => {
            if (result.winner) {
                if (currentResult.competitor_1_game_code === result.game_code) {
                    currentResult.competitor_1 = result.winner;
                    currentResult.seed_1 = result.winner_seed;
                } else if (currentResult.competitor_2_game_code === result.game_code) {
                    currentResult.competitor_2 = result.winner;
                    currentResult.seed_2 = result.winner_seed;
                }
            } else {
                if (currentResult.competitor_1_game_code === result.game_code) {
                    currentResult.competitor_1 = undefined;
                    currentResult.seed_1 = undefined;
                } else if (currentResult.competitor_2_game_code === result.game_code) {
                    currentResult.competitor_2 = undefined;
                    currentResult.seed_2 = undefined;
                }
            }
            if (result.loser) {
                if (currentResult.round > result.round) {
                    if (currentResult.competitor_1 === result.loser) {
                        currentResult.competitor_1 = undefined;
                        currentResult.seed_1 = undefined;
                    } else if (currentResult.competitor_2 === result.loser) {
                        currentResult.competitor_2 = undefined;
                        currentResult.seed_2 = undefined;
                    }
                }
            }
        });
        return results
    }

    function updateResult(result: Result) {
        let results: Result[] = props.results as Result[];
        results.forEach((currentResult, index) => {
            if (currentResult.game_code === result.game_code) {
                results[index] = result;
            }
        });
        results = propogateAndValidateWinners(result, results);
        setProps(prevProps => ({
            ...prevProps,
            results: results,
        }));
        setTiebreakerTwoWide(result.bracket.race_class);
    }




    function propogateAndValidateWinnersFourWide(result: FourWideResult, results: FourWideResult[]): FourWideResult[] {
        results.forEach((currentResult) => {
            if (result.first) {
                if (currentResult.competitor_1_game_code === result.game_code) {
                    currentResult.competitor_1 = result.first;
                    currentResult.seed_1 = result.first_seed;
                } else if (currentResult.competitor_3_game_code === result.game_code) {
                    currentResult.competitor_3 = result.first;
                    currentResult.seed_3 = result.first_seed;
                }
            } else {
                if (currentResult.competitor_1_game_code === result.game_code) {
                    currentResult.competitor_1 = undefined;
                    currentResult.seed_1 = undefined;
                } else if (currentResult.competitor_3_game_code === result.game_code) {
                    currentResult.competitor_3 = undefined;
                    currentResult.seed_3 = undefined;
                }
            }
            if (result.second) {
                if (currentResult.competitor_2_game_code === result.game_code) {
                    currentResult.competitor_2 = result.second;
                    currentResult.seed_2 = result.second_seed;
                } else if (currentResult.competitor_4_game_code === result.game_code) {
                    currentResult.competitor_4 = result.second;
                    currentResult.seed_4 = result.second_seed;
                }
            } else {
                if (currentResult.competitor_2_game_code === result.game_code) {
                    currentResult.competitor_2 = undefined;
                    currentResult.seed_2 = undefined;
                } else if (currentResult.competitor_4_game_code === result.game_code) {
                    currentResult.competitor_4 = undefined;
                    currentResult.seed_4 = undefined;
                }
            }
            if (result.third) {
                if (currentResult.round > result.round) {
                    if (currentResult.competitor_1 === result.third) {
                        currentResult.competitor_1 = undefined;
                        currentResult.seed_1 = undefined;
                    } else if (currentResult.competitor_2 === result.third) {
                        currentResult.competitor_2 = undefined;
                        currentResult.seed_2 = undefined;
                    }
                    else if (currentResult.competitor_3 === result.third) {
                        currentResult.competitor_3 = undefined;
                        currentResult.seed_3 = undefined;
                    }
                    else if (currentResult.competitor_4 === result.third) {
                        currentResult.competitor_4 = undefined;
                        currentResult.seed_4 = undefined;
                    }
                }
            }
            if (result.fourth) {
                if (currentResult.round > result.round) {
                    if (currentResult.competitor_1 === result.fourth) {
                        currentResult.competitor_1 = undefined;
                        currentResult.seed_1 = undefined;
                    } else if (currentResult.competitor_2 === result.fourth) {
                        currentResult.competitor_2 = undefined;
                        currentResult.seed_2 = undefined;
                    }
                    else if (currentResult.competitor_3 === result.fourth) {
                        currentResult.competitor_3 = undefined;
                        currentResult.seed_3 = undefined;
                    }
                    else if (currentResult.competitor_4 === result.fourth) {
                        currentResult.competitor_4 = undefined;
                        currentResult.seed_4 = undefined;
                    }
                }
            }
        });
        return results
    }

    function updateResultFourWide(result: FourWideResult) {
        let results: FourWideResult[] = props.results as FourWideResult[];
        results.forEach((currentResult, index) => {
            if (currentResult.game_code === result.game_code) {
                results[index] = result;
            }
        });
        results = propogateAndValidateWinnersFourWide(result, results);
        setProps(prevProps => ({
            ...prevProps,
            results: results,
        }));
        setTiebreakerFourWide(result.bracket.race_class)
    }


    return (
        <Layout>
            <Helmet>
                <title>DRBB | Event Configuration</title>
            </Helmet>
                <Grid container sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'fit-content',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: 'calc(100vh  - 384px)',
                    maxWidth: '100vw',
                }}>
                    <Grid container sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'fit-content',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '12px',
                    }}>
                        <Grid container sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            padding: '12px',
                            position: 'relative',
                            flexWrap: 'nowrap',
                            overflowX: 'auto',
                            justifyContent: 'center',
                        }}>
                            {loading ?
                                <EventDetailFormSkeleton/>
                                :
                                <EventDetailForm
                                    series={props.series}
                                    event={props.event ?? blankEvent}
                                    onEventChange={handleEventChange}
                                    toggleBrackets={toggleBrackets}
                                    brackets={props.brackets}
                                    submitEvent={submitEventConfig}
                                />
                            }
                            {props.brackets.length > 0 &&
                                <Grid container direction={'column'} sx={{width: 'fit-content'}}>
                                    {props.brackets.map((bracket: Bracket) => {
                                        return (
                                            <Box key={bracket.id} margin={'8px'}>
                                                <QualifierForm
                                                    division={bracket.race_class}
                                                    drivers={props.drivers.filter((driver) => {
                                                        return (driver.divisions as RaceClass[]).includes(bracket.race_class)
                                                    }).sort((a: Driver, b: Driver) => {
                                                        const lastNameComparison = a.last_name.localeCompare(b.last_name);
                                                        if (lastNameComparison !== 0) {
                                                            return lastNameComparison;
                                                        } else {
                                                            return a.first_name.localeCompare(b.first_name);
                                                        }
                                                    })}
                                                    qualifiers={props.qualifiers.filter((qualifier) => {
                                                        return qualifier.race_class === bracket.race_class
                                                    })}
                                                    adjustNumberOfQualifiers={adjustNumberOfCompetitors}
                                                    editQualifier={editQualifier}
                                                />
                                            </Box>
                                        )
                                    })}
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    {props.event.is_four_wide ?
                        <Box sx={{
                            overflowX: 'auto',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <FourWideConfigBracketContainer
                                event={props.event}
                                brackets={props.brackets}
                                results={props.results as FourWideResult[]}
                                updateResult={updateResultFourWide}
                            />
                        </Box>
                        :
                        <Box sx={{
                            overflowX: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                        }}>
                            <ConfigBracketContainer
                                event={props.event}
                                brackets={props.brackets}
                                results={props.results as Result[]}
                                updateResult={updateResult}
                            />
                        </Box>
                    }
                </Grid>
        </Layout>
    );
}