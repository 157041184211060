import { Button, Snackbar } from '@mui/material'
import { useState, CSSProperties } from 'react'
import Typography from "@mui/material/Typography";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type CopyToClipboardButtonProps = {
    text_to_copy: string,
    text_to_display: string
    style?: CSSProperties,
}
export default function CopyToClipboardButton(props: Readonly<CopyToClipboardButtonProps>){
    const [open, setOpen] = useState<boolean>(false)
    const handleClick = () => {
        navigator.clipboard.writeText(props.text_to_copy).then(() => {
            setOpen(true)
        })
    }

    return (
        <>
            <Button onClick={handleClick} variant={"contained"} style={props.style}
                    startIcon={<ContentCopyIcon/>}
                    sx={{
                ":hover": {
                    backgroundColor: "#c40000",
                    color: "#ffffff",
                }
            }}>
                <Typography variant={'h6'} noWrap>
                    {props.text_to_display}
                </Typography>
            </Button>
            <Snackbar
                open={open}
                onClose={() => setOpen(false)}
                autoHideDuration={2000}
                message="Copied to clipboard"
            />
        </>
    )
}