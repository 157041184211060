import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {NewsPost} from "../../../data/models/sponsor_api/NewsPost";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import {useTheme} from "@mui/material";

export default function NewsRow(newsPost: NewsPost) {
    const theme = useTheme();
    const darkMode = theme.palette.mode === 'dark';
    return (
        <Link key={newsPost.title} href={newsPost.link} m={'10px'} target={"_blank"} rel={"noopener"} sx={{
            textDecoration: 'none',
            width: '100%',
        }}>
            <Grid container sx={{
                direction: "row",
                alignItems: "center",
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                color: darkMode ? 'white' : 'black',
                padding: 1,
            }}>
                {newsPost.image_link === null ?
                    null :
                    <Grid item xs={4} sx={{
                        width: '150px',
                        height: '150px',
                        backgroundImage: `url(${newsPost.image_link})`,
                        backgroundSize: 'cover',
                        marginX: 1,
                    }}/>
                }
                <Grid item xs={8} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Typography fontWeight={"bold"}>
                        {newsPost.title}
                    </Typography>
                    <Typography>
                        {newsPost.publisher}
                    </Typography>
                    <Typography fontStyle={"italic"}>
                        {new Date(newsPost.publish_date).toDateString()}
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{mY:'4px'}}/>
        </Link>
    )
}