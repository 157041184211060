import Layout from "../utilities/Layout";
import Typography from "@mui/material/Typography";
import {ChangeEvent, useEffect, useState} from "react";
import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import {Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar} from "@mui/material";
import * as LeagueAPI from "../apis/League";
import Paper from "@mui/material/Paper";
import {League} from "../data/models/user_auth/League";
import ValidateSignIn from "../utilities/ValidateSignIn";
import {LeaderboardEntry, LeaderboardProps} from "../components/leaderboard/Leaderboard";
import GA4EventSend from "../utilities/GA4EventSend";
import Compressor from "compressorjs";
import Loader from "../components/common/Loader";
import {Helmet} from "react-helmet";


export default function LeagueEdit() {
    let searchParams = window.location.href.substring(window.location.href.indexOf("?") + 1);
    let decoded = decodeURIComponent(searchParams);
    const queryParams = new URLSearchParams(decoded);
    const leagueId = queryParams.get('league_id') as unknown as number;
    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<String>("");
    const [severity, setSeverity] = useState<"success" | "info" | "warning" | "error" | undefined>("error"); // ["success", "info", "warning", "error"
    const [isClicked, setIsClicked] = useState(false);
    const [file, setFile] = useState<File | undefined>(undefined);
    const [formValid, setFormValid] = useState<boolean>(true);
    const [league, setLeague] = useState<League | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    let user_nickname = JSON.parse(localStorage.getItem('user') as string).nickname;
    const [open2, setOpen2] = useState<boolean>(false);
    const [leagueName, setLeagueName] = useState<String>("");

    useEffect(() => {
        if (loading) {
            ValidateSignIn().then(() => {
                // Get user id from local storage and convert it to number
                let user_id: number = JSON.parse(localStorage.getItem('user') as string).id;
                LeagueAPI.get_league(leagueId).then((response: LeagueAPI.LeagueResponse) => {
                    if (response.succeeded) {
                        if (user_nickname !== response.data.creator) {
                            window.location.href = "/leagues";
                        }
                        let leaderboards: LeaderboardProps[] = [];
                        if (response.data.event_id && response.data.leaderboard) {
                            Object.entries(response.data.leaderboard).forEach(([key, value]) => {
                                let leaderboard_rows: LeaderboardEntry[] = [];
                                for (const element of value as any) {
                                    leaderboard_rows.push({
                                        rank: element.rank,
                                        nickname: element.nickname,
                                        points: element.points,
                                        possible_points: element.possible_points,
                                    })
                                }
                                leaderboards.push({
                                    display_name: key,
                                    entries: leaderboard_rows,
                                    viewable: !response.data.brackets_open,
                                    event_id: response.data.event_id,
                                    league_id: leagueId.toString(),
                                });
                            });
                        }
                        setLeague(response.data);
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }
                    GA4EventSend("league_edit", "load", "league_edit", response.statusCode, false);
                });
            });
        }
        ;
    }, [loading]);


    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const form = new FormData(event.currentTarget.form as HTMLFormElement);
        // Access the form element and perform any desired operations
        const formFile: File = form.get('league-photo') as File;
        setFormValid(form.get('league-name') !== '' && (form.get('league-name') as string).length < 20);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        setIsClicked(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        data.append('league-photo', file as File);
        let players: number[] = [];
        league?.players.forEach((player) => players.push(player.id));
        const response: LeagueAPI.LeagueResponse = await LeagueAPI.edit_league({
            creator: JSON.parse(localStorage.getItem('user') as string).id,
            league_name: data.get('league-name') as string,
            id: leagueId as number,
            players: players,
        });
        if (response.succeeded) {
            if (file) {
                const fileResponse: LeagueAPI.LeagueResponse = await LeagueAPI.upload_league_photo(file as File, leagueId, JSON.parse(localStorage.getItem('user') as string).id);
                if (!fileResponse.succeeded) {
                    setMessage("League Edited, but photo upload failed");
                    setSeverity("error");
                    setOpen(true);
                } else {
                    setMessage("League Edited, and photo upload successful");
                    setSeverity("success");
                    setOpen(true);
                    window.location.href = "/league/?" + encodeURIComponent("league_id=" + leagueId);
                }
            } else {
                setMessage("League Edited");
                setSeverity("success");
                setOpen(true);
                window.location.href = "/league/?" + encodeURIComponent("league_id=" + leagueId);
            }
            GA4EventSend("league_edit", "submit", "league_edit", response.statusCode, false);

        } else {
            setMessage(response.message);
            setSeverity("error");
            setOpen(true);
        }
        setIsClicked(false);
    };

    const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        // Perform any validation or checks on the file here
        // make sure file is of a photo extension
        if (file) {
            const extension: string = file?.name.split('.').pop() as string;
            if (extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png') {
                setSeverity("error")
                setMessage("File must be a photo");
                setOpen(true);
            } else if ((file as File)?.size > 5000000) {
                setSeverity("error")
                setMessage("File must be less than 5MB");
                setOpen(true);
            } else {
                if (file) {
                    let quality = 0.8; // start with high quality
                    new Compressor(file, {
                        quality: quality,
                        maxWidth: 300,
                        maxHeight: 300,
                        resize: 'cover',
                        success: (compressedResult) => {
                            // If file size is still more than 1MB, compress again with lower quality
                            while (compressedResult.size > 1000000 && quality > 0.1) {
                                quality -= 0.1; // reduce quality
                                new Compressor(compressedResult, {
                                    quality: quality,
                                    success: (doubleCompressedResult) => {
                                        compressedResult = doubleCompressedResult;
                                    },
                                });
                            }
                            console.log(compressedResult.size, quality);
                            setFile(compressedResult as File)
                            setSeverity("success")
                            setMessage("File is valid format");
                            setOpen(true);
                            handleChange(event);
                        },
                    });
                }
            }
        }
    };

    function deleteLeague(){
        LeagueAPI.delete_league(leagueId, JSON.parse(localStorage.getItem('user') as string).id).then((response: boolean) => {
            if(response){
                setMessage("Successfully deleted league. Redirecting...");
                setSeverity("success");
                setOpen(true);
                setTimeout(() => {
                    window.location.href = "/leagues";
                }, 3000);
            }
            else{
                setMessage("Failed to delete league.");
                setSeverity("error");
                setOpen(true);
            }
            GA4EventSend("league_delete", "submit", "league_delete", response ? 204 : 500, false);
        });
    }


    return (
        <Layout>
            <CssBaseline/>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={open} onClose={() => setOpen(false)}
                      autoHideDuration={6000}>
                <Alert elevation={6} severity={severity} sx={{width: '100%'}} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
            <Helmet>
                <title>DRBB | Edit League</title>
            </Helmet>
            <Dialog open={open2} onClose={() => setOpen2(false)}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To delete this league, please enter the name of the league below and hit delete.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="League Name"
                        fullWidth
                        variant="standard"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setLeagueName(event.target.value)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen2(false)}>Cancel</Button>
                    <Button disabled={leagueName !== league?.name} onClick={() => {
                        if(league !== null) {
                            deleteLeague()
                        }
                        setOpen2(false)
                    }}>Delete</Button>
                </DialogActions>
            </Dialog>
            <Grid container sx={{
                display: 'flex',
                justifyContent: 'center',
            }}>
                {!loading ?
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            padding: '12px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            alignContent: 'center',
                            borderRadius: '10px'
                        }}
                        component={Paper}
                        elevation={6}
                    >
                        <Typography variant="h3" fontWeight={"bold"} textAlign={"center"}>
                            Edit Your League
                        </Typography>
                        {
                            file ? <img src={URL.createObjectURL(file)} alt="League Photo" width={200} height={200}/> :
                                league?.image_url ?
                                    <img src={league?.image_url} alt="League Photo" width={200} height={200}/>
                                    : null
                        }
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{
                            mt: 1,
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                defaultValue={league?.name ? league.name : ""}
                                id="league-name"
                                label="League Name"
                                name="league-name"
                                autoFocus
                                onChange={handleChange}
                            />
                            <Button variant="contained" component="label" sx={{
                                backgroundColor: "#486ce7",
                                minWidth: 'fit-content',
                                color: "#ffffff",
                                ":hover": {
                                    backgroundColor: "#c40000",
                                    color: "#ffffff",
                                },
                                mt: 3,
                                mb: 2
                            }}>
                                Upload League Photo File
                                <input type="file" id="league-photo" name="league-photo" required hidden
                                       onChange={handleImageUpload}/>
                            </Button>
                            <ul>
                                <li>
                                    League name must be unique
                                </li>
                                <li>
                                    League name must be less than 20 characters
                                </li>
                                <li>
                                    File must be a photo
                                </li>
                                <li>
                                    File must be less than 5MB
                                </li>
                            </ul>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    backgroundColor: "#486ce7",
                                    minWidth: 'fit-content',
                                    color: "#ffffff",
                                    ":hover": {
                                        backgroundColor: "#c40000",
                                        color: "#ffffff",
                                    },
                                    mt: 3,
                                    mb: 2
                                }}
                                disabled={isClicked || !formValid}
                            >
                                Submit Changes
                            </Button>
                            <Button
                                type={"button"}
                                fullWidth
                                variant="contained"
                                sx={{
                                    backgroundColor: "#c40000",
                                    minWidth: 'fit-content',
                                    color: "#ffffff",
                                    ":hover": {
                                        backgroundColor: "#c40000",
                                        color: "#ffffff",
                                    },
                                    mt: 3,
                                    mb: 2
                                }}
                                onClick={() => {
                                    setOpen2(true);
                                }}
                            >
                                Delete League
                            </Button>
                        </Box>
                    </Box> :
                    <Grid container sx={{height: '60vh'}}>
                        <Loader/>
                    </Grid>
                }
            </Grid>
        </Layout>
    )
}