import {useEffect, useState} from "react";
import * as React from "react";
import {about_me, change_nickname, change_phone} from "../apis/Auth";
import Layout from "../utilities/Layout";
import {Alert, List, ListItem, Snackbar, Theme, useMediaQuery} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MuiPhoneNumber from "material-ui-phone-number-2";
import Typography from "@mui/material/Typography";
import GA4EventSend from "../utilities/GA4EventSend";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {Helmet} from "react-helmet";

export default function ChangePhone() {
    if (localStorage.getItem("user") === null) {
        let pathname = encodeURIComponent("/change-phone");
        window.location.href = "/signin/redirect?path=" + pathname;
    }

    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isClicked, setIsClicked] = useState(false);
    const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"success" | "error" | "info" | "warning" | undefined>("error");
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));

    useEffect(() => {
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp]);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    function handleChange(event: React.ChangeEvent<HTMLFormElement>) {
        const data = new FormData(event.currentTarget);
        const phone = data.get('phone') as string ?? "";
        setButtonEnabled(
            phone.length === 17
        );
    }

    async function removePhone() {
        setIsClicked(true);
        let response = await change_phone(localStorage.getItem("access_token") ?? "", localStorage.getItem("refresh_token") ?? "", "");
        if (response.succeeded) {
            let ab_response = await about_me(localStorage.getItem("refresh_token") ?? "", localStorage.getItem("access_token") ?? "");
            if (ab_response.succeeded) {
                localStorage.setItem('user', JSON.stringify(ab_response.data));
            } else {
                console.error(response.message);
            }
            setSeverity("success");
            setMessage("Successfully removed phone number and unsubscribed from SMS");
            setOpen(true);
        } else {
            setSeverity("error");
            setMessage("Failed to remove phone number");
            setOpen(true);
        }
        GA4EventSend("remove_phone_number", "user", "remove_phone_number", response.statusCode, false);
        setIsClicked(false);
    }


    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setIsClicked(true);
        const data = new FormData(event.currentTarget);
        const phone = data.get('phone') as string ?? "";
        if (phone.length === 17) {
            let response = await change_phone(localStorage.getItem("access_token") ?? "", localStorage.getItem("refresh_token") ?? "", phone);
            if (response.succeeded) {
                let ab_response = await about_me(localStorage.getItem("refresh_token") ?? "", localStorage.getItem("access_token") ?? "");
                if (ab_response.succeeded) {
                    localStorage.setItem('user', JSON.stringify(ab_response.data));
                } else {
                    console.error(response.message);
                }
                setSeverity("success");
                setMessage(response.message);
                setOpen(true);
            } else {
                setSeverity("error");
                setMessage(response.message);
                setOpen(true);
            }
            GA4EventSend("change_phone_number", "user", "change_phone_number", response.statusCode, false);
        }
        setIsClicked(false);
    }

    return (
        <Layout>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={open} onClose={handleClose}
                      autoHideDuration={6000}>
                <Alert elevation={6} severity={severity} sx={{width: '100%'}} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
            <Helmet>
                <title>DRBB | Change Phone</title>
            </Helmet>
            <Box sx={{
                height: isMdOrUp ? '65vh' : 'fit-content',
                width: '100%',
                display: 'flex',
                m: 2,
                borderRadius: '10px'
            }}>
                <Paper elevation={3} sx={{
                    padding: 2,
                    margin: 'auto',
                    width: 'fit-content',
                    height: 'fit-content'
                }}>
                    <Grid container direction={"column"} component={"form"} onSubmit={handleSubmit}
                          onChange={handleChange}
                          sx={{
                              maxWidth: '300px',
                              margin: 'auto',
                              padding: 1,
                          }}
                    >
                        <Typography variant={"h4"} fontWeight={"bold"} sx={{textAlign: "center", mb: 2}}>
                            Change Phone Number
                        </Typography>
                        <MuiPhoneNumber
                            defaultCountry={'us'}
                            onlyCountries={['us']}
                            fullWidth
                            onChange={() => {
                            }}
                            name="phone"
                            id={"phone"}
                            label="Phone Number"
                            countryCodeEditable={false}
                            disableDropdown={true}
                        />
                        <List sx={{listStyleType: 'disc'}}>
                            <ListItem sx={{display: 'list-item'}}>
                                Must be a US phone number.
                            </ListItem>
                            <ListItem sx={{display: 'list-item'}}>
                                By providing your phone number, you agree to receive SMS updates from DRBB.
                            </ListItem>
                        </List>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                            disabled={isClicked || !buttonEnabled}
                        >
                            Change Phone Number
                        </Button>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                            disabled={isClicked}
                            onClick={removePhone}
                        >
                            Remove Phone Number and Unsubscribe From SMS
                        </Button>
                    </Grid>
                </Paper>
            </Box>
        </Layout>
    )
}
