import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Logo from "../assets/images/logo.png";
import {Box, useTheme} from "@mui/material";
import {Helmet} from "react-helmet";
import CssBaseline from "@mui/material/CssBaseline";

export default function Terms() {
    const theme = useTheme();
    const darkMode = theme.palette.mode === "dark";
    return (
        <Grid container direction={"column"} sx={{
            maxWidth: "100%",
            overflowY: "auto",
            flexWrap: "nowrap",
            padding: "1rem",
            alignItems: "center",
            background: darkMode ?
                'linear-gradient(135deg, white 10%, #121212 10%, #121212 12%, white 12%, white 22%, transparent 22%);'
                :
                'linear-gradient(135deg, #7F9AF5 10%, white 10%, white 12%, #7F9AF5 12%, #7F9AF5 22%, transparent 22%);',
            backgroundAttachment: 'fixed',
        }}>
            <Helmet>
                <title>DRBB | Terms Of Service</title>
            </Helmet>
            <CssBaseline/>
            <img src={Logo} alt={"DRBB Logo"} style={{maxWidth: "300px", marginBottom: '1rem'}}/>
            <Box sx={{
                backgroundColor: 'background.paper',
                borderRadius: '10px',
                p: 2,
            }}>
                <Typography variant={"h5"} fontWeight={"bold"}>
                    To enter this Promotion, you must agree to and abide by these official rules ("Official Rules").
                </Typography>
                <br/>
                <Typography variant={"h6"} fontWeight={"bold"} textAlign={"left"}>
                    NO PURCHASE NECESSARY. NO PURCHASE OR PAYMENT NECESSARY TO ENTER OR WIN. A PURCHASE
                    OR PAYMENT WILL NOT IMPROVE ONE'S CHANCES OF WINNING. UNLESS OTHERWISE SPECIFIED IN SECTION 3 BELOW,
                    VOID IN PUERTO RICO, OTHER U.S. TERRITORIES AND FOREIGN TERRITORIES, QUEBEC AND WHERE PROHIBITED BY
                    LAW.
                </Typography>
                <br/>
                <Typography variant={"h6"} textAlign={"left"}>
                    THE AWARDING OF POINTS FOR MAKING CORRECT PICKS IN AN INDIVIDUAL RACE AND ONE'S RANKING ON THE
                    LEADER
                    BOARD IS SOLELY FOR ENTERTAINMENT VALUE.

                    2. ADMINISTRATOR. This promotion will be administered by Werner G+E LLC., 1033 Brook
                    Arbor
                    Drive, Mansfield, Texas 76063 (the "Administrator").

                    3. ELIGIBILITY. The 2023 Drag Race Bracket Bonanza (the "Promotion") is open only to legal residents
                    of
                    the 50 United States, District of Columbia and Canada (excluding Quebec), who are at least eighteen
                    (18)
                    years or older (or the age of majority in their jurisdiction of residence, whichever is older) as of
                    the
                    date of entry. The following are not eligible to participate in the Promotion or win a Prize: (i)
                    employees and agents of Sponsor, Promoter, Administrator, each of their respective parent,
                    subsidiary
                    and affiliated companies, and each of their respective advertising/promotion agencies; and (ii) the
                    "immediate family members" and/or "household members" of any of the persons set forth in (i) above.
                    "Immediate family members" shall mean parents, step-parents, children, step-children, siblings or
                    spouses. "Household members" shall mean people who share the same residence at least three (3)
                    months a
                    year. This Promotion is void in all U.S. Territories (e.g., Puerto Rico and Guam), foreign
                    territories
                    not specifically included herein, and where prohibited or restricted by law and subject to all
                    applicable federal, state, local and municipal laws and regulations.

                    4. PROMOTION TIMING: Registration for entry in the Promotion begins following the final qualifying
                    session and when the qualifying order has been made official ends before the first round of
                    eliminations. All entries must be received during the Entry Period. The Promotion is the 2023 Drag
                    Race
                    Bracket Bonanza taking place from September 4, 2023, through November 12, 2023, that consists of
                    seven
                    (7) races. For purposes of these Official Rules, all times and days are United States Eastern
                    Standard
                    Time ("ET") (e.g., New York time). Sponsor's computer is the official time-keeping device for the
                    Promotion.

                    5. REGISTRATION & ENTRY: You must be a registered member ("Member") to enter the Promotion. To
                    register
                    and enter, visit www.dragracebracketbonanza.com ("Website"). The Website is collectively referred to
                    herein as, the "Entry Method". To enter via the Entry Method, select the "Enter" button. You will
                    need
                    to log in with your e-mail address and password. To enter for free visit the Website, click on the
                    profile icon, select the "Log In" link and selecting the "Sign Up" link and providing the requested
                    information on the registration form to create an account. There is a limit of twenty-five (25)
                    total
                    entries per person per race described below.

                    You may be asked to consent to receive promotional emails or offers. This is optional and does not
                    affect your chances of winning in this Promotion. You are NOT required to give consent to receive
                    such

                    emails or offers in order to participate in the Promotion or win any prize. If you do consent to
                    receiving promotional emails or offers, your personal information will be handled in accordance with
                    Section 13 below.

                    Limit of twenty-five (25) entries per person per race during the Entry Period, regardless of Entry
                    Method used and regardless of the number of email addresses or registered Member accounts. Whether
                    you
                    enter via the Website there is a limit of twenty-five (25) total entries per person for this
                    Promotion.
                    Multiple entries in violation of the preceding limit and/or mass entry attempts, as determined by
                    Sponsor in its sole discretion, will result in disqualification of all entries by such person/Member
                    and
                    perhaps legal and/or other sanctions. Entries must be manually key-stroked by the entrant; use of
                    automated entry devices or programs, or entries by third parties are prohibited. Entries that do not
                    conform to or that violate these Official Rules or are submitted in any manner other than stated in
                    these Official Rules will be disqualified. By entering this Promotion, entrants agree to these
                    Official
                    Rules and the decisions of the Sponsor, which are binding and final in all matters relating to this
                    Promotion.

                    Participation in the Promotion constitutes entrant's full and unconditional agreement to these
                    Official
                    Rules and to Sponsor's decisions. The decisions of the Sponsor regarding all matters relating to the
                    Promotion are final and binding. Winning any prize is contingent upon fulfilling all requirements
                    set
                    forth herein.

                    Proof of submitting an entry does not constitute proof of receipt or entry into the Promotion. It is
                    your sole responsibility to notify the Sponsor/Administrator of a change in your e-mail address
                    prior to
                    the end of the Entry Period.

                    *IMPORTANT: Data rates may apply if Promotion entry is submitted via your mobile device according to
                    the
                    terms and conditions of your service agreement with your wireless carrier. Other charges may apply
                    (such
                    as normal airtime and carrier charges as well as charges for wireless Internet access) and may
                    appear on
                    your mobile phone bill or be deducted from your prepaid account balance. Wireless carriers' rate
                    plans
                    may vary, and you should contact your wireless carrier for more information on messaging/data rate
                    plans
                    and charges relating to your participation in this Promotion. Mobile device service may not be
                    available
                    in all areas. Check your device's capabilities for specific instructions.

                    6. HOW TO PLAY:

                    a. General. The Promotion involves selecting the drivers that you believe will win each round of the
                    national event.

                    b. How to make your selections. You may enter your selections by completing a race bracket following
                    the
                    posted instructions. For your entry to be complete, you must select the driver/rider that you think
                    will
                    win each of the four (4) rounds in the race in each of the professional categories. You must also
                    select
                    winners for each professional category Top Fuel, Funny Car, Pro Stock and Pro Stock Motorcycle.

                    c. Lock-time. All race picks will lock at the scheduled elimination time of the first round of Top
                    Fuel
                    (the "Lock Time"). Your race picks for the national event must be submitted, received and recorded
                    by
                    Sponsor's computer by the scheduled Lock Time. Selections submitted after the Lock Time are
                    considered
                    invalid. Sponsor's computer is the official time-keeping device for this Promotion.

                    d. Leader Boards. After Sponsor has completed scoring for each race (as well as the Promotion
                    overall),
                    Members will be listed by username along with their scores on a leader board ("Leader Board") on the
                    Website. The Leader Board is solely for entertainment purposes only. Notwithstanding the posting of
                    any
                    Leader Board, Sponsor reserves the right to revise the scores and rankings of Members listed on the
                    Leader Board at any time. Accordingly, even if a Member is listed as the highest point scorer in a
                    Final
                    Leader Board, such Member is not guaranteed to win a prize in the Promotion. Inclusion of a Member
                    in a
                    Final Leader Board does not constitute any notification of winning; potential winners will only be
                    notified via email from Sponsor/Administrator.

                    7. UNFORESEEABLE CIRCUMSTANCE.

                    ENTRANTS UNDERSTAND AND AGREE THAT THE RACE MAY NOT BE CONDUCTED AS ORIGINALLY SCHEDULED.

                    IF THE RACE IS CANCELLED PRIOR TO CONCLUSION. PRIZE WINNERS WILL BE DETERMINED VIA RANDOM DRAWING
                    FROM
                    AMONG ALL ELIGIBLE ENTRANTS WHO SUBMITTED A COMPLETED BRACKET; I.E., IN THE SAME MANNER THAT PRIZE
                    WINNERS WILL BE SELECTED AS DISCUSSED BELOW.

                    IF THE RACE IS MODIFIED OR POSTPONED, THE PRIZE WINNERS WILL BE DETERMINED VIA RANDOM DRAWING FROM
                    AMONG
                    ALL ELIGIBLE ENTRANTS WHO SUBMITTED A BRACKET WHICH CORRECTLY IDENTIFIED THE DRIVER/RIDERS THAT WON
                    IN
                    ITS MODIFIED/POSTPONED FORMAT.

                    NOTICE OF SUCH ACTIONS BY SPONSOR WILL BE POSTED ON THE WEBSITE.

                    CANCELLATION/MODIFICATION/POSTPONEMENT OF THE RACE WILL NOT AFFECT THE SELECTION OF PRIZE WINNERS.

                    8. WINNER DETERMINATION/NOTIFICATION.

                    (a) Grand Prize.

                    Sponsor and/or Administrator will review all eligible entries received during the Entry Period
                    overall
                    to determine those entries which correctly identified the highest scoring driver/rider combination
                    which
                    won the national event. The Grand Prize winners will be selected via a random drawing from among all
                    such entries.

                    (b) First Prize

                    The First Prize winners will be selected via a random drawing from among all eligible entries with
                    twenty-five (25) completed brackets each featuring a selection of the winning drivers/riders.

                    There is no entry fee required to participate in the Promotion. Only entries received online in
                    accordance with these Official Rules will be accepted. No other forms of entry - fax, mail, email,
                    phone
                    or other - will be accepted. Entry materials/data that have been tampered with or altered are void.

                    9. PRIZES.

                    GRAND PRIZE: TEN (10) WINNERS shall each receive XXXXX.

                    FIRST PRIZE: TWENTY-FIVE (25) WINNERS shall each receive XXXX.

                    Total ARV: XXXXXX

                    In all cases:

                    (a) prize award is subject to verification of entrant's full compliance with these Official Rules;

                    (b) prize is not transferable by winner.

                    (c) Sponsor in its sole discretion may award a substitute prize or prize component of equal or
                    greater
                    value if advertised prize/prize component is unavailable at time of awarding for any reason.

                    (d) limit of one (1) prize per person.

                    Odds of winning a Grand Prize depends on the total number of eligible entries received and the
                    number of
                    eligible entries included in the Grand Prize random drawing as described above.

                    Odds of winning a First Prize depends on the total number of eligible entries received and the
                    number of
                    eligible entries included in the First Prize random drawing as described above.

                    Winners should allow up to 8-10 weeks after completion of the prize claim procedure (including, if
                    applicable, the return of their signed, notarized affidavit) to receive their prize.

                    10. ENTRY ERRORS/ NO RETURN OF ENTRIES; DISPUTES: Neither Sponsor nor Promoters are responsible for
                    lost, late, incomplete, damaged, stolen, invalid, unintelligible or misdirected entries, which will
                    be
                    disqualified. Neither Sponsor nor Promoters are responsible for any unavailability of or
                    interruptions
                    to any service or equipment used in connection with the Promotion, including, without limitation,
                    (1)
                    interruptions to any network, server, Internet, website, telephone, satellite, computer or other
                    connections (2) failures of any telephone, satellite, hardware, software or other equipment, (3)
                    garbled, misdirected or jumbled transmissions, or traffic congestion, or (4) other errors of any
                    kind,
                    whether human, technical, mechanical or electronic, or (5) the incorrect or inaccurate capture of
                    entry
                    or other information or the failure to capture any such information. Once submitted, entries become
                    the
                    sole

                    property of Sponsor and will not be acknowledged or returned. In the event of a dispute as to any
                    entry,
                    the authorized account holder at the time of entry will be deemed to be the entrant. Potential
                    winner
                    may be required to show proof of identification, and Sponsor may require that the potential winner
                    provide proof that his/her identification matches the User Information associated with the winning
                    entry
                    (both at the time of entry and at the time of verification of a potential winner). SPONSOR RESERVES
                    THE
                    RIGHT TO DISQUALIFY ANY POTENTIAL WINNER IF IT DETERMINES, IN ITS SOLE DISCRETION, THAT ANY USER
                    INFORMATION WAS CHANGED OR FALSIFIED IN ORDER TO MEET ELIGIBILITY REQUIREMENTS. The decisions of the
                    drag racing sanctioning body shall be final and binding regarding the outcome of the race.

                    11. POTENTIAL WINNER. ALL POTENTIAL WINNING ENTRIES ARE SUBJECT TO VERIFICATION BY SPONSOR, WHOSE
                    DECISIONS ARE FINAL. SPONSOR SHALL SOLELY DETERMINE ANY FORM OF VERIFICATION. AN ENTRANT IS NOT A
                    WINNER
                    OF ANY PRIZE UNLESS AND UNTIL ENTRANT'S ELIGIBILITY AND THE POTENTIAL WINNING ENTRY HAS BEEN
                    VERIFIED
                    AND ENTRANT HAS BEEN NOTIFIED THAT VERIFICATION IS COMPLETE. SPONSOR WILL NOT ACCEPT SCREEN SHOTS,
                    AFFIDAVITS OR OTHER EVIDENCE OF WINNING IN LIEU OF ITS VALIDATION PROCESS. ANY ENTRY THAT OCCURS
                    AFTER
                    THE SYSTEM HAS FAILED FOR ANY REASON MAY BE DEEMED A DEFECTIVE ENTRY AND VOID. Potential winners
                    will be
                    notified via email sent within ten (10) business days following the end of the race. It is the sole
                    responsibility of the entrant to notify the Sponsor if his or her e-mail address has changed. To do
                    so
                    click on the Account Information link to update your email address. A potential winner will be
                    disqualified and an alternate potential winner may be selected via random drawing from among all
                    remaining eligible entries received for such affected random drawing if (i) he/she does not comply
                    with
                    these rules (or is otherwise determined to be ineligible), (ii) he/she does not respond to the prize
                    notice within five (5) days of its transmission, or if the emailed prize notice, after three (3)
                    attempts, is returned as undeliverable, or (iii) the prize is undeliverable for any reason. If
                    Sponsor
                    notifies, or seeks to deliver a prize to or otherwise attempts to contact an alternate potential
                    winner,
                    and such alternate potential winner is subsequently disqualified then Sponsor shall use its
                    reasonable
                    business judgment to determine, in its sole discretion, the disposition of the prize. Winning a
                    prize is
                    contingent upon fulfilling all requirements set forth herein. Winner is solely responsible for any
                    and
                    all expenses, relative to participation in the Promotion (including acceptance of any prize) not
                    specified herein.

                    12. AFFIDAVIT OF ELIGIBILITY/LIABILITY RELEASE. As a condition of receiving a prize, potential
                    winner
                    will be required to complete a Publicity Release, except where prohibited, within ten (10) days of
                    the
                    date stated on the prize notice. Except where prohibited, participation in this Promotion and
                    acceptance
                    of prize constitutes winner's consent to Sponsor's and Promoters' and any of their respective
                    agents'
                    use of winner's name, likeness, voice, opinions and biographical information for publicity,
                    advertising,
                    trade or promotional purposes in any media or manner, now known or hereafter devised, worldwide,
                    without
                    further payment, consideration, notice or approval.

                    13. CONDITIONS OF PARTICIPATION. Participation constitutes entrant's full and unconditional
                    agreement to
                    these Official Rules and to Sponsor's decisions. The decisions of the Sponsor regarding all matters
                    of
                    this Promotion are final and binding. Sponsor reserves the right in its sole discretion, to modify,
                    cancel, terminate, and/or suspend the Promotion and to disqualify any individual who tampers with
                    the
                    entry process, violates these Official Rules, or acts in a disruptive or unsportsmanlike manner.
                    Without
                    limiting the foregoing, Sponsor may lock out an entrant who, in Sponsor's sole judgment, has

                    been disqualified, has questionable eligibility or is otherwise ineligible to enter. CAUTION: A
                    PERSON
                    WHO ATTEMPTS DELIBERATELY TO UNDERMINE THE LEGITIMATE OPERATION OF THIS PROMOTION OR TO ALTER OR
                    DAMAGE
                    A WEBSITE MAY BE SUBJECT TO CIVIL AND/OR CRIMINAL PENALTIES AND FINES; AND SPONSOR RESERVES THE
                    RIGHT TO
                    SEEK DAMAGES FROM ANY SUCH PERSON TO THE FULLEST EXTENT PERMITTED BY LAW. Without limiting the
                    foregoing, Sponsor may modify, cancel, terminate, and/or suspend the Promotion if, in the Sponsor's
                    sole
                    opinion, an incident of any kind occurs to corrupt or impair the administration, security,
                    integrity,
                    fairness or play (as intended) of the Promotion, including without limitation: (a) viruses, bugs,
                    unauthorized human intervention, fraud or technical failure; or (b) earthquake, flood, fire, storm
                    or
                    other natural disaster, act of God, pandemic/epidemic; or (c) labor controversy or threat thereof,
                    civil
                    disturbance or commotion, disruption of the public markets, war or armed conflict (whether or not
                    officially declared), or (d) cancellation, suspension or postponement of any type of game or league.
                    In
                    the event that the race is postponed, terminated early, or cancelled for any reason (such as a
                    strike or
                    weather), Sponsor reserves the right to award the prizes in a manner which Sponsor determines in its
                    sole discretion is fair, appropriate and consistent with these Official Rules or to cancel, modify
                    or
                    suspend the Promotion at its discretion.

                    14. RELEASE AND INDEMNITY. By participating in this Promotion or receiving a prize, entrants (and in
                    particular, winner) agree to release and to indemnify and hold harmless Sponsor, Promoters, each of
                    their respective related companies, and all of their respective officers, directors, employees and
                    agents (collectively, the "Released Parties") for any liability, injury, death, loss or damages to
                    entrant or any person or entity, including without limitation damage to personal or real property,
                    caused in whole or in part, directly or indirectly, by participation in this Promotion (or related
                    activities) or the acceptance, possession or use/misuse of the prize. By entering, entrants also
                    authorize the Sponsor and its designees to use their name, voice, likeness, photograph, prize
                    information (if applicable), biographical data and city and state/province of residence in
                    programming,
                    advertising and/or promotional material or on a Winners' List without compensation to the extent
                    permitted by law. Sponsor is not obligated to use any of the above-mentioned information or
                    materials
                    but may do so and may edit such information or materials, at Sponsor's sole discretion, without
                    obligation or compensation. All federal, state/provincial, and local laws and regulations apply. All
                    entries become the property of the Sponsor.

                    15. GAMBLING PROHIBITION. This Promotion is strictly for entertainment purposes and may not be used
                    in
                    connection with any form of gambling.

                    16. LIMITATIONS OF LIABILITY. The Released Parties do not make any representation, warranty or
                    guarantee, express or implied, relating to the Promotion, the Grand Prize or the First Prize, and
                    are
                    not responsible for: (1) any incorrect or inaccurate information, whether caused by entrants,
                    printing
                    errors or by any of the equipment, hardware, software or programming associated or used with the
                    Promotion; (2) technical errors, defects, delays or failures of any kind, including without
                    limitation
                    malfunctions, interruptions or disconnections in communications lines, Internet or website access,
                    hardware or software; (3) digital or electronic disruptions, e.g., viruses; (4) unauthorized human
                    intervention, e.g., hacking; (5) technical or human error which may occur in connection with any
                    aspect
                    of the Promotion; (6) any injury or damage to persons or property which may be caused, directly or
                    indirectly, in whole or in part, from entrant's participation in the Promotion, access to, copying
                    or
                    downloading materials from any other website, or receipt or use of any prize; or (7) for any
                    cancellation, suspension or postponement of any type of race, or event of force majeure.

                    SPONSOR MAKES NO WARRANTY, REPRESENTATION, OR GUARANTEE, EXPRESS OR IMPLIED, IN FACT OR IN LAW,
                    RELATIVE
                    TO THE USE OF ANY PRIZE, INCLUDING, WITHOUT LIMITATION, ITS QUALITY, MERCHANTABILITY OR FITNESS FOR
                    A
                    PARTICULAR PURPOSE

                    17. DISPUTES/ARBITRATION/CHOICE OF LAW. Except where prohibited, Entrants agree that: (a) any and
                    all
                    disputes, claims, and causes of action arising out of or connected with this Promotion, or the
                    awarding
                    of Prizes and determination of Winners, shall be resolved individually, without resort to any form
                    of
                    class action, and exclusively by arbitration pursuant to the Rules of JAMS, then effective
                    (notwithstanding the foregoing, Entrant shall not be precluded from seeking relief in small claims
                    court
                    for disputes/claims within the scope of the jurisdiction of small claims court and any remedies
                    available to Entrant under applicable federal, state or local law remain available to Entrant and
                    are
                    not waived pursuant to this section); (b) any and all claims, judgments and awards shall be limited
                    to
                    actual out-of-pocket costs incurred, including costs associated with entering this Promotion but in
                    no
                    event attorneys' fees; and (c) under no circumstances will Entrant be permitted to obtain awards for
                    and
                    Entrant hereby waives all rights to claim punitive, incidental and consequential damages and any
                    other
                    damages, other than for actual out-of-pocket expenses, and any and all rights to have damages
                    multiplied
                    or otherwise increased and any and all rights to injunctive relief. The arbitrator hearing such
                    dispute/claim/cause of action shall be neutral and the Entrant will have a reasonable opportunity to
                    participate in the selection of the arbitrator. Such arbitration will take place at a location near
                    the
                    Entrant's residence and the Entrant has the right to an in-person hearing in the arbitration
                    proceeding,
                    if desired. The arbitration will allow for the discovery and/or exchange of non-privileged
                    information
                    between Entrant and Sponsor. Should an Entrant initiate arbitration pursuant to this section, the
                    Entrant shall be responsible for JAMS's fees to do so (approximately, $250), with all other costs of
                    such arbitration being borne solely by Sponsor. If Entrant is a California (USA) resident and
                    Sponsor is
                    the prevailing party in such arbitration, such Entrant will not be responsible for Sponsor's costs
                    and
                    fees in conjunction with the arbitration proceeding; BUT, IN ALL OTHER CASES, ENTRANT WILL BE
                    RESPONSIBLE FOR SUCH COSTS/FEES IF REQUIRED BY THE ARBITRATOR'S RULING. For the avoidance of doubt,
                    the
                    requirement to arbitrate disputes pursuant to this section is reciprocal and binding both upon
                    Entrants
                    and Sponsor. All issues and questions concerning the construction, validity, interpretation and
                    enforceability of these Official Rules, or the rights and obligations of Entrant and/or Released
                    Parties
                    in connection with the Promotion, shall be governed by, and construed in accordance with, the laws
                    of
                    the State of Texas (USA), without regard for conflicts of law doctrine of Texas or any other
                    jurisdiction.

                    18. PRIVACY POLICY. Personal information provided in conjunction with Promotion entry is subject to
                    Sponsor's privacy policy.

                    19. OFFICIAL RULES; WINNERS LIST. For the Official Rules and names of winners, mail a
                    self-addressed,
                    stamped envelope to: Bracket Bonanza 2023 Winners, c/o  Werner G+E LLC, 1033 Brook Arbor
                    Drive, Mansfield, Texas 76063. All such requests must be received by December 31, 2023.

                    This Promotion is in no way sponsored, endorsed or administered by NHRA, FOX, Facebook, Instagram or
                    Twitter.
                </Typography>
            </Box>
        </Grid>
    )
}