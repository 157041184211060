export enum RaceException{
    DID_NOT_START = "DNS",
    DID_NOT_QUALIFY = "DNQ",
    NO_RUN = "NR",
    RED_LIGHT = "RD LT",
    RUN_DISQUALIFIED = "RDQ",
    TIMED_OUT = "TO",
    NO_SHOW = "NS",
    BROKE = "BRK",
    CROSS_CENTER_LINE = "CCL",
    HIT_GUARDWALL = "HT GW",
    FOUL = "FOUL",
    CRASH = "CRASH",
    LEFT_BEFORE_TREE = "LBT",
    TECHNICAL_VIOLATION = "DQT",
    HOLESHOT = "HOLE",
    NONE = "NONE"
}

export function getRaceExceptionString(raceException: RaceException): string{
    switch (raceException){
        case RaceException.DID_NOT_START:
            return "Did Not Start";
        case RaceException.DID_NOT_QUALIFY:
            return "Did Not Qualify";
        case RaceException.NO_RUN:
            return "No Run";
        case RaceException.RED_LIGHT:
            return "Red Light";
        case RaceException.RUN_DISQUALIFIED:
            return "Run Disqualified";
        case RaceException.TIMED_OUT:
            return "Timed Out";
        case RaceException.NO_SHOW:
            return "No Show";
        case RaceException.BROKE:
            return "Broke";
        case RaceException.CROSS_CENTER_LINE:
            return "Cross Center Line";
        case RaceException.HIT_GUARDWALL:
            return "Hit Guardwall";
        case RaceException.FOUL:
            return "Foul";
        case RaceException.CRASH:
            return "Crash";
        case RaceException.LEFT_BEFORE_TREE:
            return "Left Before Tree";
        case RaceException.TECHNICAL_VIOLATION:
            return "Technical Violation";
        case RaceException.HOLESHOT:
            return "Holeshot";
        default:
            return "None";
    }
}


export function getRaceExceptionFromString(raceException: string): RaceException{
    switch (raceException){
        case "Did Not Start":
            return RaceException.DID_NOT_START;
        case "Did Not Qualify":
            return RaceException.DID_NOT_QUALIFY;
        case "No Run":
            return RaceException.NO_RUN;
        case "Red Light":
            return RaceException.RED_LIGHT;
        case "Run Disqualified":
            return RaceException.RUN_DISQUALIFIED;
        case "Timed Out":
            return RaceException.TIMED_OUT;
        case "No Show":
            return RaceException.NO_SHOW;
        case "Broke":
            return RaceException.BROKE;
        case "Cross Center Line":
            return RaceException.CROSS_CENTER_LINE;
        case "Hit Guardwall":
            return RaceException.HIT_GUARDWALL;
        case "Foul":
            return RaceException.FOUL;
        case "Crash":
            return RaceException.CRASH;
        case "Left Before Tree":
            return RaceException.LEFT_BEFORE_TREE;
        case "Technical Violation":
            return RaceException.TECHNICAL_VIOLATION;
        case "Holeshot":
            return RaceException.HOLESHOT;
        default:
            return RaceException.NONE;
    }
}