import * as AuthAPI from "../apis/Auth";

export default async function ValidateSignIn(): Promise<void> {
    try {
        if (localStorage.getItem("access_token")?.length !== 0) {
            let response: boolean = (await AuthAPI.verify_token(localStorage.getItem("refresh_token") as string,
                localStorage.getItem("access_token") as string)).succeeded;
            if (response) {
                localStorage.setItem("signed_in", "true");
            } else {
                localStorage.setItem("signed_in", "false");
                window.location.href = "/signin";
            }
        } else {
            localStorage.setItem("signed_in", "false");
            window.location.href = "/signin";
        }
    } catch (e) {
        console.error(e);
        localStorage.setItem("signed_in", "false");
        window.location.href = "/signin";
    }
}