import {Config} from "../Config";
import {League} from "../data/models/user_auth/League";
import {AuthResponse, refresh_token} from "./Auth";

const config: Config = new Config();

export type LeagueResponse = {
    succeeded: boolean;
    statusCode: number;
    message: String;
    data?: any;
}

export async function leagues_screen_load(playerId: number): Promise<LeagueResponse> {
    if (window.location.pathname === "/error") {
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
    try {
        let response: Response = await fetch(config.backendURL + config.endpoints.leagues_screen_load + playerId + "/", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + localStorage.getItem('access_token')
            }
        });
        let data = await response.json();
        let status_code = response.status;
        if(status_code < 400){
            return {
                succeeded: true,
                statusCode: status_code,
                message: "Successfully loaded leagues screen",
                data: data
            };
        }
        else if(status_code === 401){
            let refresh_validation: AuthResponse = await refresh_token(localStorage.getItem('refresh_token') ?? "");
            if (refresh_validation.succeeded) {
                return await leagues_screen_load(playerId);
            } else {
                return {succeeded: false, statusCode: 401, data: null, message: "Access token is invalid"};
            }
        }
        else{
            return {
                succeeded: false,
                statusCode: status_code,
                message: "Failed to load leagues screen",
                data: data
            };
        }
    } catch (e) {
        if (e instanceof TypeError && e.message === "Failed to fetch") {
            window.location.href = "/error";
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}

export async function get_leagues_per_player(playerId: number) {
    if (window.location.pathname === "/error") {
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
    try {
        let response: Response = await fetch(config.backendURL + config.endpoints.league_by_player + playerId + "/", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + localStorage.getItem('access_token')
            }
        });
        let data = await response.json();
        let status_code = response.status;
        switch (status_code) {
            case 200:
                return {
                    succeeded: true,
                    statusCode: status_code,
                    message: "Successfully retrieved leagues",
                    data: data
                };
            case 401:
                return {
                    succeeded: false,
                    statusCode: status_code,
                    message: "Unauthorized",
                };
            case 404:
                return {
                    succeeded: false,
                    statusCode: status_code,
                    message: "No leagues found",
                };
            default:
                return {
                    succeeded: false,
                    statusCode: status_code,
                    message: "Unknown error",
                };

        }
    } catch (e) {
        if (e instanceof TypeError && e.message === "Failed to fetch") {
            window.location.href = "/error";
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}

export async function join_league(playerId: number, leagueInviteCode: string): Promise<{succeeded: boolean, statusCode: number, message: string}> {
    if (window.location.pathname === "/error") {
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
    try {
        let response: Response = await fetch(config.backendURL + config.endpoints.join_league, {
            'method': 'POST',
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + localStorage.getItem('access_token')
            },
            "body": JSON.stringify({
                "player_id": playerId,
                "invite_code": leagueInviteCode
            })
        });
        let status_code = response.status;
        let data = await response.json();
        if(status_code < 400) {
            return {
                succeeded: true,
                statusCode: status_code,
                message: "Successfully joined league",
            };
        }
        else if(status_code === 401){
            let refresh_validation: AuthResponse = await refresh_token(localStorage.getItem('refresh_token') ?? "");
            if (refresh_validation.succeeded) {
                return await join_league(playerId, leagueInviteCode);
            } else {
                return {succeeded: false, statusCode: 401,  message: "Access token is invalid"};
            }
        }
        else{
            return {
                succeeded: false,
                statusCode: status_code,
                message: data,
            };
        }
    } catch (e) {
        if (e instanceof TypeError && e.message === "Failed to fetch") {
            window.location.href = "/error";
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}

export async function get_league(leagueId: number) {
    if (window.location.pathname === "/error") {
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
    try {

        let response: Response = await fetch(config.backendURL + config.endpoints.league_detail + leagueId + "/", {
            'method': 'GET',
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + localStorage.getItem('access_token')
            }
        });
        let data = await response.json();
        let status_code = response.status;
        switch (status_code) {
            case 200:
                return {
                    succeeded: true,
                    statusCode: status_code,
                    message: "Successfully retrieved league",
                    data: data
                };
            case 401:
                return {
                    succeeded: false,
                    statusCode: status_code,
                    message: "Unauthorized",
                };
            case 404:
                return {
                    succeeded: false,
                    statusCode: status_code,
                    message: "League not found",
                };
            default:
                return {
                    succeeded: false,
                    statusCode: status_code,
                    message: "Unknown error",
                };

        }
    } catch (e) {
        if (e instanceof TypeError && e.message === "Failed to fetch") {
            window.location.href = "/error";
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}

export async function invite_player_to_league(email: string, player_id: number, league_id: number) {
    if (window.location.pathname === "/error") {
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
    try {
        let response: Response = await fetch(config.backendURL + config.endpoints.invite_to_league, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + localStorage.getItem('access_token')
            },
            body: JSON.stringify(
                {
                    "player_id": player_id,
                    "league_id": league_id,
                    "email": email
                }
            ),
        });
        let data = await response.json();
        let status_code = response.status;
        if (status_code < 400) {
            return {
                succeeded: true,
                statusCode: status_code,
                message: `Successfully sent invite to ${email}`,
                data: data
            };
        }
        switch (status_code) {
            case 401:
                return {
                    succeeded: false,
                    statusCode: status_code,
                    message: "Unauthorized",
                    data: data
                };
            case 400:
                return {
                    succeeded: false,
                    statusCode: status_code,
                    message: "Bad request",
                    data: data
                };
            default:
                return {
                    succeeded: false,
                    statusCode: status_code,
                    message: "Unknown error",
                    data: data
                };
        }
    } catch (e) {
        if (e instanceof TypeError && e.message === "Failed to fetch") {
            window.location.href = "/error";
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}

type LeagueFormData = {
    league_name: string;
    creator: number;
    id?: number;
    players?: number[] | undefined;
}

export async function create_league(formData: LeagueFormData): Promise<LeagueResponse> {
    if (window.location.pathname === "/error") {
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
    try {
        let response: Response = await fetch(config.backendURL + config.endpoints.league_create, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + localStorage.getItem('access_token')
            },
            body: JSON.stringify(formData),
        });
        let data = await response.json();
        let status_code = response.status;
        if(status_code < 400){
            return {
                succeeded: true,
                statusCode: status_code,
                message: "Successfully created league",
                data: data
            };
        }
        else if(status_code === 401){
            let refresh_validation: AuthResponse = await refresh_token(localStorage.getItem('refresh_token') ?? "");
            if (refresh_validation.succeeded) {
                return await create_league(formData);
            } else {
                return {succeeded: false, statusCode: 401, data: null, message: "Access token is invalid"};
            }
        }
        else{
            return {
                succeeded: false,
                statusCode: status_code,
                message: "Failed to create league",
                data: data
            };
        }
    } catch (e) {
        if (e instanceof TypeError && e.message === "Failed to fetch") {
            window.location.href = "/error";
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}


export async function league_history_load(id: number): Promise<LeagueResponse> {
    if (window.location.pathname === "/error") {
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
    try {
        let response: Response = await fetch(config.backendURL + config.endpoints.league_history_load + id + '/', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + localStorage.getItem('access_token')
            },
        });
        let data = await response.json();
        let status_code = response.status;
        if(status_code < 400){
            return {
                succeeded: true,
                statusCode: status_code,
                message: "Successfully loaded league history",
                data: data
            };
        }
        else if(status_code === 401){
            let refresh_validation: AuthResponse = await refresh_token(localStorage.getItem('refresh_token') ?? "");
            if (refresh_validation.succeeded) {
                return await league_history_load(id);
            } else {
                return {succeeded: false, statusCode: 401, data: null, message: "Access token is invalid"};
            }
        }
        else{
            return {
                succeeded: false,
                statusCode: status_code,
                message: "Failed to create league",
                data: data
            };
        }
    } catch (e) {
        if (e instanceof TypeError && e.message === "Failed to fetch") {
            window.location.href = "/error";
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}


export async function league_history_leaderboard(league_id: number, event_id: string): Promise<LeagueResponse> {
    if (window.location.pathname === "/error") {
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
    try {
        let response: Response = await fetch(config.backendURL + config.endpoints.league_history_leaderboard + league_id + '/' + event_id + '/', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + localStorage.getItem('access_token')
            },
        });
        let data = await response.json();
        let status_code = response.status;
        if(status_code < 400){
            return {
                succeeded: true,
                statusCode: status_code,
                message: "Successfully loaded league history leaderboard",
                data: data
            };
        }
        else if(status_code === 401){
            let refresh_validation: AuthResponse = await refresh_token(localStorage.getItem('refresh_token') ?? "");
            if (refresh_validation.succeeded) {
                return await league_history_leaderboard(league_id, event_id);
            } else {
                return {succeeded: false, statusCode: 401, data: null, message: "Access token is invalid"};
            }
        }
        else{
            return {
                succeeded: false,
                statusCode: status_code,
                message: "Failed to create league",
                data: data
            };
        }
    } catch (e) {
        if (e instanceof TypeError && e.message === "Failed to fetch") {
            window.location.href = "/error";
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}


export async function edit_league(formData: LeagueFormData): Promise<LeagueResponse> {
    if (window.location.pathname === "/error") {
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
    try {
        let response: Response = await fetch(config.backendURL + config.endpoints.league_update + formData.id + "/", {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + localStorage.getItem('access_token')
            },
            body: JSON.stringify({
                "name": formData.league_name,
                "creator": formData.creator,
                "players": formData.players
            }),
        });
        let data = await response.json();
        let status_code = response.status;
        if(status_code < 400){
            return {
                succeeded: true,
                statusCode: status_code,
                message: "Successfully edited league",
                data: data
            };
        }
        else if(status_code === 401){
            let refresh_validation: AuthResponse = await refresh_token(localStorage.getItem('refresh_token') ?? "");
            if (refresh_validation.succeeded) {
                return await edit_league(formData);
            } else {
                return {succeeded: false, statusCode: 401, data: null, message: "Access token is invalid"};
            }
        }
        else{
            return {
                succeeded: false,
                statusCode: status_code,
                message: "Failed to edit league",
                data: data
            };
        }
    } catch (e) {
        if (e instanceof TypeError && e.message === "Failed to fetch") {
            window.location.href = "/error";
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}


export async function delete_league(id: number, userId: string): Promise<boolean> {
    if (window.location.pathname === "/error") {
        return false;
    }
    try {
        let response: Response = await fetch(config.backendURL + config.endpoints.league_delete + id + "/" + userId + "/", {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + localStorage.getItem('access_token')
            },
        });
        let status_code = response.status;
        if(status_code < 400){
            return true
        }
        else {
            return false
        }
    } catch (e) {
        if (e instanceof TypeError && e.message === "Failed to fetch") {
            window.location.href = "/error";
        }
        return false;
    }
}

export async function upload_league_photo(file: File, leagueId: number, userId: number) {
    if (window.location.pathname === "/error") {
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
    try {

        const response = await fetch(config.backendURL + config.endpoints.league_upload_photo + leagueId + "/" + userId + "/", {
            method: 'POST',
            headers: {
                'Content-Type': file.type, // Set the appropriate content type for your photo
                'Authorization': 'JWT ' + localStorage.getItem('access_token'),
                'Content-Disposition': 'attachment; filename=' + file.name,
            },
            body: file
        });
        if (response.ok) {
            // Handle the successful response
            return {
                succeeded: true,
                statusCode: response.status,
                message: "Successfully uploaded league photo",
            }
        } else {
            return {
                succeeded: false,
                statusCode: response.status,
                message: "Failed to upload league photo"
            }
        }
    } catch (e) {
        if (e instanceof TypeError && e.message === "Failed to fetch") {
            window.location.href = "/error";
        }
        return {succeeded: false, statusCode: 500, message: "Something went wrong on our end"};
    }
}