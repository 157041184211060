import Grid from "@mui/material/Grid";
import PrintRow from "../PrintRow";
import {Divider} from "@mui/material";
import Paper from "@mui/material/Paper";
import {FourWidePick} from "../../../../data/models/bracket_api/FourWidePick";
import {FourWideResult} from "../../../../data/models/bracket_api/FourWideResult";

export type FourWidePickBlockProps = {
    pick?: FourWidePick,
    result?: FourWideResult,
    updatePick: (pick: FourWidePick) => void,
    picks_locked?: boolean
}

export default function PrintBlockFourWide({pick, result, updatePick, picks_locked}: Readonly<FourWidePickBlockProps>){

    return (
        <Grid container my={0.5} sx={{
            display: 'flex',
            flexDirection: "column",
            height: 'fit-content',
            width: 'fit-content',
            border: '1px solid black',
        }}>
            <PrintRow
                competitor_number={1}
                pick={pick}
                result={result}
                fourWide={true}
            />
            <PrintRow
                competitor_number={2}
                pick={pick}
                result={result}
                fourWide={true}
            />
            <PrintRow
                competitor_number={3}
                pick={pick}
                result={result}
                fourWide={true}
            />
            <PrintRow
                competitor_number={4}
                pick={pick}
                result={result}
                fourWide={true}
            />
        </Grid>
    )
}