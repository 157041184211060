import * as React from "react";
import Grid from "@mui/system/Unstable_Grid/Grid";
import Layout from "../utilities/Layout";
import {Driver} from "../data/models/bracket_api/Driver";
import {DriverResponse, get_drivers} from "../apis/Driver";
import {useEffect} from "react";
import RaceClassFromInt from "../utilities/RaceClassTools";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {RaceClass} from "../data/models/bracket_api/RaceClass";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MapIcon from '@mui/icons-material/Map';
import CakeIcon from '@mui/icons-material/Cake';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import PaidIcon from '@mui/icons-material/Paid';
import TimerIcon from '@mui/icons-material/Timer';
import SpeedIcon from '@mui/icons-material/Speed';
import {ListItemText, useMediaQuery} from "@mui/material";
import {useState} from "react";
import {Theme} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {driver_stats} from "../apis/Driver";
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import helmet from "../assets/images/helmet.png";
import "../styles/IndividualStats.css";
import Button from "@mui/material/Button";
import {Helmet} from "react-helmet";
import AdSpot from "../components/common/AdSpot";

type RawDriver = {
    first_name: string,
    last_name: string,
    id: number,
    divisions: number[],
    thumbnail: string,
    date_of_birth: string,
    residence: string,
    waist_up: string,
    driver_background: string,
    driver_sponsors: string[],
    career_best_et: number,
    career_best_speed: number,
    is_active: boolean,
}

type DriverStats = {
    list_of_events: string[],
    runner_up_count: number,
    win_count: number,
    record_by_year: SeasonRecord[],
}

type SeasonRecord = {
    year: number,
    wins: number,
    losses: number
}

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


export default function IndividualStats() {
    let searchParams = window.location.href.substring(window.location.href.indexOf("?") + 1);
    let decoded = decodeURIComponent(searchParams);
    const queryParams = new URLSearchParams(decoded);
    const id = queryParams.get("id") || "";
    const [driver, setDriver] = React.useState<Driver | undefined>(undefined);
    const [stats, setStats] = React.useState<DriverStats | undefined>(undefined);
    const sponsorList = formatList(driver?.driver_sponsors || ["No Known Sponsors"]);
    const divisionList = formatList(driver?.divisions);
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = React.useState(false);

    //For Popup
    const handleClickOpen = () => {
        setOpen(true);
    };

    //for popup
    const handleClose = () => {
        setOpen(false);
    };

    //for table
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    //for table
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function callDriverStats() {
        driver_stats(Number(id)).then((response) => {
            if (response.succeeded) {
                let seasonRecord: SeasonRecord[] = [];
                Object.entries(response.data.record_by_year).forEach(([key, value]) => {
                    seasonRecord.push({
                        year: Number(key),
                        wins: (value as number[])[0],
                        losses: (value as number[])[1]
                    });
                });
                console.log(seasonRecord)
                console.log(response.data)
                setStats({
                    list_of_events: response.data.list_of_events,
                    runner_up_count: response.data.runner_up_count,
                    win_count: response.data.win_count,
                    record_by_year: seasonRecord
                })
                console.log(stats?.list_of_events);
            }
        })
    }

    useEffect(() => {
        let result: RawDriver[] = [];
        try {
            result = JSON.parse(localStorage.getItem('drivers') ?? "[]");
        } catch (e) {
            get_drivers().then((response: DriverResponse) => {
                localStorage.setItem('drivers', JSON.stringify(response.data));
                let drivers2: Driver[] = [];
                result.forEach((driver: RawDriver) => {
                    drivers2.push({
                        id: driver.id,
                        first_name: driver.first_name,
                        last_name: driver.last_name,
                        divisions: RaceClassFromInt(driver.divisions),
                        thumbnail: driver.thumbnail,
                        date_of_birth: driver.date_of_birth,
                        residence: driver.residence,
                        waist_up: driver.waist_up,
                        driver_background: driver.driver_background,
                        driver_sponsors: driver.driver_sponsors,
                        career_best_et: driver.career_best_et,
                        career_best_speed: driver.career_best_speed,
                        is_active: driver.is_active,
                    });
                });

                setDriver(drivers2.find((driver) => {
                    return driver.id === Number(id);
                }));

                callDriverStats();
            });
        }
        if (result.length === 0) {
            get_drivers().then((response: DriverResponse) => {
                localStorage.setItem('drivers', JSON.stringify(response.data));
                let drivers2: Driver[] = [];
                result.forEach((driver: RawDriver) => {
                    drivers2.push({
                        id: driver.id,
                        first_name: driver.first_name,
                        last_name: driver.last_name,
                        divisions: RaceClassFromInt(driver.divisions),
                        thumbnail: driver.thumbnail,
                        date_of_birth: driver.date_of_birth,
                        residence: driver.residence,
                        waist_up: driver.waist_up,
                        driver_background: driver.driver_background,
                        driver_sponsors: driver.driver_sponsors,
                        career_best_et: driver.career_best_et,
                        career_best_speed: driver.career_best_speed,
                        is_active: driver.is_active,
                    });
                });

                setDriver(drivers2.find((driver) => {
                    return driver.id === Number(id);
                }));

                callDriverStats();
            });
        }
        let drivers2: Driver[] = [];
        result.forEach((driver: RawDriver) => {
            drivers2.push({
                id: driver.id,
                first_name: driver.first_name,
                last_name: driver.last_name,
                divisions: RaceClassFromInt(driver.divisions),
                thumbnail: driver.thumbnail,
                date_of_birth: driver.date_of_birth,
                residence: driver.residence,
                waist_up: driver.waist_up,
                driver_background: driver.driver_background,
                driver_sponsors: driver.driver_sponsors,
                career_best_et: driver.career_best_et,
                career_best_speed: driver.career_best_speed,
                is_active: driver.is_active,
            });
        });

        setDriver(drivers2.find((driver) => {
            return driver.id === Number(id);
        }));

        callDriverStats();

        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMdOrUp])

    function formatList(input?: string[] | RaceClass[] | number[]) {
        let stringOut: string = "";
        let firstElement = 0;
        input?.toString();
        if (input !== undefined) {
            for (let i = 0; i < input.length; i++) {
                if (input[i] !== "") {
                    if (firstElement === 0) {
                        stringOut = stringOut + input[i];
                        firstElement = 1;
                    } else {
                        stringOut = stringOut + ", " + input[i];
                    }
                }
            }
        }

        return stringOut;
    }

    function formatDate(input?: string) {
        if(input === undefined){
            return undefined;
        }
        let date = new Date(input);
        return date.toLocaleDateString(undefined,{
            timeZone: 'UTC',
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        });
    }


    return (
        <Layout>
            <Helmet>
                <title>DRBB | Stats</title>
            </Helmet>
            <Grid container direction="row" justifyContent="center" alignItems="center" width={"100%"} flexWrap={"wrap"}>
                <Grid container direction={"column"} m={4}>
                    <div className={"myCard"} style={{
                        height: isMdOrUp ? '700px' : '500px',
                        width: isMdOrUp ? '500px' : '300px',
                    }}>
                        <Grid className="innerCard" container>
                            <Card className="frontSide" sx={{
                                backgroundImage: `url(${driver?.driver_background || helmet})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: driver?.driver_background !== null ? '25% 50%' : '50% 0%',
                                borderRadius: '25px',
                            }}>
                                <CardContent sx={{
                                    position: 'relative',
                                    zIndex: '1',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    textAlign: 'center',
                                    width: '100%',
                                    height: '100%',
                                    paddingBottom: '0px !important'
                                }}>
                                    <Typography variant={"h3"} sx={{
                                        fontWeight: 'bold',
                                        background: 'rgba( 255, 255, 255, 0.3 )',
                                        backdropFilter: 'blur(6px)',
                                        boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
                                        borderRadius: '10px',
                                        color: '#121212'
                                    }}>
                                        {driver?.first_name + " " + driver?.last_name}
                                    </Typography>
                                    <Grid sx={{display: 'flex', justifyContent: 'center'}}>
                                        {
                                            driver !== undefined && driver?.waist_up !== null &&
                                            <img src={driver.waist_up} width={isMdOrUp ? "400px" : "260px"} height={isMdOrUp ? "600px" : "390px"}></img>
                                        }
                                    </Grid>
                                </CardContent>
                            </Card>
                            <Grid className="backSide" container direction="column" padding={2}
                                  minHeight="500px" maxWidth="90vw"
                                  sx={{
                                      alignItems: 'flex-start !important',
                                      backgroundColor: '#486ce7',
                                      color: 'white',
                                  }}>
                                <Grid container direction="row" flexWrap="nowrap" alignItems="center" marginY={1}>
                                    <MapIcon fontSize="medium" sx={{mr: '5px'}}/>
                                    <Typography variant="h6" fontWeight="bold" textAlign="left">
                                        Residence: {driver?.residence || "Unknown"}
                                    </Typography>
                                </Grid>
                                <Grid container direction="row" flexWrap="nowrap" alignItems="center" marginY={1}>
                                    <CakeIcon fontSize="medium" sx={{mr: '5px'}}/>
                                    <Typography variant="h6" fontWeight="bold" textAlign="left">
                                        Date of Birth: {formatDate(driver?.date_of_birth) || "Unknown"}
                                    </Typography>
                                </Grid>
                                <Grid container direction="row" flexWrap="nowrap" alignItems="center" marginY={1}>
                                    <SportsScoreIcon fontSize="medium" sx={{mr: '5px'}}/>
                                    <Typography variant="h6" fontWeight="bold" textAlign="left">
                                        Division(s): {divisionList}
                                    </Typography>
                                </Grid>
                                <Grid container direction="row" flexWrap="nowrap" alignItems="center" marginY={1}>
                                    <PaidIcon fontSize="medium" sx={{mr: '5px'}}/>
                                    <Typography variant="h6" fontWeight="bold" textAlign="left" noWrap={false}>
                                        Sponsors: {sponsorList}
                                    </Typography>
                                </Grid>
                                <Grid container direction="row" flexWrap="nowrap" alignItems="center" marginY={1}>
                                    <EmojiEventsIcon fontSize="medium" sx={{mr: '5px'}}/>
                                    <Typography variant="h6" fontWeight="bold" textAlign="left">
                                        National Event Wins: {stats?.win_count}
                                    </Typography>
                                </Grid>
                                <Grid container direction="row" flexWrap="nowrap" alignItems="center" marginY={1}>
                                    <EmojiEventsIcon fontSize="medium" sx={{mr: '5px'}}/>
                                    <Typography variant="h6" fontWeight="bold" textAlign="left">
                                        National Event Runner-Ups: {stats?.runner_up_count}
                                    </Typography>
                                </Grid>
                                <Grid container direction="row" flexWrap="nowrap" alignItems="center" marginY={1}>
                                    <TimerIcon fontSize="medium" sx={{mr: '5px'}}/>
                                    <Typography variant="h6" fontWeight="bold" textAlign="left">
                                        Career best E.T: {driver?.career_best_et ? driver.career_best_et + " sec" : "Unknown"}
                                    </Typography>
                                </Grid>
                                <Grid container direction="row" flexWrap="nowrap" alignItems="center" marginY={1}>
                                    <SpeedIcon fontSize="medium" sx={{mr: '5px'}}/>
                                    <Typography variant="h6" fontWeight="bold" textAlign="left">
                                        Career Best
                                        Speed: {driver?.career_best_speed ? Number(driver.career_best_speed).toFixed(2) + " MPH" : "Unknown"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <React.Fragment>
                        <Button variant={"contained"} onClick={handleClickOpen} sx={{margin: 2}}>
                            View Event Wins
                        </Button>
                        <BootstrapDialog onClose={handleClose} open={open} sx={{minWidth: '300px', maxWidth: '90vw'}}>
                            <DialogTitle sx={{m: 0, p: 2}}>
                                Event Wins
                            </DialogTitle>
                            <IconButton
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon/>
                            </IconButton>
                            <DialogContent dividers>
                                <Typography gutterBottom>
                                    <List>
                                        {stats?.list_of_events.sort().reverse().map((eventName) => (
                                            <ListItemText key={eventName}>
                                                {eventName}
                                            </ListItemText>
                                        ))}
                                    </List>
                                </Typography>
                            </DialogContent>
                        </BootstrapDialog>
                    </React.Fragment>
                </Grid>
                <Grid width={"fit-content"} bgcolor={"background.paper"} borderRadius={"10px"} m={4}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={3}>
                                        <Typography variant={"h4"} fontWeight={"bold"} align={"center"}>
                                            Elimination Record Per Season
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        align={'left'}
                                        style={{minWidth: 'fit-content'}}
                                    >
                                        <Typography variant='h6' fontWeight={'bold'}>
                                            Season
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align={'center'}
                                        style={{minWidth: 'fit-content'}}
                                    >
                                        <Typography variant='h6' fontWeight={'bold'}>
                                            Wins
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align={'right'}
                                        style={{minWidth: 'fit-content'}}
                                    >
                                        <Typography variant='h6' fontWeight={'bold'}>
                                            Losses
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stats?.record_by_year.sort((a: SeasonRecord, b: SeasonRecord) => {
                                    return b.year - a.year;
                                })
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row: SeasonRecord) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={driver?.id+String(row.year)}>
                                                <TableCell
                                                    key={'record' + row.year}
                                                    align={'left'}
                                                    style={{minWidth: 'fit-content'}}
                                                >
                                                    {row.year}
                                                </TableCell>
                                                <TableCell
                                                    key={'record' + row.year + 'wins'}
                                                    align={'center'}
                                                    style={{minWidth: 'fit-content'}}
                                                >
                                                    {row.wins}
                                                </TableCell>
                                                <TableCell
                                                    key={'record' + row.year + 'losses'}
                                                    align={'right'}
                                                    style={{minWidth: 'fit-content'}}
                                                >
                                                    {row.losses}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={isMdOrUp ? [10, 25, 100] : [10]}
                        component="div"
                        count={stats?.record_by_year.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
                <AdSpot id={"driver-screen-1"} sx={{m: 2, width: '75%'}}/>
            </Grid>
        </Layout>
    );
}