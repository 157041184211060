import Layout from "../utilities/Layout";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {ChangeEvent, useEffect, useState} from "react";
import ValidateSignIn from "../utilities/ValidateSignIn";
import * as LeagueAPI from "../apis/League";
import {League} from "../data/models/user_auth/League";
import {
    Divider,
    Theme,
    useMediaQuery
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import GA4EventSend from "../utilities/GA4EventSend";
import {LeaderboardEntry, LeaderboardProps} from "../components/leaderboard/Leaderboard";
import Autocomplete from "@mui/material/Autocomplete";
import * as React from "react";
import LeaderboardCarousel from "../components/leaderboard/LeaderboardCarousel";
import Loader from "../components/common/Loader";
import {Event} from "../data/models/bracket_api/Event";
import {Helmet} from "react-helmet";
import Paper from "@mui/material/Paper";

export default function LeagueHistory() {
    let searchParams = window.location.href.substring(window.location.href.indexOf("?") + 1);
    let decoded = decodeURIComponent(searchParams);
    const queryParams = new URLSearchParams(decoded);
    const leagueId = queryParams.get('league_id') as unknown as number;
    const [league, setLeague] = useState<League | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingLeaderboard, setLoadingLeaderboard] = useState<boolean>(false);
    const [isClicked, setIsClicked] = useState(false);
    const [isMdOrUp, setIsMdOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('md')));
    const [eventLookUp, setEventLookUp] = useState<Event[]>([]);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [leaderboard, setLeaderboard] = useState<LeaderboardProps[]>([]);
    const [event_id, setEventId] = useState<string>("");

    const handleChange = (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        if (loading) {
            ValidateSignIn().then(() => {
                // Get user id from local storage and convert it to number
                let user_id: number = JSON.parse(localStorage.getItem('user') as string).id;
                LeagueAPI.get_league(leagueId).then((response: LeagueAPI.LeagueResponse) => {
                    if (response.succeeded) {
                        setLeague(response.data);
                    }
                    GA4EventSend("league_history", "load", "league_history", response.statusCode, false);
                });
                LeagueAPI.league_history_load(leagueId).then((response: LeagueAPI.LeagueResponse) => {
                    if (response.succeeded) {
                        console.log(response.data);
                        setEventLookUp(response.data);
                    } else {
                        console.log(response);
                    }
                });
            });
        }
        setLoading(false)
        const handleResize = () => {
            setIsMdOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [loading, eventLookUp]);

    function handleSearchChange(event: React.SyntheticEvent, value: string) {
        let eventFound: Event[] = eventLookUp.filter((event2) => {
            if (event2.name === value) {
                return event2;
            }
        })
        if (eventFound.length !== 0) {
            setLoadingLeaderboard(true);
            setEventId(eventFound[0].id as string);
            LeagueAPI.league_history_leaderboard(leagueId, eventFound[0].id as string).then((response: LeagueAPI.LeagueResponse) => {
                if (response.succeeded) {
                    console.log(response)
                    let leaderboards: LeaderboardProps[] = [];
                    Object.entries(response.data).forEach(([key, value]) => {
                        let leaderboard_rows: LeaderboardEntry[] = [];
                        for (const element of value as any) {
                            leaderboard_rows.push({
                                rank: element.rank,
                                nickname: element.nickname,
                                points: element.points,
                                possible_points: element.possible_points,
                            })
                        }
                        leaderboards.push({
                            display_name: key,
                            entries: leaderboard_rows,
                            viewable: eventFound[0].picks_locked && new Date() > new Date(eventFound[0].close_date),
                            event_id: eventFound[0].id as string,
                            league_id: leagueId.toString(),
                        });
                    });
                    setLeaderboard(leaderboards);
                    setLoadingLeaderboard(false);
                } else {
                    console.log(response);
                }
            });
        } else {
            console.log("Unsuccessful search")
        }
    }

    return (
        <Layout>
            <Helmet>
                <title>DRBB | League History</title>
            </Helmet>
            {loading || league === undefined ?
                <Grid container sx={{
                    height: '50vh'
                }}>
                    <Loader/>
                </Grid>
                :
                <Grid container component={Paper} elevation={0}
                      sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          minHeight: 'calc(100vh - 384px)',
                          alignItems: 'center',
                          alignContent: 'center',
                          m: 'auto',
                          width: 'fit-content',
                          p: 2,
                          backgroundColor: 'background.paper',
                          borderRadius: '10px',
                          maxWidth: '100vw',
                          position: 'relative',
                      }}>
                    <Grid container sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 'fit-content',
                    }}>
                        {league && league?.image_url !== null &&
                            <img src={league.image_url} alt={'League image'} height={'75px'}/>}
                        <Typography variant={isMdOrUp ? 'h3' : 'h5'} align={'center'} sx={{
                            marginLeft: '12px',
                        }}>
                            Leaderboard History of {league?.name}
                        </Typography>
                    </Grid>
                    <Divider sx={{width: '100%', m: 2}}/>
                    <Autocomplete
                        sx={{
                            minWidth: "300px",
                            width: "100%",
                            backgroundColor: 'background.paper',
                            borderRadius: '5px',
                        }}
                        freeSolo
                        disableClearable
                        onChange={handleSearchChange}
                        options={eventLookUp.map((event) => (event.name))}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Search Race"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                        )}/>
                    {
                        loadingLeaderboard ?
                            <Grid container sx={{
                                height: '50vh',
                            }}>
                                <Loader/>
                            </Grid>
                            :
                            leaderboard.length > 0 && league && event_id.length > 0 &&
                            <LeaderboardCarousel
                                league_name={league?.name}
                                leaderboards={leaderboard}
                                league_id={String(leagueId)}
                                event_id={event_id}
                                sx={{justifyContent: 'center'}}
                            />
                    }
                </Grid>
            }
        </Layout>
    )
}