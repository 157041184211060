import {Bracket} from "../../../../data/models/bracket_api/Bracket";
import {FourWideResult} from "../../../../data/models/bracket_api/FourWideResult";
import {FourWidePick} from "../../../../data/models/bracket_api/FourWidePick";
import {useTheme} from "@mui/material";
import {Driver} from "../../../../data/models/bracket_api/Driver";
import {RaceClass} from "../../../../data/models/bracket_api/RaceClass";
import TopFuelDark from "../../../../assets/images/bracket_crests/top_fuel_dark.png";
import TopFuel from "../../../../assets/images/bracket_crests/top_fuel.png";
import ProStockDark from "../../../../assets/images/bracket_crests/pro_stock_dark.png";
import ProStock from "../../../../assets/images/bracket_crests/pro_stock.png";
import FunnyCarDark from "../../../../assets/images/bracket_crests/funny_car_dark.png";
import FunnyCar from "../../../../assets/images/bracket_crests/funny_car.png";
import ProStockMotorcycleDark from "../../../../assets/images/bracket_crests/pro_stock_motorcycle_dark.png";
import ProStockMotorcycle from "../../../../assets/images/bracket_crests/pro_stock_motorcycle.png";
import Grid from "@mui/material/Grid";
import PickBlockFourWide from "./PickBlockFourWide";
import BracketConnector from "../../BracketConnector";
import TextField from "@mui/material/TextField";
import {PickStatus} from "../../../../data/models/bracket_api/PickStatus";
import {uuidv4} from "../../../../utilities/UUIDGeneration";
import {UUID} from "crypto";
import {Pick} from "../../../../data/models/bracket_api/Pick";
import Typography from "@mui/material/Typography";
import PickBlockReverseFourWide from "./PickBlockReverseFourWide";
import BracketConnectorReverse from "../../BracketConnectorReverse";

export type FourWidePickBracketProps = {
    race_class: RaceClass,
    bracket?: Bracket,
    results?: FourWideResult[],
    picks?: FourWidePick[],
    updatePick: (pick: FourWidePick) => void,
    picks_locked: boolean
}
export default function PickBracketFourWide({race_class, bracket, results, picks, updatePick, picks_locked}: Readonly<FourWidePickBracketProps>) {
    const theme = useTheme();
    let winner: Driver | undefined = picks?.find(pick => {
        return pick.result.round === 3 && pick.first
    })?.first;
    let actualWinner: Driver | undefined = results?.find(result => {
        return result.round === 4 && result.first
    })?.first;

    const determineCrestImage = () => {
        switch (race_class) {
            case RaceClass.TOP_FUEL:
                return theme.palette.mode === 'dark' ? TopFuelDark : TopFuel;
            case RaceClass.PRO_STOCK:
                return theme.palette.mode === 'dark' ? ProStockDark : ProStock;
            case RaceClass.FUNNY_CAR:
                return theme.palette.mode === 'dark' ? FunnyCarDark : FunnyCar;
            case RaceClass.PRO_STOCK_MOTORCYCLE:
                return theme.palette.mode === 'dark' ? ProStockMotorcycleDark : ProStockMotorcycle;
            default:
                return TopFuel;
        }
    }

    const crestImage = determineCrestImage();


    const determineRaceClassString = () => {
        switch (race_class){
            case RaceClass.TOP_FUEL:
                return 'TF'
            case RaceClass.FUNNY_CAR:
                return 'FC'
            case RaceClass.PRO_STOCK:
                return 'PS'
            case RaceClass.PRO_STOCK_MOTORCYCLE:
                return 'PSM'
            default:
                return 'TF'
        }
    }


    const raceClassString = determineRaceClassString();

    return(
        race_class === RaceClass.TOP_FUEL || race_class === RaceClass.PRO_STOCK ?
            <Grid container sx={{
                display: 'flex',
                flexDirection: "row",
                position: "relative",
                minWidth: 'fit-content',
                flexWrap: "nowrap",
            }}>
                <Grid container sx={{
                    flex: .3,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <PickBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 1
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 1
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PickBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 2
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 2
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PickBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 3
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 3
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PickBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 4
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 4
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                </Grid>
                <Grid container sx={{
                    flex: .05,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <BracketConnector height={"25.5%"}/>
                    <BracketConnector height={"25.5%"}/>
                </Grid>
                <Grid container sx={{
                    flex: .3,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <PickBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 2 && pick.result.game === 1
                        })}
                        result={results?.find((result) => {
                            return result.round === 2 && result.game === 1
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <img src={crestImage} alt={"Crest"} height={'170px'}/>
                    <PickBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 2 && pick.result.game === 2
                        })}
                        result={results?.find((result) => {
                            return result.round === 2 && result.game === 2
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                </Grid>
                <Grid container sx={{
                    flex: .05,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <BracketConnector height={"51%"}/>
                </Grid>
                <Grid container sx={{
                    flex: .3,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: '100%',
                    position: "relative",
                }}>
                    <TextField
                        type="number"
                        defaultValue={picks?.find((pick) => {
                            return pick.result.round === 3 && pick.result.game === 1 && pick.pick_status === PickStatus.TIEBREAKER
                        })?.tiebreaker ?? ""}
                        contentEditable={picks_locked}
                        InputProps={{inputProps: {step: 0.01}}}
                        label={`Fastest Speed ${raceClassString} (MPH)`}
                        variant="outlined"
                        sx={{
                            position: "absolute",
                            top: "25%",
                            width: "90%",
                            alignSelf: "center",
                        }}
                        onChange={(event) => {
                            let tiebreaker = event.currentTarget.value;
                            let pick = picks?.find((pick) => {
                                return pick.pick_status === PickStatus.TIEBREAKER
                            });
                            if(pick !== undefined){
                                pick.tiebreaker = parseFloat(tiebreaker);
                                updatePick(pick);
                            } else {
                                let pick2 = {
                                    tiebreaker: parseFloat(tiebreaker),
                                    pick_status: PickStatus.TIEBREAKER,
                                    result: results?.find((result) => {
                                        return result.round === 4 && result.game === 1
                                    })!,
                                    id: uuidv4() as UUID,

                                } as Pick;
                                updatePick(pick2);
                            }
                        }}
                    />
                    <PickBlockFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 3 && pick.result.game === 1 && pick.pick_status !== PickStatus.TIEBREAKER
                        })}
                        result={results?.find((result) => {
                            return result.round === 3 && result.game === 1
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <Typography variant="h6" component="div" textAlign={"center"} position={"absolute"} top={"65%"}
                                width={"100%"}
                                style={winner && actualWinner && winner.id !== actualWinner.id ? {textDecoration: 'line-through'} : {}}
                    >
                        {winner ? `Winner: ${winner.first_name + " " + winner.last_name}` : "Please Select A Winner"}
                    </Typography>
                    {winner && actualWinner && winner.id !== actualWinner.id &&
                        <Typography variant="h6" component="div" textAlign={"center"} position={"absolute"} top={"69%"}
                                    width={"100%"}>
                            {actualWinner ? `Winner: ${actualWinner.first_name + " " + actualWinner.last_name}` : ""}
                        </Typography>
                    }
                </Grid>
            </Grid>
            :
            <Grid container sx={{
                display: 'flex',
                flexDirection: "row",
                position: "relative",
                minWidth: 'fit-content',
                flexWrap: "nowrap",
            }}>
                <Grid container sx={{
                    flex: .3,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "center",
                    position: "relative"
                }}>
                    {results !== undefined && results?.length > 0 &&
                        <TextField
                            type="number"
                            defaultValue={picks?.find((pick) => {
                                return pick.result.round === 3 && pick.result.game === 1 && pick.pick_status === PickStatus.TIEBREAKER
                            })?.tiebreaker ?? ""}
                            contentEditable={picks_locked}
                            InputProps={{inputProps: {step: 0.01}}}
                            label={`Fastest Speed ${raceClassString} (MPH)`}
                            variant="outlined"
                            sx={{
                                position: "absolute",
                                top: "25%",
                                width: "90%",
                                alignSelf: "center",
                            }}
                            onChange={(event) => {
                                let tiebreaker = event.currentTarget.value;
                                let pick = picks?.find((pick) => {
                                    return pick.pick_status === PickStatus.TIEBREAKER
                                });
                                if (pick) {
                                    pick.tiebreaker = parseFloat(tiebreaker);
                                    updatePick(pick);
                                } else {
                                    let pick2 = {
                                        tiebreaker: parseFloat(tiebreaker),
                                        pick_status: PickStatus.TIEBREAKER,
                                        result: results?.find((result) => {
                                            return result.round === 4 && result.game === 1
                                        })!,
                                        id: uuidv4() as UUID,

                                    } as Pick;
                                    updatePick(pick2);
                                }
                            }}
                        />
                    }
                    <PickBlockReverseFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 3 && pick.result.game === 1 && pick.pick_status !== PickStatus.TIEBREAKER
                        })}
                        result={results?.find((result) => {
                            return result.round === 3 && result.game === 1
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    {results !== undefined && results?.length > 0 &&
                        <Typography variant="h6" component="div" textAlign={"center"} position={"absolute"} top={"65%"}
                                    width={"100%"}
                                    style={winner && actualWinner && winner.id !== actualWinner.id ? {textDecoration: 'line-through'} : {}}
                        >
                            {winner ? `Winner: ${winner.first_name + " " + winner.last_name}` : "Please Select A Winner"}
                        </Typography>
                    }
                    {winner && actualWinner && winner.id !== actualWinner.id &&
                        <Typography variant="h6" component="div" textAlign={"center"} position={"absolute"} top={"69%"}
                                    width={"100%"}>
                            {actualWinner ? `Winner: ${actualWinner.first_name + " " + actualWinner.last_name}` : ""}
                        </Typography>
                    }
                </Grid>
                <Grid container sx={{
                    flex: .05,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                }}>
                    <BracketConnectorReverse height={"51%"}/>
                </Grid>
                <Grid container sx={{
                    flex: .3,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center"
                }}>
                    <PickBlockReverseFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 2 && pick.result.game === 1
                        })}
                        result={results?.find((result) => {
                            return result.round === 2 && result.game === 1
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <img src={crestImage} alt={"Crest"} height={'170px'}/>
                    <PickBlockReverseFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 2 && pick.result.game === 2
                        })}
                        result={results?.find((result) => {
                            return result.round === 2 && result.game === 2
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                </Grid>
                <Grid container sx={{
                    flex: .05,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                }}>
                    <BracketConnectorReverse height={"25.5%"}/>
                    <BracketConnectorReverse height={"25.5%"}/>
                </Grid>
                <Grid container sx={{
                    flex: .3,
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: '100%',
                }}>
                    <PickBlockReverseFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 1
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 1
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PickBlockReverseFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 2
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 2
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PickBlockReverseFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 3
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 3
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                    <PickBlockReverseFourWide
                        pick={picks?.find((pick) => {
                            return pick.result.round === 1 && pick.result.game === 4
                        })}
                        result={results?.find((result) => {
                            return result.round === 1 && result.game === 4
                        })}
                        updatePick={updatePick}
                        picks_locked={picks_locked}
                    />
                </Grid>
            </Grid>
    )

}