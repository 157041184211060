import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import QualifierTable from "./QualifierTable";
import {SxProps} from "@mui/material";
import Grid from "@mui/material/Grid";
import "../../styles/leaderboard_carousel.css";
import {Qualifier} from '../../data/models/bracket_api/Qualifier';
import {RaceClass} from "../../data/models/bracket_api/RaceClass";

type QualifierCarouselProps = {
    event_name: string,
    qualifiers: Qualifier[],
    sx?: SxProps,
}

export default function QualifierCarousel(props: Readonly<QualifierCarouselProps>) {
    let race_classes: RaceClass[] = []
    for(let qualifier of props.qualifiers) {
        if (race_classes.indexOf(qualifier.race_class) === -1) {
            race_classes.push(qualifier.race_class);
        }
    }
    return (
        <Grid container position={"relative"} sx={{

        }}>
            <Carousel
                className={"leaderboard-carousel"}
                showThumbs={race_classes.length > 1}
                swipeable={false}
                showArrows={false}
                showStatus={false}
                infiniteLoop={false}
                dynamicHeight={true}
            >
                {race_classes.map((race_class: RaceClass) => (
                    <QualifierTable
                        race_class={race_class}
                        entries={
                        props.qualifiers.filter((q) =>
                        {return q.race_class === race_class}).sort((a, b) => a.seed - b.seed)
                    }/>
                ))}
            </Carousel>
        </Grid>
    )
}